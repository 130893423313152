import React, { useState } from "react";
import MasterService from "../../Services/MasterService";
import MastersService from "../../../../src/CapsitechConstruction/Services/MastersService";

import ApplicationContext from "./CreateApplicationContext";
import VendorService from "../../Services/VendorService";
import { RangePickerProps } from "antd/es/date-picker";
import { dayjs } from "../../../Utilities/dayjs";
import UserRights from "../../Services/UserRights";
import { EncryptStorage } from "encrypt-storage";
import { Select, message, notification } from "antd";
const { Option } = Select;

export const CategoryOption = [
  { label: "All", value: "0" },
  { label: "Cleaning", value: "1" },
  { label: "Stationary", value: "2" },
  { label: "Garden or Plants", value: "3" },
  { label: "Emergency Medical Kit", value: "4" },
  { label: "Paid Software", value: "5" },
  { label: "Digital Products", value: "6" },
  { label: "Office Equipments", value: "7" },
];

export const ApprovalStatusOption = [
  { label: "All", value: 0 },
  { label: "Approved", value: 1 },
  { label: "Pending", value: 2 },
  { label: "Deleted", value: 3 },
];

export const DigitalProductOption = [
  { label: "Single Stock", value: 1 },
  { label: "Stock with variant", value: 2 },
  { label: "Variant", value: 3 },
];

export const DigitalProductStatus = [
  { label: "All", value: "-1" },
  { label: "In Stock", value: "0" },
  { label: "In Use", value: "1" },
  { label: "Defective", value: "2" },
  { label: "In Repair", value: "3" },
  { label: "Repaired", value: "4" },
  { label: "Replaced", value: "5" },
  { label: "Dead", value: "6" },
];

export const PurchaseTypeObj: any = {
  0: "UPI",
  1: "Cash",
  2: "Cheque",
  3: "Net Banking",
  4: "Credit/Debit Card",
};

export const enum ProductStatusFlag {
  All,
  Approved,
  Pending,
  Delete,
}
export interface LabelValueModel {
  label: string;
  value: string;
}

export const pageSizeOption = ["10", "15", "30", "50", "100", "200"];
// Define Session key names

export const userRightsSessionKey: string = "user-rights";
export const loginUserSessionKey: string = "user";

// Encryption for storage
const secretKey: string = "1234567890";

export const secureSessionStorage = new EncryptStorage(secretKey, {
  prefix: "@secret",
  storageType: "sessionStorage",
  stateManagementUse: true,
});
export const getSecureSessionItem = (key: string) => {
  let decryptValue = secureSessionStorage.getItem(key);
  let data: any = null;
  if (decryptValue) {
    data = JSON.parse(decryptValue);
  }
  return data;
};
export const removeSecureSessionItem = (key: string) => {
  secureSessionStorage.removeItem(key);
};
export const clearSecureSession = () => {
  secureSessionStorage.clear();
};
const ApplicationState = (props: any) => {
  /******************** Related to Construction ***********************/
  const [materialOptions, setMaterialOptions] = useState<any>([]);
  const [itemOption, setItemOptionOptions] = useState<any>([]);
  const [measurementOption, setMeasurementOption] = useState<any>([]);
  const [cVendorOption, setCVendorOption] = useState<any>([]);
  const [gstOption, setGstOption] = useState<any>([]);
  const getMaterialOptions = () => {
    MastersService.getMaterialTypeOptions("", 0, 0, false).then((res: any) => {
      setMaterialOptions(
        res?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
          items: itm?.items,
        }))
      );
    });
  };
  const getItemOption = () => {
    MastersService.getItemsOptions("", "", "", 0, 0, false).then((res: any) => {
      setItemOptionOptions(
        res?.items?.map((itm: any) => ({
          label: itm?.name,
          value: itm?.id,
          materialUsed: itm?.materialUsed,
        }))
      );
    });
  };
  const getMeasurementOption = () => {
    MastersService.getMeasurementOptions("", 0, 0, false).then((res: any) => {
      setMeasurementOption(
        res?.items?.map((itm: any) => ({ label: itm?.name, value: itm?.id }))
      );
    });
  };
  const getCVendorOptions = (val: any = "") => {
    VendorService.getList("", val, "", "", 1, 0, 0, "", "", "", "", false).then(
      (res: any) => {
        if (res) {
          setCVendorOption(
            res?.items?.map((itm: any) => ({
              label: itm?.name,
              value: itm?.id,
            }))
          );
        }
      }
    );
  };
  const getGstOptions = (val: any = "") => {
    MastersService.getGstOptions("", 0, 15, false).then((res: any) => {
      if (res) {
        setGstOption(
          res?.items?.map((itm: any) => ({
            label: itm?.name,
            value: itm?.id,
          }))
        );
      }
    });
  };
  /******************** Related to Products ***********************/
  const [unitOptions, setunitOptions] = useState<any>([]);
  const [variantTypes, setVariantTypes] = useState<any>([]);
  const [vendorOptions, setVendorOptions] = useState<any>([]);
  const [assigneeOptions, setAssigneeOptions] = useState<any>([]);
  const [userRoles, setUserRoles] = useState<any>([]);
  const [api, contextholder] = notification.useNotification();

  type notificationType = "error" | "success";

  const openNotification = (type: notificationType, message: string) => {
    api[type]({
      message: message,
      duration: 1,
      placement: "topRight",
    });
  };
  // get assignee select options
  const getAssigneeOptions = () =>
    MasterService.getAssignee().then((res) => {
      if (res) {
        setAssigneeOptions(
          res?.map((r: any) => ({
            label: r?.name,
            value: r?.id,
            department: r?.department?.name,
            designation: r?.designation?.name,
          }))
        );
      }
    });

  // get vendor select options
  const getVendorOptions = (vendorOptSearch: string) => {
    VendorService.getVendorOptions(vendorOptSearch).then((res) => {
      if (res) {
        setVendorOptions(
          res?.map((r: any) => ({
            label: r.name,
            value: r.id,
          }))
        );
      }
    });
  };
  //get list of variants
  const getVariants = (val: any = "") =>
    MasterService.getVariant(val, 0, 30, false).then((res) => {
      setVariantTypes(
        res?.items?.map((r: any) => ({
          label: r.name,
          value: r.id,
          varOptions: r?.options,
        }))
      );
    });

  // GET list of unit
  const getUnits = () =>
    MasterService.getUnitOptions("", "", 0, 30, false).then((res) => {
      setunitOptions(
        res?.items?.map((r: any) => ({
          label: r.name,
          value: r.id,
        }))
      );
    });

  const postUnit = (params: any) => {
    MasterService.postUnit(params);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < dayjs().subtract(1, "day");
  };

  const getRolesOption = () => {
    UserRights.getUserRole("", false, 0, 10).then((res: any) => {
      if (res?.items?.length > 0) {
        setUserRoles(
          res?.items?.map((item: any) => ({
            label: item?.role,
            value: item?.role,
          }))
        );
      }
    });
  };

  /********* For Currency List *********/
  const [currency, setCurrency] = useState({ label: "$", value: 0 });
  const selectAfter = (
    <Select
      style={{ width: 60 }}
      defaultValue={currency}
      labelInValue
      size="middle"
      options={[
        { label: "$", value: 0 },
        { label: "€", value: 1 },
        { label: "£", value: 2 },
        { label: "¥", value: 3 },
        { label: "₹", value: 4 },
      ]}
      onChange={(e: any) => setCurrency(e)}
    />
  );

  /******************** Related to Cab ***********************/
  const [data, setData] = useState({
    vehicle: [{ label: "", value: "" }],
    driver: [{ label: "", value: "" }],
    trip: [{ label: "", value: "" }],
    shift: [{ label: "", value: "" }],
    round: [{ label: "", value: "" }],
    passengerType: [{ label: "", value: "" }],
    status: [{ label: "", value: "" }],
  });
  return (
    <ApplicationContext.Provider
      value={{
        /******************** Related to Products ***********************/
        unitOptions,
        postUnit,
        vendorOptions,
        variantTypes,
        assigneeOptions,
        userRoles,
        getVariants,
        getUnits,
        disabledDate,
        setVariantTypes,
        getVendorOptions,
        getAssigneeOptions,
        getRolesOption,
        setUserRoles,
        selectAfter,
        currency,
        setCurrency,
        /******************** Related to Construction ***********************/
        materialOptions,
        itemOption,
        measurementOption,
        cVendorOption,
        gstOption,
        getMaterialOptions,
        getItemOption,
        getMeasurementOption,
        getCVendorOptions,
        getGstOptions,
        /******************** Related to Cab ***********************/
        data,
        setData,
      }}
    >
      {props.children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationState;
