import React, { useEffect, useState } from "react";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
  Spin,
  Table,
  Select,
  Input,
  message,
  Row,
  Col,
  Button,
  Card,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  CategoryOption,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import ReportServices from "../../Services/ReportServices";

const { Search } = Input;

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("category", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelect"
            placeholder="Search..."
            options={CategoryOption}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={() => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const DPStockReport = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [data, setData] = useState<any>([]);
  const [listParams, setListParams] = useState({
    subCategorySearch: "",
    productGrpSearch: "",
    start: 0,
    length: 15,
    sortDir: "",
    sortCol: "",
  });

  function timeout(delay: number) {
    return new Promise(() => setTimeout(() => setLoading(false), delay));
  }
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter.columnKey;
      p.sortDir = sorter.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };

  useEffect(() => {
    setLoading(true);
    ReportServices.digitalStockReport(
      listParams.subCategorySearch,
      listParams.productGrpSearch,
      listParams.start,
      listParams.length,
      listParams.sortCol,
      listParams.sortDir
    ).then(async (res: any) => {
      if (res !== null || res?.status === true || res?.items.length > 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            productGroup: data?.productGroup,
            subCategory: data?.subCategory?.name,
            total: data?.total,
            inUse: data?.inUse,
            inRepair: data?.inRepair,
            repaired: data?.repaired,
            replaced: data?.replaced,
            defective: data?.defective,
            disposed: data?.disposed,
            inStock: data?.inStock,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      } else {
        messageApi.error(res?.message);
        await timeout(3000);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, shouldRefresh]);

  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Product-Category"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subCategory",
      key: "subCategory",
      width: 200,
      render: (val, record) => <div className="ps-2 font13">{val}</div>,

      // render: (value, row, index) => {
      //   const trueIndex = index + listParams.start;
      //   const obj: any = {
      //     children: value,
      //     props: {},
      //   };
      //   if (index >= 1 && value === data[trueIndex - 1]?.subCategory) {
      //     console.log(
      //       "if loop====== obj",
      //       obj,
      //       "index",
      //       index,
      //       "data[true-1]",
      //       data[trueIndex - 1]
      //     );
      //     obj.props.rowSpan = 0;
      //   } else {
      //     for (
      //       let i = 0;
      //       trueIndex + i !== data.length &&
      //       value === data[trueIndex + i]?.subCategory;
      //       i += 1
      //     ) {
      //       console.log("else loop ==========i", i);
      //       obj.props.rowSpan = i + 1;
      //     }
      //     // const occurCount = data.filter(
      //     //   (data: any) => data?.subCategory === value
      //     // ).length;

      //     // obj.props.rowSpan = occurCount;
      //   }
      //   console.log(
      //     "outside ==========trueIndex",
      //     trueIndex,
      //     "value",
      //     value,
      //     "obj",
      //     obj
      //   );
      //   return obj;
      // },
    },
    {
      title: (
        <THeader
          title={"Product Group"}
          inputName={"productGrpSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productGroup",
      key: "productGroup",
      width: 250,
      render: (val, record) => <div className="ps-2 font13">{val?.name}</div>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      // width: 160,
      //   sorter: true,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
    {
      title: "In Use",
      dataIndex: "inUse",
      key: "inUse",
      //   sorter: true,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
    {
      title: "In Repair",
      dataIndex: "inRepair",
      key: "inRepair",
      //   sorter: true,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
    {
      title: "Dead",
      dataIndex: "disposed",
      key: "disposed",
      //   sorter: true,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
    {
      title: "Defective",
      dataIndex: "defective",
      key: "defective",
      //sorter: true,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
    {
      title: "In Stock",
      dataIndex: "inStock",
      key: "inStock",
      // width: "120px",
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Digital Product Report"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  style={{ fontWeight: "600" }}
                  icon={
                    <VerticalAlignBottomOutlined style={{ fontSize: "24px" }} />
                  }
                  disabled
                ></Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* <Row justify={"end"} className="mb-3">
        <Col>
          <Button
            size="small"
            disabled
            // onClick={() => {}}
            icon={<VerticalAlignBottomOutlined style={{ fontSize: "24px" }} />}
          />
        </Col>
      </Row> */}
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          // className="InvTable"
          scroll={{ y: `calc(100vh - 350px)` }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} className="fw600">
                    {" "}
                    <span>TOTAL</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={2} className="fw600" align="right">
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.total));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600" align="right">
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.inUse));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="fw600" align="right">
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.inRepair));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600" align="right">
                    {" "}
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.disposed));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="fw600" align="right">
                    {" "}
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.defective));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="fw600" align="right">
                    {" "}
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.inStock));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>
    </>
  );
};

export default DPStockReport;
