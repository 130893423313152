import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { ApplicationRouteLink } from "../CapsitechInventoryComponents/Routes/ApplicationRoutePath";
import secureLocalStorage from "react-secure-storage";
import {
  getSecureSessionItem,
  userRightsSessionKey,
} from "../CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";
const navigatePath = (userContent: any) => {
  // console.log("userC = ", userContent);
  let navigateUrl: string = "/inventory/";
  // Home Dashboard
  if (userContent?.dashboard?.showDashboard) {
    return "/inventory";
  }
  // Vendor
  if (userContent?.vendor?.showVendor) {
    return navigateUrl + ApplicationRouteLink.Vendor;
  }
  // Products
  if (userContent?.products?.showProduct) {
    if (userContent?.products?.cleaning) {
      return navigateUrl + ApplicationRouteLink.Cleaning;
    }
    if (userContent?.products?.stationery) {
      return navigateUrl + ApplicationRouteLink.Stationery;
    }
    if (userContent?.products?.gardenOrPlants) {
      return navigateUrl + ApplicationRouteLink.GardenOrPlants;
    }
    if (userContent?.products?.emergencyMedicalKit) {
      return navigateUrl + ApplicationRouteLink.EmergencyMedicalKit;
    }
    if (userContent?.products?.paidSoftware) {
      return navigateUrl + ApplicationRouteLink.PaidSoftware;
    }
    if (userContent?.products?.digitalProduct) {
      return navigateUrl + ApplicationRouteLink.DigitalProduct;
    }
    if (userContent?.products?.officeEquipments) {
      return navigateUrl + ApplicationRouteLink.OfficeEquipments;
    }
  }
  // Masters
  if (userContent?.master?.showMaster) {
    if (userContent?.master?.unit) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersUnit;
    }
    if (userContent?.master?.variant) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersVariant;
    }
    if (userContent?.master?.option) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersOption;
    }
    if (userContent?.master?.seating) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersSeating;
    }
    if (userContent?.master?.subCategory) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersSubCategory;
    }
    if (userContent?.master?.productGroup) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MasterProductGroup;
    }
    if (userContent?.master?.department) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersDepartment;
    }
    if (userContent?.master?.designation) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersDesignation;
    }
    if (userContent?.master?.assignee) {
      return navigateUrl + ApplicationRouteLink.MastersLinks.MastersAssignee;
    }
    // if (userContent?.master?.materials) {
    //   return navigateUrl + ApplicationRouteLink.MastersLinks.MastersMaterials;
    // }
    // if (userContent?.master?.constructionItem) {
    //   return navigateUrl + ApplicationRouteLink.MastersLinks.MastersConstructionItem;
    // }
    // if (userContent?.master?.measurement) {
    //   return navigateUrl + ApplicationRouteLink.MastersLinks.MastersMeasurement;
    // }
  }
  if (userContent?.service?.showService) {
    return navigateUrl + ApplicationRouteLink.Services;
  }

  if (userContent?.construction?.showPurchase) {
    return navigateUrl + ApplicationRouteLink.Construction.Purchases;
  }
  if (userContent?.construction?.master?.cMaterialType) {
    return navigateUrl + ApplicationRouteLink.Construction.Category;
  }
  if (userContent?.construction?.master?.cItems) {
    return navigateUrl + ApplicationRouteLink.Construction.SubCategory;
  }
  if (userContent?.construction?.master?.cMeasurement) {
    return navigateUrl + ApplicationRouteLink.Construction.Measurement;
  }
  // Attendance specific
  if (userContent?.attendance?.showAttendance) {
    if (userContent?.attendance?.showUserCategory) {
      return navigateUrl + ApplicationRouteLink.Attendance.UserCategory;
    }
    if (userContent?.attendance?.showAttendanceSheet) {
      return navigateUrl + ApplicationRouteLink.Attendance.UserAttendance;
    }
  }

  if (userContent?.report?.showReport) {
    return navigateUrl + ApplicationRouteLink.ReportsLinks.Report;
  }
  return "/inventory";
};

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      // extra={<Button type="primary">Back Home</Button>}
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(navigatePath(getSecureSessionItem(userRightsSessionKey)));
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};

export default PageNotFound;
