import React, { useEffect, useState } from "react";
import { Drawer, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import ProductStateService from "../../Services/ProductStateService";
import { dayjs } from "../../../Utilities/dayjs";

interface IReportSummary {
  productData: any;
  onDismiss: () => void;
}
let DigitalProductStatus: any = {
  0: "In Stock",
  1: "InUSe",
  2: "Defective",
  3: "In Repair",
  4: "Repaired",
  5: "Replaced",
  6: "Dead",
};
const ReportSummary = (props: IReportSummary) => {
  const [data, setData] = useState<any>([]);

  let columns: ColumnsType<any> = [
    {
      title: "Seat No.",
      dataIndex: "seatAllotted",
      key: "seatAllotted",
      className: "fw500",
      width: "250px",
      render: (seatAllotted: any) => {
        // return <>{seatAllotted === null ? `No Seat` : seatAllotted?.name}</>;
        return <>{seatAllotted}</>;
      },
    },
    {
      title: "ASSIGNEE",
      dataIndex: "assignee",
      key: "assignee",
      className: "fw500",
      width: "250px",
      render: (val) => val?.name,
    },
    {
      title: "STATE",
      dataIndex: "productState",
      key: "productState",
      render: (val: any) => DigitalProductStatus[val],
    },
    {
      title: "QUANTITY",
      dataIndex: "qty",
      key: "qty",
      render: (val: any) =>
        props?.productData?.category === 1 ||
        props?.productData?.category === 2 ||
        props?.productData?.category === 3 ||
        props?.productData?.category === 4
          ? val
          : "-",
    },
    {
      title: "ASSIGNED DATE",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "ISSUED DATE",
      dataIndex: "issueDate",
      key: "issueDate",
    },
    // {
    //   title: "AVAILABLE STOCK",
    //   dataIndex: "available",
    //   key: "available",
    // },

    // {
    //   title: "DISCARDED STOCK",
    //   dataIndex: "discarded",
    //   key: "discarded",
    // },
  ];
  if (props?.productData?.category !== 6)
    columns = columns.filter((x) => x.key !== "seatAllotted");

  useEffect(() => {
    ProductStateService.getList(
      props?.productData?.category,
      props?.productData?.id
    ).then((res) => {
      if (res) {
        setData(
          res?.items?.map((itm: any, i: number) => ({
            key: i,
            date: dayjs(itm?.createdBy?.date).format("DD/MM/YYYY"),
            issueDate: dayjs(itm?.issueDate).format("DD/MM/YYYY"),
            productState: itm?.digiProductState,
            assignee: itm?.assignee,
            seatAllotted:
              props?.productData?.seatAllotted === null
                ? `No Seat`
                : props?.productData?.seatAllotted?.name,

            // given: itm?.givenStock,
            // returned: itm?.returnedStock,
            // used: itm?.usedStock,
            // discarded: itm?.discardedStock,
            qty:
              itm?.productState === 1
                ? itm?.givenStock
                : itm?.productState === 2
                ? itm?.usedStock
                : itm?.productState === 3
                ? itm?.returnedStock
                : itm?.discardedStock,
          }))
        );
      }
    });
  }, [props?.productData?.category, props?.productData?.id]);

  return (
    <>
      <Drawer
        // className="InvAddDrawer"
        open={true}
        closable={false}
        title={`Summary of ${props.productData?.productName}`}
        width="100%"
        extra={<CloseOutlined onClick={props.onDismiss} />}
        // style={{ height: `calc(100vh - 100px)` }}
        getContainer={false}
      >
        <Table
          // bordered
          columns={columns}
          pagination={false}
          dataSource={data}
          className="InvTable"
          // summary={(total) => {
          //   let totalGiven = 0;
          //   let totalUsed = 0;
          //   let totalReturn = 0;
          //   let totalDiscard = 0;
          //   let totalAvailable = 0;

          //   // eslint-disable-next-line array-callback-return
          //   total.map((itm: any) => {
          //     totalGiven += itm?.given;
          //     totalUsed += itm?.used;
          //     totalReturn += itm?.returned;
          //     totalDiscard += itm?.discarded;
          //     totalAvailable += itm?.available;
          //   });
          //   return (
          //     <>
          //       <Table.Summary.Row>
          //         <Table.Summary.Cell index={0} className="text-light fw500">
          //           TOTAL
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={1}>
          //           {totalGiven}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={2}>{totalUsed}</Table.Summary.Cell>
          //         <Table.Summary.Cell index={3}>
          //           {totalReturn}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={4}>
          //           {totalAvailable}
          //         </Table.Summary.Cell>
          //         <Table.Summary.Cell index={5}>
          //           {totalDiscard}
          //         </Table.Summary.Cell>
          //       </Table.Summary.Row>
          //     </>
          //   );
          // }}
        />
      </Drawer>
    </>
  );
};

export default ReportSummary;
