import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import ProductService, {
  IProductApiProps,
} from "../../Services/ProductService";
import { Utils } from "../../../Utilities/Utils";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import { dayjs } from "../../../Utilities/dayjs";
import { ProductStatusFlag } from "../../Context/ApplicationContext/ApplicationState";
import TextArea from "antd/es/input/TextArea";

interface IEditProductProps {
  isPending: number;
  subCategoryOptions: any;
  productData: IProductApiProps;
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProduct = (props: IEditProductProps) => {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [editData, setEditData] = useState<any>({});
  const [enableEdit, setEnableEdit] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const { unitOptions, vendorOptions, getVendorOptions } =
    useContext(ApplicationContext);

  useEffect(() => {
    setEditData({
      ...props?.productData,
      issueDate:
        props?.productData?.issueDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.issueDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.issueDate)),
      expiryDate:
        props?.productData?.expiryDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.expiryDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.expiryDate)),
      purchaseDate:
        props?.productData?.purchaseDate === "0001-01-01T00:00:00Z" ||
        props?.productData?.purchaseDate === "0001-01-01T00:00:00"
          ? undefined
          : dayjs(new Date(props?.productData?.purchaseDate)),
      subCategory: {
        label: props?.productData?.subCategory?.name,
        value: props?.productData?.subCategory?.id,
      },
      unit: {
        label: props?.productData?.unit?.name,
        value: props?.productData?.unit?.id,
      },
      vendor: {
        label: props?.productData?.vendor?.name,
        value: props?.productData?.vendor?.id,
      },
    });
  }, [props?.productData]);

  useEffect(() => {
    form.setFieldsValue(editData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, enableEdit]);

  const saveUpdatedData = () => {
    setBtnDisable(true);
    form
      .validateFields()
      .then(async (values) => {
        let val = {
          ...editData,
          ...values,
          expiryDate: values?.expiryDate,
          purchaseDate: values?.purchaseDate,
          issueDate: values?.issueDate,
          subCategory:
            values?.subCategory !== undefined
              ? {
                  name: values?.subCategory?.label,
                  id: values?.subCategory?.value,
                }
              : null,
          unit:
            values?.unit !== undefined
              ? { name: values?.unit?.label, id: values?.unit?.value }
              : null,
          vendor:
            values?.vendor !== undefined
              ? { name: values?.vendor?.label, id: values?.vendor?.value }
              : null,
          id: editData?.id,
        };
        await ProductService.post(val).then((res) => {
          if (res?.status) {
            setEnableEdit(false);
            setBtnDisable(false);
            messageApi.success("Information Updated Successfully");
            props?.setShouldRefresh((x: boolean) => !x);
            props?.setIsDataSaved(true);
          } else {
            messageApi.error(res?.message);
            setBtnDisable(false);
          }
        });
      })
      .catch(() => {
        setBtnDisable(false);
      });
  };
  return (
    <>
      {contextHolder}
      <Row justify={"space-between"} align={"bottom"} className="pb-3">
        <Col className="font16 fw600"></Col>
        {enableEdit ? (
          <Col>
            <Space>
              <Button
                icon={<SaveOutlined />}
                // className="border-none font16"
                onClick={saveUpdatedData}
                disabled={btnDisable}
              />
              <Button
                icon={<CloseOutlined />}
                // className="border-none font16"
                onClick={() => {
                  setEnableEdit(false);
                }}
                disabled={btnDisable}
              />
            </Space>
          </Col>
        ) : (
          <Col>
            <Button
              icon={<EditOutlined />}
              // className="border-none font16"
              onClick={() => setEnableEdit(true)}
              disabled={
                (props.isPending === ProductStatusFlag.Pending
                  ? true
                  : false) || !editData?.isEditAllowed
              }
            />
          </Col>
        )}
      </Row>

      <Form
        form={form}
        colon={false}
        // size="small"
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 14 }}
        className="InvDForm"
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Field can't be empty." }]}
          required={false}
        >
          {enableEdit ? <Input autoFocus /> : editData?.name}
        </Form.Item>

        <Form.Item label="Product-Category" name="subCategory">
          {enableEdit ? (
            <Select
              allowClear
              options={props.subCategoryOptions}
              labelInValue
            />
          ) : editData?.subCategory?.label === undefined ? (
            "-"
          ) : (
            editData?.subCategory?.label
          )}
        </Form.Item>
        <Form.Item label="Purchase Date" name="purchaseDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  form.setFieldValue("purchaseDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.purchaseDate, "-", false)
          )}
        </Form.Item>

        <Form.Item
          label="Vendor Name"
          name="vendor"
          rules={[{ required: true, message: "Field can't be empty." }]}
          required={false}
        >
          {enableEdit ? (
            <Select
              allowClear
              options={vendorOptions}
              labelInValue
              showSearch
              filterOption={false}
              onSearch={(val) => getVendorOptions(val)}
              placeholder="Search Vendor"
            />
          ) : editData?.vendor?.label === undefined ? (
            "-"
          ) : (
            editData?.vendor?.label
          )}
        </Form.Item>
        {/* 
        <Form.Item label="Item ID/SKU" name="itemId">
          {enableEdit ? <Input readOnly /> : editData?.itemId}
        </Form.Item> */}

        {/* <Form.Item label="Brand" name="brand">
          {enableEdit ? (
            <Input />
          ) : (
            editData?.brand
          )}
        </Form.Item> */}

        <Form.Item label="Unit" name="unit">
          {enableEdit ? (
            <Select allowClear options={unitOptions} labelInValue />
          ) : editData?.unit?.label === undefined ? (
            "-"
          ) : (
            editData?.unit?.label
          )}
        </Form.Item>
        <Form.Item label="Invoice Number" name="invoiceNumber">
          {enableEdit ? (
            <Input />
          ) : editData?.invoiceNumber !== null ? (
            editData?.invoiceNumber
          ) : (
            "-"
          )}
        </Form.Item>
        <Form.Item
          label="Total Price"
          name="totalPrice"
          rules={[
            // {
            //   required: true,
            //   message: "Please enter rate",
            // },
            {
              pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
              message: "Please enter number upto two decimal places only!",
            },
          ]}
        >
          {enableEdit ? (
            <InputNumber style={{ width: "100%" }} />
          ) : (
            editData?.totalPrice
          )}
        </Form.Item>

        <Form.Item label="Expiry Date" name="expiryDate">
          {enableEdit ? (
            <DatePicker
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
              onChange={(val: any) => {
                if (val === null) {
                  form.setFieldValue("expiryDate", undefined);
                }
              }}
            />
          ) : (
            Utils.getFormattedDate(editData?.expiryDate, "-", false)
          )}
        </Form.Item>
        {/* <Form.Item label="Issue date" name="issueDate" initialValue={dayjs()}>
          {enableEdit ? (
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          ) : (
            Utils.getFormattedDate(editData?.issueDate, "-", false)
          )}
        </Form.Item> */}
        <Form.Item label="Description" name="description">
          {enableEdit ? (
            <TextArea />
          ) : editData?.description ? (
            editData?.description
          ) : (
            "-"
          )}
        </Form.Item>
        {/* <Col className="font16 fw600 py-3">Purchase Details</Col> */}
        {/* 
        <Form.Item
          label="Vendor Name"
          name="vendor"
          rules={[{ required: true, message: "Field can't be empty." }]}
          required={false}
        >
          {enableEdit ? (
            <Select
              allowClear
              options={vendorOptions}
              labelInValue
              showSearch
              filterOption={false}
              onSearch={(val) => getVendorOptions(val)}
              placeholder="Search Vendor"
            />
          ) : editData?.vendor?.label === undefined ? (
            "-"
          ) : (
            editData?.vendor?.label
          )}
        </Form.Item> */}

        {/* <Form.Item label="Purchase Date" name="purchaseDate">
          {enableEdit ? (
            <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          ) : (
            Utils.getFormattedDate(editData?.purchaseDate, "-", false)
          )}
        </Form.Item> */}
        {/* 
        <Form.Item label="Purchase Type" name="purchaseType">
          {enableEdit ? (
            <Select
              allowClear
              options={[
                { value: 0, label: "UPI" },
                { value: 1, label: "Cash" },
                { value: 2, label: "Cheque" },
                { value: 3, label: "Net Banking" },
                { value: 4, label: "Credit/Debit Card" },
              ]}
            />
          ) : editData?.purchaseType === 0 ? (
            "UPI"
          ) : editData?.purchaseType === 1 ? (
            "Cash"
          ) : editData?.purchaseType === 2 ? (
            "Cheque"
          ) : editData?.purchaseType === 3 ? (
            "Net Banking"
          ) : (
            "Credit/Debit Card"
          )}
        </Form.Item> */}

        {/* <Form.Item label="Invoice Number" name="invoiceNumber">
          {enableEdit ? (
            <Input />
          ) : editData?.invoiceNumber !== null ? (
            editData?.invoiceNumber
          ) : (
            "-"
          )}
        </Form.Item> */}
        {/* 
        <Form.Item label="Transaction ID" name="transactionId">
          {enableEdit ? (
            <Input />
          ) : editData?.transactionId !== null ? (
            editData?.transactionId
          ) : (
            "-"
          )}
        </Form.Item>

        <Form.Item label="Price Per Unit" name="pricePerUnit">
          {enableEdit ? (
            <InputNumber style={{ width: "100%" }} />
          ) : (
            editData?.pricePerUnit
          )}
        </Form.Item>

        <Form.Item label="Add Tax" name="tax">
          {enableEdit ? (
            <InputNumber style={{ width: "100%" }} />
          ) : (
            `${editData?.tax}%`
          )}
        </Form.Item>

        <Form.Item label="Total Price" name="totalPrice">
          {enableEdit ? (
            <InputNumber style={{ width: "100%" }} />
          ) : (
            editData?.totalPrice
          )}
        </Form.Item>

        <Form.Item label="End of Life" name="expiryDate">
          {enableEdit ? (
            <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          ) : (
            Utils.getFormattedDate(editData?.expiryDate, "-", false)
          )}
        </Form.Item>
        <Form.Item label="Issue date" name="issueDate" initialValue={dayjs()}>
          {enableEdit ? (
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
          ) : (
            Utils.getFormattedDate(editData?.issueDate, "-", false)
          )}
        </Form.Item> */}
      </Form>
    </>
  );
};

export default EditProduct;
