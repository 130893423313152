import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Button,
  Table,
  Spin,
  message,
  Tooltip,
} from "antd";
import { LoadingOutlined, MailOutlined, PlusOutlined } from "@ant-design/icons";
import "../../../Common/MainLayout.css";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import AddEditAttendance from "./AddEditAttendance";
import MasterService from "../../../Services/MasterService";
import AttendanceService from "../../../Services/AttendanceService";
import Icon from "@ant-design/icons/lib/components/Icon";

const { Option } = Select;
interface DataType {
  key: React.Key;
  sno: number;
  name: string;
}
const UserAttendance: React.FC = () => {
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [trigger, setTrigger] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [batchLoader, setBatchLoader] = React.useState<boolean>(false);
  const [tableLoader, setIsTableLoader] = React.useState<boolean>(false);

  const [dataSource, setDataSource] = React.useState<DataType[]>([]);
  const [monthDates, setMonthDates] = useState<any>([]);
  const [monthDays, setMonthDays] = useState<any>([]);

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedUserCategory, setSelectedUserCategory] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const [userCategoryOptions, setUserCategoryOptions] = useState<any[]>([]);
  const getUserCategory = async () => {
    let res = await MasterService.getUserCategoryList("", "", 0, 15, true);
    if (res?.items?.length >= 0) {
      const result = res?.items;
      const transformedItems = result.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setUserCategoryOptions([...transformedItems]);
    } else {
      message.error(res?.message);
    }
  };
  React.useEffect(() => {
    setPageLoader(true);
    calDate(dayjs().startOf("month"), dayjs().endOf("month"));
    getUserCategory();
  }, []);

  useEffect(() => {
    getAttendanceSheetData(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, selectedMonth, selectedUserCategory, pagingItems]);

  useEffect(() => {
    if (!tableLoader && !batchLoader) {
      setPageLoader(false);
    }
  }, [batchLoader, tableLoader]);

  //------------- Get Students Record Endpoint ----------//
  const getAttendanceSheet = async (
    start: any,
    length: any,
    batch: any,
    month: any
  ) => {
    setIsTableLoader(true);
    let params = {
      month: month.format("MM"),
      year: month.format("YYYY"),
      uId: batch ?? "",
      start: start,
      length: length,
    };
    let res = await AttendanceService.get_att_in_sheet(params);
    if (res) {
      setPagingItems((p) => {
        p.totalRecords = res?.totalRecords;
        return p;
      });
      setDataSource(
        res?.items?.map((item: any, index: number) => ({
          key: index,
          sno: index + 1,
          name: item?.name,
          email: item?.email,
          enrDate: item?.enrolledDate,
          records: item?.records,
        }))
      );
    }
    setIsTableLoader(false);
  };

  const getAttendanceSheetData = (start: any, length: any) => {
    if (selectedMonth) {
      getAttendanceSheet(start, length, selectedUserCategory, selectedMonth);
    } else {
      if (!openDrawer) {
        // openNotification("warning", "Please select valid month");
      }
    }
  };

  const calDate = (startDate: any, endDate: any) => {
    let dates = [];
    let days = [];
    for (
      let date = startDate;
      date.isSameOrBefore(endDate);
      date = date.add(1, "day")
    ) {
      dates.push(dayjs(date));
      days.push(dayjs(date).format("ddd"));
    }
    setMonthDates(dates);
    setMonthDays(days);
  };

  const columns2: ColumnsType<DataType> = [
    // {
    //   title: "S.No.",
    //   dataIndex: "sno",
    //   key: "sno",
    //   width: 55,
    //   align: "start",
    //   fixed: "left",
    //   render: (text: any, record: any) => {
    //     return <span className="ps-2 text-dark font13">{record?.sno}</span>;
    //   },
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 185,
      fixed: "left",
      ellipsis: true,
      render: (text: any, record: any) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <Button
            type="text"
            className="text-dark font13"
            icon={<MailOutlined />}
            style={{ color: "#4096FF" }}
          >
            {record?.name?.first}
            </Button> */}
          <div>
            <span className="text-dark font13">
              {record?.name?.first}
            </span>
          </div>
          <div>
            <Tooltip
              title={record?.email}
            // style={{ backgroundColor: "red", color: "black" }}
            >
              <MailOutlined
                style={{ color: "#4096FF" }}
                playsInline
                className="ps-2"
              />
            </Tooltip>
          </div>
          {/* <div className="text-dark font13" title={record?.name?.first}>
            {record?.name?.first} */}
          {/* {record?.name?.first}
          <MailOutlined
            style={{ color: "#4096FF" }}
            playsInline
            name={record?.email}
            // value={record?.email}
            htmlFor={record?.email}
          /> */}
          {/* </div> */}
          {/* <div
            style={{
              fontSize: "9px",
              fontWeight: "900",
              color: "#4096ff",
            }}
            title={record?.email}
          >
            {record?.email}
          </div> */}
        </div>
      ),
    },
    ...monthDates.map((date: any, dateIndex: any) => ({
      title: <span className="font13">{date.format("D")}</span>,
      dataIndex: "",
      children: [
        {
          title: <span className="font13">{monthDays[dateIndex]}</span>,
          render: (text: any, record: any) => renderItem(date, record),
        },
      ],
    })),
  ];

  const drawerClose = () => {
    setOpenDrawer(false);
  };

  const renderItem = (date1: any, record: any) => {
    const date = date1.format("D");
    const day = date1.format("ddd");

    let attStatus = record?.records?.find(
      (x: any) => x?.day === date
    )?.attendance;
    const cellDateFormatted = dayjs(
      dayjs(
        `${dayjs(selectedMonth).year()}-${dayjs(selectedMonth).month() + 1
        }-${Number(date)}`
      ).format("DD/MM/YYYY"),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const enrollDateFormatted = dayjs(
      dayjs(record?.enrDate).format("DD/MM/YYYY"),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    let isEnrolled =
      dayjs(cellDateFormatted).isSameOrAfter(enrollDateFormatted);
    let isFuture = dayjs(cellDateFormatted).isSameOrAfter(dayjs());

    return (
      <div style={{ textAlign: "center", fontSize: "13px" }}>
        {!isFuture && isEnrolled ? (
          <span
            className="ps-2"
            style={{
              padding: "8px 6px",
              fontWeight: attStatus === "A" ? "700" : "",
              color: attStatus
                ? attStatus === "P"
                  ? "green"
                  : attStatus === "A"
                    ? "red"
                    : "red"
                : // : day === "Sun" || day === "Sat"
                // ? "black"
                "black",
            }}
          >
            {attStatus === "P"
              ? "P"
              : attStatus === "A"
                ? "A"
                : // : day === "Sun" || day === "Sat"
                // ? "H"
                " "}
          </span>
        ) : (
          " "
        )}
      </div>
    );
  };

  // const renderItem = (date1: any, record: any) => {
  //     const date = date1.format("D");
  //     const day = date1.format("ddd");

  //     let attStatus = record?.records?.find((x: any) => x?.day === date)?.attendance;
  //     const cellDateFormatted = dayjs(dayjs(`${dayjs(selectedMonth).year()}-${dayjs(selectedMonth).month() + 1}-${Number(date)}`).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');
  //     const enrollDateFormatted = dayjs(dayjs(record?.enrDate).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');

  //     let isEnrolled = dayjs(cellDateFormatted).isSameOrAfter(enrollDateFormatted);
  //     let isFuture = dayjs(cellDateFormatted).isSameOrAfter(dayjs());
  //     console.log("date1 = ", date1)
  //     console.log("attStatus = ", attStatus)
  //     console.log("cellDateFormatted = ", cellDateFormatted)
  //     console.log("enrollDateFormatted = ", enrollDateFormatted)
  //     console.log("isEnrolled = ", isEnrolled)
  //     console.log("isFuture = ", isFuture)

  //     return (
  //         <p style={{ textAlign: "center" }}>
  //             {
  //                 (isEnrolled) ?
  //                     <span style={{ padding: "8px 6px", color: attStatus ? attStatus === "P" ? "green" : attStatus === "A" ? "red" : "red" : day === "Sun" || day === "Sat" ? "black" : "black" }}>
  //                         {
  //                             attStatus === "P" ? "P" : attStatus === "A" ? "A" : day === "Sun" || day === "Sat" ? "H" : "-"
  //                         }
  //                     </span> : "-"
  //             }
  //         </p>
  //     )
  // }
  return (
    <>
      <AddEditAttendance
        openDrawer={openDrawer}
        drawerClose={drawerClose}
        setTrigger={setTrigger}
      />

      <div className="shadowLight roundedCornerMedium bg-white  h100">
        <Spin
          spinning={pageLoader}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row justify="space-between">
            <Col>
              <Button
                className="cit-add-btn mb-1"
                onClick={() => setOpenDrawer(true)}
                style={{ marginBottom: "20px" }}
                type="primary"
              >
                <PlusOutlined /> Attendance
              </Button>
            </Col>
            <Col>
              <Select
                popupClassName="cit-select-box"
                className="me-2"
                placeholder={"Select  Category"}
                allowClear
                showSearch
                options={userCategoryOptions}
                style={{ width: 160 }}
                onSelect={(val: any) => {
                  setSelectedUserCategory(val);
                }}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onClear={() => {
                  setSelectedUserCategory("");
                }}
              ></Select>

              <DatePicker
                allowClear={false}
                className="me-2"
                value={selectedMonth}
                picker="month"
                format={"MMM-YYYY"}
                disabledDate={(current: any) => {
                  return current && current > dayjs().endOf("month");
                }}
                onChange={(val: any) => {
                  calDate(
                    dayjs(val).startOf("month"),
                    dayjs(val).endOf("month")
                  );
                  setSelectedMonth(val);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Table
              className="cit-att-sheet"
              loading={{
                spinning: tableLoader,
                indicator: <Spin indicator={<LoadingOutlined />} />,
              }}
              columns={columns2}
              dataSource={dataSource}
              bordered
              size="small"
              scroll={{ x: 1800, y: 750 }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: [15, 30, 50, 100],
                position: ["bottomRight"],
              }}
              onChange={(paging, filter, sort: any) => {
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
            />
          </Row>
        </Spin>
      </div>
    </>
  );
};

export default UserAttendance;
