import React from 'react'

type Props = {}

const UserProfile = (props: Props) => {
    return (
        <div>UserProfile</div>
    )
}

export default UserProfile