import React from 'react'

type Props = {}

const OfficeEquipmentsDashboard = (props: Props) => {
  return (
    <div>OfficeEquipmentsDashboard</div>
  )
}

export default OfficeEquipmentsDashboard