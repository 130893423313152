import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Badge,
  Button,
  Col,
  Drawer,
  Empty,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  TableProps,
  Tabs,
  TabsProps,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { dayjs } from "../../../Utilities/dayjs";
import ProductService, { Category } from "../../Services/ProductService";
import "../../Common/Common.css";
import { pageSizeOption } from "../../Context/ApplicationContext/ApplicationState";
import ProductStateService from "../../Services/ProductStateService";
import ModifyState from "./ModifyState";
const { Search } = Input;

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div
        className="searchDivStyle"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div>{title}</div>
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("status", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelect"
            placeholder="Search..."
            defaultValue={{ value: "-1", label: "All" }}
            options={[
              { value: "-1", label: "All" },
              { value: "0", label: "In Stock" },
              { value: "1", label: "In Use" },
              { value: "2", label: "Used" },
            ]}
          />
        ) : (
          <Search
            placeholder="Search..."
            className="InvSearchInput"
            allowClear
            onChange={(e) =>
              changeListParams(inputName, e.target?.value?.trim())
            }
            onSearch={(val) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
            style={{
              marginLeft: -3,
              padding: "4px 0px",
              borderBottom: "1px solid #d3d3d3",
            }}
          />
        )}
      </div>
    </>
  );
};

const ViewModifySummary = (props: any) => {
  console.log(props.selectedRecord, "<");

  const [show, setShow] = useState("");
  const [data, setData] = useState<any>([]);
  const [summaryData, setSummaryData] = useState<any>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isPending, setIsPending] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [spinLoading, setSpinLoading] = useState(true);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [listParams, setListParams] = useState({
    assignee: "",
    sortDir: "",
    sortCol: "",
    fromDate: "",
    toDate: "",
    start: 0,
    length: 15,
  });

  const [messageApi, contextHolder] = message.useMessage();
  const error = (mes: string) => {
    messageApi.error(mes, 1);
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };
  const columns: any = [
    {
      // title: (
      //     <THeader
      //         title={"Summary"}
      //         inputName={"nameSearch"}
      //         changeListParams={changeListParams}
      //         setShouldRefresh={setShouldRefresh}
      //         listParams={listParams}
      //         setListParams={setListParams}
      //         ifSelect={false}
      //     />
      // ),
      title: "Adjustment",
      dataIndex: "creationDate",
      key: "creationDate",
      align: "center",

      width: 100,
      // sorter: true,
      // showSorterTooltip: false,
      render: (creationDate: any, record: any) => (
        <div
          //   type="link"
          // onClick={() => {
          //     setSelectedRowKeys(record.key);
          //     if (record.productStatusFlag === ProductStatusFlag.Pending) {
          //         setIsPending(ProductStatusFlag.Pending);
          //     } else {
          //         setIsPending(ProductStatusFlag.Approved);
          //     }
          //     setShow("ProductDetails");
          //     setSelectedRecord(record);
          //     setOpenDrawer(true);
          // }}
          className="p-0"
        >
          {creationDate}
        </div>
      ),
    },
    {
      title: "Person",
      dataIndex: "person",
      key: "person",
      render: (val: any) => <div className="p-0 text-dark">{val}</div>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (val: any) => <div className="p-0 ">{val}</div>,
    },
    {
      title: "Note",
      dataIndex: "description",
      key: "description",
      render: (val: any) => <div className="p-0 ">{val}</div>,
    },
    {
      title: "Opening Stock",
      dataIndex: "openingStock",
      key: "openingStock",
      width: 120,
      align: "right",

      render: (val: any) => (
        <div className="p-0 text-dark  text-end">{val}</div>
      ),
    },
    {
      title: "Previous Qty",
      dataIndex: "previousQty",
      key: "previousQty",
      width: 120,
      align: "right",

      render: (val: any) => (
        <div className="p-0 text-dark  text-end">{val}</div>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: 70,
      align: "right",

      render: (val: any) => (
        <div className="p-0 text-dark  text-end">{val}</div>
      ),
    },
    {
      title: "Committed",
      dataIndex: "committedStock",
      key: "committedStock",
      align: "right",
      width: 100,
      render: (val: any) => (
        <div className="p-0 text-dark  text-end">{val}</div>
      ),
    },
    {
      title: "Discard",
      dataIndex: "discardStock",
      key: "discardStock",
      align: "right",
      width: 80,
      render: (val: any) => (
        <div className="p-0 text-dark  text-end">{val}</div>
      ),
    },
    {
      title: "InStock",
      dataIndex: "numberOfStock",
      key: "numberOfStock",
      align: "right",
      width: 100,
      render: (val: any) => (
        <div className="p-0 text-dark text-end ">{val}</div>
      ),
    },

    // {
    //   title: "Updated",
    //   dataIndex: "date",
    //   key: "date",
    //   render: (val: any) => <div className="p-0 text-dark  font12">{val}</div>,
    // },
    {
      title: "Action",
      dataIndex: "modifyState",
      key: "modifyState",
      width: 120,
      align: "center",
      render: (val: any, record: any) => (
        <div className="font12 py-1">
          <Button
            disabled={record?.stockAdjID === props?.selectedRecord?.currentCid}
            className="font12"
            size="small"
            type="link"
            onClick={() => {
              setShow("ModifyState");
              setSelectedRecord(record);
            }}
          >
            Modify State
          </Button>
        </div>
      ),
    },
  ];
  const summaryColumns: any = [
    {
      //   title: (
      //     <THeader
      //       title={"ASSIGNEE"}
      //       inputName={"assigneeSearch"}
      //       changeListParams={changeListParams}
      //       setShouldRefresh={setShouldRefresh}
      //       listParams={listParams}
      //       setListParams={setListParams}
      //       ifSelect={false}
      //     />
      //   ),
      title: "Assignee",
      dataIndex: "assigneeSearch",
      key: "assigneeSearch",
      render: (name: any, record: any) => <div className="ps-0">{name}</div>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      // align: "right",
      key: "reason",
      render: (val: any) => <div>{val}</div>,
    },
    {
      title: "Used",
      dataIndex: "usedStock",
      key: "usedStock",
      align: "right",
      width: 100,
      // sorter: true,
      // showSorterTooltip: false,
      render: (val: any) => <div className="ps-0">{val}</div>,
    },

    {
      title: "Discard",
      dataIndex: "discardedStock",
      align: "right",
      width: 100,
      key: "discardedStock",
      // sorter: true,
      // showSorterTooltip: false,
      render: (val: any) => <div className="ps-0">{val}</div>,
    },

    {
      title: "Issue Date",
      dataIndex: "issueDate",
      key: "issueDate",
      align: "center",
      width: 120,
      // sorter: true,
      // showSorterTooltip: false,
      render: (val: any) => <div className="ps-0">{val}</div>,
    },
    {
      title: "Modified Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 120,
      // sorter: true,
      // showSorterTooltip: false,
      render: (val: any) => <div className="ps-0">{val}</div>,
    },
    {
      title: "Modified By",
      dataIndex: "auth",
      key: "auth",
      width: 200,
      // sorter: true,
      // showSorterTooltip: false,
      render: (val: any) => <div className="ps-0">{val}</div>,
    },
  ];

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const handleExpand = (expanded: any, record: any) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  const items: TabsProps["items"] = [
    {
      key: "Summary",
      label: `Stock Summary of ${props?.selectedRecord?.productName}`,
      children: (
        <>
          <Table
            rowClassName={rowClassName}
            loading={tableLoading}
            className="InvTable"
            columns={columns}
            dataSource={data}
            onChange={onChangeTable}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              total: totalRecords,
              pageSizeOptions: pageSizeOption,
              position: ["bottomRight"],
            }}
            scroll={{ y: `calc(100vh - 300px)` }}
            expandable={{
              onExpand: (expanded, record) => {
                setSpinLoading(true);
                if (expanded) {
                  getCommittedSummaryList(
                    record?.stockAdjID,
                    record?.productID,
                    props?.category
                  );
                  // setSelectedRowKeys(record?.key);

                  //   ProductService.getVariants(record?.id, category).then(
                  //     (res: any) => {
                  //       setVariantList(res);
                  //       res?.map((item: any) => {
                  //         if (item?.name) isName = true;
                  //         if (item?.serialNo) isSno = true;
                  //         if (
                  //           item?.expiryDate &&
                  //           item?.expiryDate !== "0001-01-01T00:00:00Z"
                  //         )
                  //           isExp = true;
                  //       });

                  //       setSpinLoading(false);
                  //     }
                  //   );
                } else {
                  setSummaryData([]);
                  setSpinLoading(false);
                }
                handleExpand(expanded, record);
              },

              expandedRowKeys,
              expandedRowRender: (
                record: any,
                index: number,
                indent: number,
                expanded: boolean
              ) => {
                return (
                  <>
                    <Skeleton
                      key={record?.id}
                      active={true}
                      round={true}
                      loading={spinLoading}
                    >
                      <div style={{ padding: "20px 40px 0px 40px" }}>
                        {summaryData?.length > 0 ? (
                          <Table
                            caption=""
                            size="middle"
                            columns={summaryColumns}
                            dataSource={summaryData}
                          />
                        ) : (
                          <Empty description={<span>No Summary</span>} />
                        )}
                      </div>
                    </Skeleton>
                  </>
                );
              },
            }}
          />
        </>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);
    ProductService.getSummaryList(
      props?.selectedRecord?.id,
      listParams.assignee,
      listParams.sortDir,
      listParams.sortCol,
      listParams.fromDate,
      listParams.toDate,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res?.items?.length > 0) {
        console.log("res ->",res);
        
        setData(
          res?.items?.map((itm: any, idx: any) => ({
            stockAdjID: itm?.id,
            key: idx,
            creationDate: dayjs(itm?.createdBy?.date).format("DD/MM/YYYY"),
            productID: itm?.productId,
            productName: props?.selectedRecord?.productName,
            person: itm?.createdBy?.userName,
            date: dayjs(itm?.updatedBy?.date).format("DD/MM/YYYY"),
            qty: itm?.qty,
            committedStock: itm?.committedStock,
            discardStock: itm?.discardStock,
            previousQty: itm?.previousQty,
            openingStock: itm?.openingStock,
            numberOfStock: itm?.numberOfStock,
            description: itm?.description ? itm?.description : "-",
            reason: itm?.reason ? itm?.reason : "-",
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      } else {
        setLoading(false);
      }
    });
  }, [shouldRefresh]);
  const getCommittedSummaryList = (
    stockAdjID: string,
    productID: string,
    category: Category
  ) => {
    ProductStateService.getList(category, productID, stockAdjID).then(
      (res: any) => {
        if (res?.items?.length > 0) {
          setSummaryData(
            res?.items?.map((itm: any, idx: any) => ({
              ...itm,
            issueDate: dayjs(itm?.issueDate).format("DD/MM/YYYY"),
              key: idx,
              assigneeSearch: itm?.assignee?.name,
              givenStock: itm?.givenStock,
              usedStock: itm?.usedStock,
              returnedStock: itm?.returnedStock,
              discardedStock: itm?.discardedStock,
              reason: itm?.reason ? itm?.reason : "-",
              date: dayjs(itm?.createdBy?.date).format("DD/MM/YYYY"),
              auth: itm?.createdBy?.userName,
            }))
          );
          setSpinLoading(false);
        } else {
          setSpinLoading(false);
          setSummaryData([]);
        }
      }
    );
  };
  return (
    <>
      <Drawer
        className="InvAddDrawer"
        open={true}
        width="100%"
        extra={<CloseOutlined onClick={() => props.onDismiss(false)} />}
        style={{ height: `calc(100vh - 99px)`, top: 0 }}
        getContainer={false}
      >
        <Tabs activeKey={"Summary"} items={items} className="InvTab" />
      </Drawer>

      {show === "ModifyState" && (
        <ModifyState
          mode={"SUMMARY"}
          setShow={setShow}
          selectedRecord={selectedRecord}
          category={props?.category}
          onDismiss={(rec: boolean) => {
            setSelectedRowKeys("");
            setShow("");
            if (rec) setShouldRefresh((x) => !x);
          }}
        />
      )}
    </>
  );
};

export default ViewModifySummary;
