import Axios from "axios";
import { ApiUtility, IApiResponse } from "../Utilities/ApiUtility.axios";
import { Utils } from "../Utilities/Utils";
import { InMemoryJWT, LocalStorageKeyToken } from "../Utilities/InMemoryJWT";
import { message } from "antd";
import { loginUserSessionKey, secureSessionStorage } from "../CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";

export interface IUserLoginResponse {
  name?: string;
  token: string;
  tokenExpiry: number;
}

export const AuthService = {
  login: async (
    username: string,
    password: string
  ): Promise<IUserLoginResponse | null> => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      "/API/Auth/Login",
      { username, password } as any,
      ApiUtility._axiosOptions({ withCredentials: true })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);
        // secureLocalStorage.setItem("ao-user", result);
        secureSessionStorage.setItem(loginUserSessionKey, result)
        Utils.setSession("ao-user", result);
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);
    }
    return null;
  },
  changePassword: async (id: any, value: any) => {
    return await Axios.post(
      `/API/Auth/ChangePassword/${id}`,
      { ...value },
      ApiUtility._axiosOptions({ withCredentials: true })
    );
  },

  postUser: async (user: any) => {
    return await Axios.post(
      `API/Auth/SaveUser`,
      { ...user },
      ApiUtility._axiosOptions({ withCredentials: true })
    );
  },
  getUsersList: async (
    userNameSearch: string,
    roleSearch: string,
    mailSearch: string,
    phoneNumberSearch: string,
    status: any,
    start: number,
    length: number
  ) => {
    return await ApiUtility.get(`API/Auth/User`, {
      userNameSearch,
      roleSearch,
      mailSearch,
      phoneNumberSearch,
      status,
      start,
      length,
    });
  },
  loginAs: async (pid: string, id: string) => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      "/auth/" + pid + "/loginas/" + id,
      {} as any,
      ApiUtility._axiosOptions({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);

        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  forgotpassword: async (email: string) => {
    const response = await Axios.post<IApiResponse>(
      "/auth/forgotpassword",
      { email },
      ApiUtility._axiosOptions()
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  recoverpassword: async (
    email: string,
    password: string,
    confirmpassword: string,
    code: string
  ) => {
    const response = await Axios.post<IApiResponse>(
      "/auth/resetpassword",
      { email, password, confirmpassword, code },
      ApiUtility._axiosOptions()
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  logout: async () => {
    // remove user from local storage to log user out
    InMemoryJWT.ereaseToken(true);
    return Promise.resolve();
  },

  getAuthToken: (): string | null =>
    InMemoryJWT.getToken() || Utils.getLocal(LocalStorageKeyToken),

  isAuthenticated: (): boolean | false => AuthService.getAuthToken() !== null,

  userRole() {
    if (AuthService.getAuthToken() != null) {
      let user = Utils.getSession("ao-user");
      return user.role;
    }
  },
};

export const Roles = {
  Admin: "ADMIN",
  Manager: "MANAGER",
  Employee: "EMPLOYEE",
};
