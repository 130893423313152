export const ApplicationRouteLink = {
  InventoryMainDashboard: "",

  Vendor: "Vendor",

  Cleaning: "Cleaning",

  Stationery: "Stationery",

  GardenOrPlants: "GardenOrPlants",

  EmergencyMedicalKit: "EmergencyMedicalKit",

  PaidSoftware: "PaidSoftware",

  DigitalProduct: "DigitalProducts",

  OfficeEquipments: "OfficeEquipments",

  Construction: {
    Purchases: "Purchases",
    Category: "Category",
    SubCategory: "ConstructionSubCategory",
    Measurement: "Measurement",
    Gst: "GST",
  },
  Attendance: {
    UserCategory: "UserCategory",
    UserAttendance: "UserAttendance",
  },
  CabService: {
    VehicleMaster: "VehicleMaster",
    Driver: "Driver",
    Passenger: "Passenger",
    Shift: "Shift",
    Round: "Round",
    PassengerType: "PassengerType",
    LogStatus: "LogStatus",
    TripSchedule: "TripSchedule",
    TripLogs: "TripLogs",
  },
  MastersLinks: {
    MastersUnit: "Unit",
    MasterProductGroup: "ProductGroup",
    MastersOption: "Option",
    MastersVariant: "Variant",
    MastersSubCategory: "SubCategory",
    MastersSeating: "Seating",
    MastersDesignation: "Designation",
    MastersDepartment: "Department",
    MastersAssignee: "Assignee",
    // MastersMaterials: "Materials",
    // MastersConstructionItem: "Constructions Items",
  },

  Services: "Services",

  ReportsLinks: {
    Report: "Reports",
    DigitalProductReport: "Reports/DigitalProduct",
    CommittedStockReport: "Reports/CommittedStock",
  },

  MainDashboardLinks: {
    CleaningDashboard: "Dashboard/Cleaning",

    StationeryDashboard: "Dashboard/Stationery",

    GardenOrPlantsDashboard: "Dashboard/GardenOrPlants",

    EmergencyMedicalKitDashboard: "Dashboard/EmergencyMedicalKit",

    PaidSoftwareDashboard: "Dashboard/PaidSoftware",

    DigitalProductDashboard: "Dashboard/DigitalProduct",

    OfficeEquipmentsDashboard: "Dashboard/OfficeEquipments",
  },
  Users: {
    UserList: "Users",
    UserProfile: "UserProfile",
  },

  Setting: "Setting",

  UserAccess: "Setting/UserAccess",
};
