import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Col, Input, Row, Select, Spin, Tag } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import UserDetails from "./UserDetails";
import { AuthService } from "../../../Services/AuthService";
import "../../Common/Common.css";
import AddUser from "./AddUser";
import { pageSizeOption } from "../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;

export const enum UserStatus {
  Active,
  Inactive,
  Deleted,
}
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {/* <div>{title}</div> */}
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("status", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            allowClear
            onClear={() => {
              changeListParams("status");
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            style={{ width: "100%" }}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            // defaultValue={{ value: "0", label: "Active" }}
            options={[
              { value: "-1", label: "All" },
              { value: "0", label: "Active" },
              { value: "1", label: "Inactive" },
            ]}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={(val) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const UserList = (props: any) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [userId, setUserId] = useState("");
  const [data, setData] = useState<any>([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [show, setShow] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [listParams, setListParams] = useState({
    userNameSearch: "",
    roleSearch: "",
    mailSearch: "",
    phoneNumberSearch: "",
    status: UserStatus.Active,
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Name"}
          inputName={"userNameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <div
          // type="link"
          onClick={() => {
            setShow("UserDetails");
            setUserId(record.id);
            setOpenDrawer(true);
          }}
          className="ps-2 font12"
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {name?.first}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Role"}
          inputName={"roleSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "role",
      key: "role",
      render: (role, record) => (
        <span className="ps-2 text-dark font12 ">{role}</span>
      ),
    },
    // {
    //   title: <span className="font13">Card No.</span>,
    //   dataIndex: "cardNo",
    //   render: (role, record) => (
    //     <span className="ps-2 text-dark font12 ">{role}</span>
    //   ),
    // },
    // {
    //   title: <span className="font13">Card Id</span>,

    //   dataIndex: "userId",
    //   render: (role, record) => (
    //     <span className="ps-2 text-dark font12 ">{role}</span>
    //   ),
    // },
    // {
    //   title: <span className="font13">Biometric No.</span>,

    //   dataIndex: "biometricCode",
    //   render: (role, record) => (
    //     <span className="ps-2 text-dark font12 ">{role}</span>
    //   ),
    // },
    {
      title: (
        <THeader
          title={"Mail Address"}
          inputName={"mailSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "email",
      key: "email",
      render: (email, record) => (
        <span className="text-dark font12 ">{email}</span>
      ),
    },
    {
      title: (
        <THeader
          title={"Category"}
          inputName={"categorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "userCategory",
      key: "userCategory",
      render: (userCategory, record) => (
        <span className="ps-2 text-dark font13 ">{userCategory?.name}</span>
      ),
    },
    {
      title: (
        <THeader
          title={"Phone Number"}
          inputName={"phoneNumberSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "phoneNumber",
      width: 180,
      key: "phoneNumber",
      render: (val) => (
        <div className="font13 text-end"> {val === "" ? "-" : val}</div>
      ),
    },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"status"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      dataIndex: "status",
      key: "status",
      render: (status, record) =>
        status === 0 ? (
          <Badge status="success" text="Active" className="ps-2" />
        ) : (
          <Badge status="error" text="Inactive" className="ps-2" />
        ),
    },
  ];

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  useEffect(() => {
    setLoading(true);
    AuthService.getUsersList(
      listParams.userNameSearch,
      listParams.roleSearch,
      listParams.mailSearch,
      listParams.phoneNumberSearch,
      listParams.status,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res?.result !== null || res?.result?.totalRecords !== 0) {
        setData(
          res?.result?.items?.map((data: any, i: number) => ({
            ...data,
            key: data?.id,
            id: data?.id,
            name: data?.name,
            role: data?.role,
            email: data?.email ?? "-",
            phoneNumber: data?.phoneNumber ?? "-",
            address: data?.address ?? "-",
            status: data?.status,
            userCategory: data?.userCategory,
            biometricCode: data?.biometricCode,
            cardNo: data?.cardNo,
            userId: data?.userId,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.result?.totalRecords);
      } else {
        setLoading(false);
      }
    });
  }, [listParams, shouldRefresh]);

  return (
    <>
      <Card
        title={"User List"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow("AddUser")}
                >
                  User
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          // className="InvTable"
          // rowSelection={{
          //   type: "checkbox",
          //   preserveSelectedRowKeys: true,
          //   onChange(selectedRowKeys, selectedRows, info) {
          //     console.log("selectedRows,", selectedRows,);

          //   },

          // }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            onChange: (page, pageSize) => {
              changeListParams("start", (page - 1) * pageSize);
              changeListParams("length", pageSize);
              setShouldRefresh((x) => !x);
            },
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 279px)` }}
        />

        {show === "UserDetails" && (
          <UserDetails
            userId={userId}
            openDrawer={openDrawer}
            setShow={setShow}
            onDismiss={(rec: boolean) => {
              setShow("");
              setOpenDrawer(false);
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "AddUser" && (
          <AddUser
            onDismiss={(rec: boolean) => {
              setShow("");
              setOpenDrawer(false);
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}
      </Card>
    </>
  );
};

export default UserList;
