import React from 'react'

type Props = {}

const GardenOrPlantsDashboard = (props: Props) => {
  return (
    <div>GardenOrPlantsDashboard</div>
  )
}

export default GardenOrPlantsDashboard