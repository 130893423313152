/* eslint-disable */
import { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import { EntitiesEnum } from "../../../Services/LogService";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterHistory from "../MasterHistory";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const Seating = (props: any) => {
  const { getSeatingOptions } = useContext(ApplicationContext);
  const [messageApi, contextHolder] = message.useMessage();
  const [SeatingForm] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [show, setShow] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const inputRef = useRef<any>(null);
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    seatingSearch: "",
    start: 0,
    length: 15,
    setLength: true,
  });
  const [editData, setEditData] = useState<any>({});
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getSeatings = (listParams: any) => {
    setLoading(true);
    MasterService.getSeating(
      listParams.seatingSearch,
      listParams.company,
      listParams.start,
      listParams.length,
      listParams.setLength
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            key: r.id,
            id: r.id,
            name: r.name,
            value: r.id,
            recordStatus: r.recordStatus,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    setFieldChange(false);
    // setLoading(false);
    SeatingForm.resetFields();
  };
  // Message on Submit

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Seating Is Assigned  Can't be deleted",
    });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [exist, shouldRefresh]);

  const subSeating = (saveTog: boolean) => {
    SeatingForm.validateFields().then((values) => {
      let val = { name: values.name.trim() };
      MasterService.postSeating(val).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            SeatingForm.resetFields();
            setShouldRefresh(!shouldRefresh);
          }
        } else {
          errorMsg(res?.message);
        }
        // if (res?.status) {
        //   info("Added Successfully");
        //   onDismiss();
        //   SeatingForm.resetFields();
        // } else {
        //   errorMsg(res?.message);
        // }
      });
    });
  };

  const searchSeating = (e: any) => {
    setListParams({
      seatingSearch: e.trim(),
      start: 0,
      length: 15,
      setLength: true,
    });
  };

  const editRowData = async (item: any) => {
    // console.log(item?.id);
    MasterService.getSeatingById(item?.id).then(async (res: any) => {
      setShow(true);
      setUpdateToggle("show");
      SeatingForm.setFieldsValue({ ...item });
      await MasterService.getExistSeating(Masters.Seating, item.id).then(
        (res: any) => {
          if (res === true) {
            setExist(true);
          } else {
            setExist(false);
          }
        }
      );
      setEditData(res);
      SeatingForm.setFieldsValue(res);
    });
    // MasterService.isExist(Masters.Seating, item.id).then((res) => {
    //     if (res === true) {
    //         setExist(true)
    //     } else {
    //         setExist(false)
    //     }
    // })
  };

  const updateData = () => {
    let values = SeatingForm.getFieldsValue(true);
    if (fieldChange) {
      SeatingForm.validateFields().then(async () => {
        let val = {
          id: values?.id,
          name: values?.name.trim(),
        };
        await MasterService.postSeating(val).then((res: any) => {
          if (res?.message) {
            messageApi.error(res?.message);
          } else {
            setSelectedRowKeys("");
            setUpdateToggle("close");
            onDismiss();
            info("Updated Successfully");
            getSeatingOptions();
          }
        });
      });
    } else {
      setShow(false);
    }

    // MasterService.postSeating(val).then(() => {
    //   setUpdateToggle("close");
    //   onDismiss();
    //   info("Updated Successfully");
    // });
  };

  const deleteList = (listId: any) => {
    // MasterService.getExistSeating(listId).then((res) => {
    //     if (res === true) {
    //         warning();
    //     }
    //     else {
    //         MasterService.deleteSeating(listId).then((res) => {
    //             errorMsg("Seating Deleted")
    //             onDismiss()
    //         });
    //     }
    // })
    // MasterService.getExistSeating(listId).then((res) => {
    //     if (res === true) {
    //         warning();
    //     }
    MasterService.getExistSeating(Masters.Seating, listId).then((res: any) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getSeatingById(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteSeating(listId).then((res) => {
              // errorMsg("Seat is Deleted Successfully");
              messageApi.success("Seat is deleted successfully.");
              onDismiss();
            });
          } else {
            messageApi.warning("Seat is added by admin can'nt be deleted");
          }
        });
      }
    });
  };

  useEffect(() => {
    getSeatings(listParams);
  }, [shouldRefresh, listParams]);

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === SeatingForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">SEATING </span> */}

          <Search
            placeholder="Seating"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchSeating(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      width: 240,

      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      align: "right",
      key: "operation",
      render: (_, record) => (
        <>
          <Button
            size="small"
            type="text"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            Edit
          </Button>{" "}
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record.id)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} type="text" danger size="small">
                Delete
              </Button>
            </Popconfirm>
          }
          {
            <Button
              type="text"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              History
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Seating"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          destroyOnClose={true}
          closable
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            SeatingForm.resetFields();
            setUpdateToggle("close");
            setSelectedRowKeys("");
          }}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          zIndex={1005}
          // title={updateToggle === "close" ? "Add Seating" : "Update Seating"}

          title={
            updateToggle === "close" ? (
              "Add Seating"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Seating
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Seat is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Seating"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>{" "} */}
              </>
            )
          }
          width={750}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subSeating();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      SeatingForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subSeating(true);
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subSeating(false);
                    }}
                  >
                    Save and New
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      SeatingForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {exist === true ? (
                  <Popconfirm
                    title={
                      exist === true
                        ? "Do you wan't to update this assigned Seating"
                        : "Update Seating"
                    }
                    // onConfirm={() =>  }
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    onConfirm={() => updateData()}
                    onCancel={() => {
                      setShow(false);
                      SeatingForm.resetFields();
                      setUpdateToggle("close");
                    }}
                  >
                    <Button type="primary" ghost>
                      Update
                    </Button>
                  </Popconfirm>
                ) : ( */}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed}
                  >
                    Update
                  </Button>
                  {/* )} */}
                </div>
              )}
            </>
          }
        >
          {" "}
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="SeatingBasic"
            form={SeatingForm}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Enter Seating"
              name="name"
              rules={[
                //   {
                //     pattern: /^\S[\S\s]*$/,
                //     message:
                //       "Spaces,  are not allowed at the beginning or end of the value.",
                //   },
                //   {
                //     required: true,
                //     message: "Fill the field",
                //   },
                // {
                //   pattern: pattern,
                //   message:
                //     "Spaces,  are not allowed at the beginning or end of the value.",
                // },

                {
                  required: true,
                  message: "Please enter seating",
                },
                { whitespace: true },
              ]}
            >
              <Input
                ref={inputRef}
                onBlur={() => {
                  CheckChanges("name");
                }}
              />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Seating}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Seating;
