import React from 'react'

type Props = {}

const DigitalProductsDashboard = (props: Props) => {
  return (
    <div>DigitalProductsDashboard</div>
  )
}

export default DigitalProductsDashboard