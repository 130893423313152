import React from "react";
import DigitalProductList from "../../../Components/ParentCategory-B/DigitalProductList";
import { Category } from "../../../Services/ProductService";

type Props = {};

const OfficeEquipments = (props: Props) => {
  return (
    <>
      <DigitalProductList category={Category.OfficeEquipments} />
    </>
  );
};

export default OfficeEquipments;
