import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { Category } from "./ProductService";

class ProductStateService {
  route = `/v1/api/IProductStateManagement`;

  getList = (category: Category, productId: string, stockAdjID?: string) =>
    ApiUtility.getResult(`${this.route}`, {
      category,
      productId,
      stockAdjID
    });
  getGroupedList = (category: Category, productId: string, stockAdjID:string) =>
    ApiUtility.getResult(`${this.route}/groupedList`, {
      category,
      productId,
      stockAdjID
    });
  post = (productID: string, stockAdjID: string, category: Category, params?: any) => ApiUtility.post(`${this.route}?productID=${productID}&stockAdjID=${stockAdjID}&category=${category}`, [...params]);

  postDigitalAssignee = (params?: any) =>
    ApiUtility.post(`${this.route}/digital/assignee`, params);


}

export default new ProductStateService();
