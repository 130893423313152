import React from "react";
import ProductList from "../../../Components/ParentCategory-A/ProductList";
import { Category } from "../../../Services/ProductService";

type Props = {};

const Stationery = (props: Props) => {
  return (
    <>
      <ProductList category={Category.Stationary} />
    </>
  );
};

export default Stationery;
