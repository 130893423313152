import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import "../../Common/Common.css";
import { AuthService } from "../../../Services/AuthService";
import TextArea from "antd/es/input/TextArea";
import MasterService from "../../Services/MasterService";
interface IAddVendorProps {
  userId?: string;
  onDismiss: (rec: boolean) => void;
}

const AddUser = (props: IAddVendorProps) => {
  const { userRoles } = useContext(ApplicationContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form1] = Form.useForm();
  const inputRef = React.useRef<any>(null);
  const [userCategory, setUserCategory] = useState<any>([
    {
      label: "",
      value: "",
    },
  ]);
  const getUserCategory = () => {
    MasterService.getUserCategoryList().then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            value: r?.id,
            label: r?.name,
          });
        });
        setUserCategory([...data]);
        // setLoading("");
        // setTotalRecords(res?.totalRecords);
      }
    });
  };
  useEffect(() => {
    getUserCategory();
  }, []);
  const onSave = () => {
    form1.validateFields().then(async (values) => {
      let user = {
        ...values,
        role: values?.role?.label,
        name: {
          title: "",
          first: values?.name,
          last: "",
          alias: "",
        },
        id: props?.userId,
        company: {
          name: "Capsitech",
          id: "63a959074e041fdc2ae91ce8",
        },
        userCategory: {
          id: values?.userCategory?.value,
          name: values?.userCategory?.label,
        },
      };
      setButtonDisable(true);
      await AuthService.postUser(user).then((res: any) => {
        if (res?.data?.result) {
          messageApi.success("User Added Successfully", 1);
          form1.resetFields();
          setTimeout(() => {
            props.onDismiss(true);
          }, 1000);
        } else {
          messageApi.error(res?.data?.message);
        }
      });
      setButtonDisable(false);
    });
  };

  return (
    <div>
      {contextHolder}
      <Drawer
        // className="InvAddDrawer"
        title="ADD USER"
        open={true}
        closable={false}
        width="100%"
        extra={<CloseOutlined onClick={() => props?.onDismiss(false)} />}
        getContainer={false}
        style={{ height: `calc(100vh - 100px)` }}
        footer={
          <>
            <Button
              htmlType="submit"
              type="primary"
              className="Inv-SaveBtn"
              onClick={() => onSave()}
              disabled={buttonDisable}
            >
              Save
            </Button>
            <Button
              className="Inv-CancelBtn"
              onClick={() => props?.onDismiss(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <>
          {/* <span className="fw600 font16">ADD USER</span> */}
          {/* <Divider style={{ marginBottom: 20 }} /> */}
          <Form
            form={form1}
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSave}
            // style={{ maxWidth: 800 }}
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="large"
            className="InvAddForm"
          >
            <Row gutter={[24, 12]}>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user email",
                    },
                  ]}
                >
                  <Input ref={inputRef} placeholder="Name" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user email",
                    },
                    {
                      type: "email",
                      message: "Invalid user email",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Mobile No."
                  name="phoneNumber"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input phone number!",
                    // },
                    {
                      pattern: new RegExp(/^\d{10}$/),
                      message: "Please input valid number!",
                    },
                  ]}
                >
                  <Input placeholder="Phone no." />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }}>
                <Form.Item
                  label="Roles"
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Please select user role",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select role"
                    labelInValue
                    options={userRoles}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 12]}>
              <Col lg={{ span: 6 }}>
                <Form.Item
                  label="User Category"
                  name="userCategory"
                  rules={[
                    {
                      required: true,
                      message: "Please select user category",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    placeholder="Select user category"
                    labelInValue
                    options={userCategory}
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Card No."
                  name="cardNo"
                  rules={[
                    {
                      required: true,
                      message: "card no. required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Card No."
                    style={{ width: "100%" }}
                  />
                  {/* <label>
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Biometric Code"
                  name="biometricCode"
                  rules={[
                    {
                      required: true,
                      message: "Biometric code required",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Biometric Code"
                    style={{ width: "100%" }}
                  />
                  {/* <label>
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                </Form.Item>
              </Col>
              <Col lg={{ span: 6 }} sm={{ span: 12 }}>
                <Form.Item
                  label="User / Card ID"
                  name="userId"
                  rules={[
                    {
                      required: true,
                      message: "User / Card ID required",
                    },
                  ]}
                >
                  <Input placeholder="User / Card ID" />
                  {/* <label>
                  {credentialDetail?.enrolledDate === "0001-01-01T00:00:00Z" ||
                  editId === ""
                    ? `-`
                    : dayjs(credentialDetail?.enrolledDate).format(
                        "DD MMM, YYYY"
                      )}
                </label> */}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[24, 12]}>
              <Col lg={{ span: 12 }} md={{ span: 16 }}>
                <Form.Item label="Address" name="address">
                  <TextArea
                    autoSize={{ minRows: 4, maxRows: 6 }}
                    placeholder="Address"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </div>
  );
};

export default AddUser;
