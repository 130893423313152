import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum CompanyType {
  Supplier,
  ServiceProvider,
  Both,
}
export enum VendorType {
  Both = -1,
  Product,
  Construction,
}
export interface IApiDataProps {
  id: string;
  name: string;
  contactPerson: string;
  description: string;
  companyType: CompanyType;
  email: string;
  workPhone: string;
  mobileNo: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  isEditAllowed: boolean;
  issueDate: any;
}
class VendorService {
  // route = `${process.env.REACT_APP_API_BASE_URL}/v1/api/IVendor`
  route = `/v1/api/IVendors`;

  get = (id: string) => ApiUtility.getResult(`${this.route}/${id}`);

  getList = (
    company: string,
    vendorSearch: string,
    contactSearch: string,
    type: string,
    vendorType: number,
    start: number,
    length: number,
    sortCol: string,
    sortDir: string,
    fromDate: string,
    toDate: string,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      company,
      vendorSearch,
      contactSearch,
      type,
      vendorType,
      start,
      length,
      sortCol,
      sortDir,
      fromDate,
      toDate,
      setLength,
    });

  post = (params?: any) => ApiUtility.post(`${this.route}`, params);

  getVendorOptions = (
    vendorOptSearch: string,
    type?: VendorType,
    setLength?: boolean,
    start?: string,
    length?: string
  ) =>
    ApiUtility.getResult(`${this.route}/vendorOptions`, {
      vendorOptSearch,
      type,
      setLength,
      start,
      length,
    });
}

export default new VendorService();
