import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Select,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  InputRef,
  Space,
  Card,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useState, useEffect, useRef } from "react";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import MasterHistory from "../MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
const { Search } = Input;
const AddInOption = ({
  funcType,
  department,
  designation,
  setShouldRefresh,
}: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [status, setStatus] = useState<any>("success");
  const [messageApi, contextHolder] = message.useMessage();

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "designation")
        MasterService.postDesignation({ name: name }).then((r: any) => {
          if (r.status === true) {
            setName("");
            setShouldRefresh((x: any) => !x);
            inputRef.current?.focus();
          } else {
            messageApi.error(r?.message);
          }
        });
      else if (funcType === "department")
        MasterService.postDepartment({ name: name }).then((r: any) => {
          if (r.status === true) {
            setName("");
            setShouldRefresh((x: any) => !x);
            inputRef.current?.focus();
          } else {
            messageApi.error(r?.message);
          }
        });
    }
  };
  return (
    <>
      {contextHolder}
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }}>
        <Input
          placeholder="Enter Option"
          ref={inputRef}
          value={name}
          status={status}
          onChange={onNameChange}
          onPressEnter={addOption}
        />
        <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
      </Space>
    </>
  );
};

// *****
export const Assignee = () => {
  const inputRef = useRef<any>(null);
  const { getAssigneeOptions } = useContext(ApplicationContext);
  const [assigneeForm] = Form.useForm();
  const [assigneeList, setAssigneeList] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [messageApi, contextHolder] = message.useMessage();
  const [editRecord, setEditRecord] = useState<any>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [show, setShow] = useState<boolean>(false);
  const [keyVal, setKeyVal] = useState<any>();
  const [selectDisable, setButtonDisable] = useState(false);
  const [exist, setExist] = useState<any>(false);
  const [departmentOptions, setDepartmentOptions] = useState<any>([]);
  const [designationOptions, setDesignationOptions] = useState<any>([]);
  const [listParams, setListParams] = useState<any>({
    assigneeSearch: "",
    designationSearch: "",
    departmentSearch: "",
    start: 0,
    length: 15,
    setLength: true,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  // get Department select options
  const getDepartment = () =>
    MasterService.getDepartmentOptions("", "", 0, 30, false).then((res) => {
      if (res) {
        setDepartmentOptions(
          res?.items?.map((r: any) => ({
            label: r?.name,
            value: r?.id,
          }))
        );
      }
    });
  // get Designation select options
  const getDesignation = () =>
    MasterService.getDesignationOptions("", "", 0, 30, false).then((res) => {
      if (res) {
        setDesignationOptions(
          res?.items?.map((r: any) => ({
            label: r?.name,
            value: r?.id,
          }))
        );
      }
    });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  // Message on Submit
  const info = (msg: any) => {
    messageApi.success(msg);
  };
  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "assignee Is Assigned  Can't be deleted",
    });
  };

  // POST For assignee
  const subAssignee = (saveTog: boolean) => {
    assigneeForm.validateFields().then((values) => {
      let val = {
        name: values?.name,
        designation: {
          id: values?.designation?.value,
          name: values?.designation?.label,
        },
        department: {
          id: values?.department?.value,
          name: values?.department?.label,
        },
      };

      MasterService.postAssignee(val).then((res) => {
        if (res?.status) {
          info("submitted");
          if (saveTog === true) {
            getData(listParams);
            onDismiss();
            getAssigneeOptions();
          } else {
            setShouldRefresh((x: any) => !x);
            assigneeForm.resetFields();
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  // GET For assignee
  const getData = (listParams: any) => {
    setLoading(true);
    MasterService.getAssigneeList(
      listParams.assigneeSearch,
      listParams.designationSearch,
      listParams.departmentSearch,
      listParams.start,
      listParams.length,
      listParams.setLength
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r?.id,
            name: r?.name,
            designation: r?.designation,
            department: r?.department,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
            isEditAllowed: r?.isEditAllowed,
          });
        });
        setAssigneeList([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };
  // Edit
  const editRowData = (editList: any) => {
    setUpdateToggle("show");
    setKeyVal(editList.key);
    setShow(true);
    MasterService.getAssigneeById(editList?.id).then((res) => {
      if (res) {
        let values = {
          ...res,
          designation: {
            label: res?.designation?.name,
            value: res?.designation?.id,
          },
          department: {
            label: res?.department?.name,
            value: res?.department?.id,
          },
        };
        setEditRecord(values);
        assigneeForm.setFieldsValue(values);
      }
    });
    MasterService.isExist(Masters.Assignee, editList.key).then((res) => {
      if (res === true) setExist(true);
      else setExist(false);
    });
  };

  const updateData = () => {
    let values = assigneeForm.getFieldsValue(true);
    assigneeForm.validateFields().then(() => {
      let val = {
        ...values,
        id: keyVal,
        designation: {
          id: values?.designation?.value,
          name: values?.designation?.label,
        },
        department: {
          id: values?.department?.value,
          name: values?.department?.label,
        },
      };
      MasterService.postAssignee(val).then((res: any) => {
        if (res?.result === null) {
          errorMsg(res?.message);
        } else {
          setUpdateToggle("close");
          onDismiss();
          info("Updated Successfully");
          getData(listParams);
          getAssigneeOptions();
          setSelectedRowKeys("");
        }
      });
    });
  };

  const deleteList = (listId: any) => {
    MasterService.isExist(Masters.Assignee, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getAssigneeById(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteAssignee(listId).then((res: any) => {
              if (res?.status) {
                // errorMsg("assignee is Deleted Successfully");
                messageApi.success("Assignee is deleted successfully.");
                getData(listParams);
                getAssigneeOptions();
                onDismiss();
              } else errorMsg(res.errors);
            });
          } else {
            messageApi.warning("Assignee is added by admin can't be deleted");
          }
        });
      }
    });
  };
  const searchAssignee = (e: any) => {
    setListParams({
      assigneeSearch: e.trim(),
      designationSearch: "",
      departmentSearch: "",
      start: 0,
      length: 15,
      setLength: true,
    });
  };
  const searchDesignation = (e: any) => {
    setListParams({
      assigneeSearch: "",
      designationSearch: e.trim(),
      departmentSearch: "",
      start: 0,
      length: 15,
      setLength: true,
    });
  };
  const searchDepartment = (e: any) => {
    setListParams({
      assigneeSearch: "",
      designationSearch: "",
      departmentSearch: e.trim(),
      start: 0,
      length: 15,
      setLength: true,
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    assigneeForm.resetFields();
  };
  useEffect(() => {
    // inputRef.current?.focus();
  }, [exist]);

  useEffect(() => {
    getData(listParams);
    inputRef.current?.focus();

    // getVariants();
    // eslint-Disable-next-line react-hooks/exhaustive-deps
  }, [listParams, shouldRefresh]);

  useEffect(() => {
    getDesignation();
    getDepartment();
  }, [shouldRefresh]);
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">ASSIGNEE</span> */}
          <Search
            placeholder="Assignee"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchAssignee(e)}
            size="small"
            bordered={false}
          />
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 240,
      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          // style={{ cursor: "pointer" }}
          className="ps-1  font13   cursorPointer"
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">DESIGNATION</span> */}
          <Search
            placeholder="Designation"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchDesignation(e)}
            size="small"
            bordered={false}
          />
        </div>
      ),
      dataIndex: "designation",
      width: 240,
      render: (itm: any, record: any) => (
        <span
          className="ps-2   font13 cursorPointer"
          // style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm?.name}
        </span>
      ),
    },
    {
      title: (
        <div>
          <Search
            placeholder="Department"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchDepartment(e)}
            size="small"
            bordered={false}
          />
        </div>
      ),
      dataIndex: "department",
      width: 240,
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13 cursorPointer"
          // style={{ cursor: "pointer" }}
          onClick={() => editRowData(record)}
        >
          {itm?.name}
        </span>
      ),
    },
    {
      title: (
        <>
          <div>Date</div>
        </>
      ),
      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: " ",
      key: "operation",
      align: "right",

      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.key)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button size="small" icon={<DeleteOutlined />} type="text" danger>
              Delete
            </Button>
          </Popconfirm>{" "}
          {
            <Button
              type="text"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              History
            </Button>
          }
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  return (
    <>
      <Card
        title={"Assignee"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setUpdateToggle("close");
                    setShow(true);
                    setButtonDisable(false);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {contextHolder}

        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={assigneeList}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          // title={updateToggle === "close" ? "Add assignee" : "Update assignee"}
          title={
            updateToggle === "close" ? (
              "Add Assignee"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Assignee
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Assignee"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>{" "} */}
              </>
            )
          }
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setSelectedRowKeys("");
            setShow(false);
            assigneeForm.resetFields();
            setUpdateToggle("close");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subassignee();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      assigneeForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subAssignee(true);
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subAssignee(false);
                    }}
                  >
                    Save and New
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      assigneeForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editRecord?.isEditAllowed}
                  >
                    Update
                  </Button>
                  {/* </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="assigneeBasic"
            className="InvAddForm"
            layout="vertical"
            size="large"
            form={assigneeForm}
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Form.Item
              label="Enter Assignee Name"
              name="name"
              required
              rules={[
                // {
                //   pattern: /^\b[^-\s\d\W][^\s\d\W]*$/,
                //   message:
                //     "Spaces,  are not allowed at the beginning or end of the value.",
                // },
                // {
                //   required: true,
                //   message: "Fill the field",
                // },
                {
                  pattern: pattern,
                  message:
                    "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                },
                {
                  required: true,
                  message: "Please enter assignee name",
                },
              ]}
            >
              <Input placeholder="Enter Assignee Name" ref={inputRef} />
            </Form.Item>
            <Form.Item
              label="Enter Designation"
              name="designation"
              // required
              rules={[{ required: true, message: "Please select designation" }]}
            >
              {/* <Select
              // assignees={variantTypes}
              options={designationOptions}
              labelInValue
              // disabled={selectDisable}
            /> */}
              <Select
                //
                labelInValue
                options={designationOptions}
                allowClear
                listHeight={120}
                style={{ width: "100%" }}
                showSearch
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AddInOption
                      funcType={"designation"}
                      setShouldRefresh={setShouldRefresh}
                    />
                  </>
                )}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item
              label="Enter Department"
              name="department"
              rules={[{ required: true, message: "Please select department" }]}
            >
              <Select
                // assignees={variantTypes}
                options={departmentOptions}
                labelInValue
                // disabled={selectDisable}
                allowClear
                listHeight={120}
                style={{ width: "100%" }}
                showSearch
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AddInOption
                      funcType={"department"}
                      setShouldRefresh={setShouldRefresh}
                    />
                  </>
                )}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Assignee}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Assignee;
