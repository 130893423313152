import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class AttendanceService {
  route = `API/Attendance`;

  //get batch id name as option for select box
  get_batch_options = () =>
    ApiUtility.getResult(`${this.route}/${"GetAllBatchOptions"}`);

  //Get all attendance by batch and date
  get_all_att_by_batch_and_date = (params: {
    date: any;
    uId: string;
    start: number;
    length: number;
  }) =>
    ApiUtility.getResult(`${this.route}/${"GetAllAttByBatchAndDate"}`, params);

  //Save Bulk Attendance
  sav_bulk_attendance = (params: any) =>
    ApiUtility.post(`${this.route}${"/getOptionsList"}`, params);

  //Get Attendance in sheet
  get_att_in_sheet = (params: {
    month: number;
    year: number;
    uId: string;
    start: number;
    length: number;
  }) => ApiUtility.getResult(`${this.route}${"/AttendanceSheet/"}`, params);

  // employee option list in attendance section
  get_all_student_list = (year: string, month: string, batch: string) =>
    ApiUtility.getResult(
      `${this.route}${"/GetAllStudentList/" + year + "/" + month}`,
      { batch }
    );

  get_monthly_attendance = (stuId: string, year: string, month: string) =>
    ApiUtility.getResult(
      `${this.route}${"/GetMonthlyAtt/" + stuId + "/" + year + "/" + month}`
    );

  get_Att_By_Id = (stuId: string | undefined, date: any) =>
    ApiUtility.getResult(`${this.route}${"/GetAttById/" + stuId}`, { date });

  // save punch time
  save_Punch_Time = (stuId: string | undefined, params: any) =>
    ApiUtility.post(`${this.route}${"/SavePunchTime/" + stuId}`, params);

  // save attendance
  save_attendance = (params: any) =>
    ApiUtility.post(`${this.route}${"/SaveAttendance"}`, params);

  // delete punch time
  delete_Punch_Time = (stuId: string | undefined, params: any) =>
    ApiUtility.post(`${this.route}${"/DeletePunchTime/" + stuId}`, params);

  // for filter options
  getOptionsList = () =>
    ApiUtility.getResult(`${this.route}/${"getOptionsList"}`);

  // employee option list in attendance section
  getAllEmpList = (
    year: string,
    month: string,
    deptIds: any,
    projectIds: any,
    desigIds: any,
    shiftIds: any
  ) =>
    ApiUtility.getResult(
      `${this.route}${"/GetAllEmpList/" + year + "/" + month}`,
      { deptIds, projectIds, desigIds, shiftIds }
    );

  // get att by id

  // get time schedule
  getTimeSchedule = (biometricCode: string | undefined) =>
    ApiUtility.get(`${this.route}${"/GetTimeSchedule/" + biometricCode}`);

  SaveLeave = (params: any) =>
    ApiUtility.post(`${this.route}${"/SaveLeave"}`, params);

  // save halfDay punch time
  saveHalfDayPunchTime = (
    biometricCode: string | undefined,
    type: string,
    params: any
  ) =>
    ApiUtility.post(
      `${this.route}${"/SaveHalfLeavePunchTime/" + biometricCode + "/" + type}`,
      params
    );

  // delete punch time
  deletePunchTime = (biometricCode: string | undefined, params: any) =>
    ApiUtility.post(
      `${this.route}${"/DeletePunchTime/" + biometricCode}`,
      params
    );

  // delete halfDay punch time
  deleteHalfDayPunchTime = (biometricCode: string | undefined, params: any) =>
    ApiUtility.post(
      `${this.route}${"/DeleteHalfLeavePunchTime/" + biometricCode}`,
      params
    );

  // save bulk att for particular employee
  postBulkAtt = (params: any) =>
    ApiUtility.post(`${this.route}${"/SaveBulkAttendance"}`, params);

  // for employee leaves type
  getEmpLeaves = (biometricCode: string | undefined, date: any) =>
    ApiUtility.getResult(`${this.route}${"/GetEmpLeaves/" + biometricCode}`, {
      date,
    });
}
export default new AttendanceService();
