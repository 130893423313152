import React, { useEffect, useState } from "react";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import {
  Spin,
  Button,
  Table,
  Select,
  Input,
  DatePicker,
  message,
  Row,
  Col,
  Popover,
  Divider,
  Card,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  CategoryOption,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import ReportSummary from "./ReportSummary";
import ReportServices from "../../Services/ReportServices";

const { RangePicker } = DatePicker;
const { Search } = Input;

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("type", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            options={[
              { value: 0, label: "In Stock" },
              { value: 1, label: "In Use" },
              { value: 2, label: "Used" },
            ]}
            allowClear
            style={{ width: "100%" }}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={() => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const CommittedStockReport = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [show, setShow] = useState("");
  const [category, setCategory] = useState("0");
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState({
    totalCommittedStock: 0,
    totalStock: 0,
  });
  const [listParams, setListParams] = useState({
    nameSearch: "",
    subCategorySearch: "",
    assigneeSearch: "",
    seatSearch: "",
    start: 0,
    length: 15,
    fromDate: "",
    toDate: "",
    sortDir: "",
    sortCol: "",
  });

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter.columnKey;
      p.sortDir = sorter.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Seat No"}
          inputName={"seatSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "seatAllotted",
      key: "seatAllotted",
      width: 130,
      render: (seatAllotted: any, record: any) => (
        <div
          style={{ cursor: "pointer" }}
          className="ps-2 font13"
          onClick={() => {
            setSelectedRecord(record);
            setShow("Summary");
          }}
        >
          {seatAllotted === null ? `No Seat` : seatAllotted?.name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Product Name"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productName",
      key: "productName",
      render: (name, record) => <div className="ps-2 font13">{name}</div>,
      // width: 300,
    },
    {
      title: (
        <THeader
          title={"Product Category"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subCategory",
      key: "subCategory",
      render: (name, record) => <div className="ps-2 font13">{name}</div>,
    },
    {
      title: (
        <THeader
          title={"Assigned Person"}
          inputName={"assigneeSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "assignedPerson",
      key: "assignedPerson",
      className: "fw500",
      width: "250px",
      render: (val: any, record: any) =>
        val ? (
          <div className="d-flex font13">
            {val[0]?.name}
            <div className="ps-2">
              {val?.length > 1 ? (
                <Popover
                  placement="rightTop"
                  trigger="click"
                  content={() => (
                    <>
                      <h3 className="my-1">Assignee</h3>
                      <div
                        style={{
                          maxHeight: 300,
                          maxWidth: 200,
                          overflow: "auto",
                        }}
                      >
                        {val?.map((v: any, index: any) => (
                          <div key={index}>
                            <Divider className="m-0" key={index} />
                            <div className="p-1 fw500" key={index + 1}>
                              {v?.name}
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                >
                  <div className="popoverStyle">
                    {val?.length < 100 ? `+${val?.length - 1}` : `+99`}
                  </div>
                </Popover>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="ps-4">-</div>
        ),
    },
    {
      title: "Total Stock",
      dataIndex: "numberOfStock",
      key: "NumberOfStock",
      // width: 160,
      sorter: true,
      showSorterTooltip: false,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
      width: 150,
    },
    {
      title: "Committed Stock",
      dataIndex: "committedStock",
      key: "CommittedStock",
      sorter: true,
      showSorterTooltip: false,
      render: (val: any) => <div className=" font13 text-end">{val}</div>,
      width: 150,
    },

    {
      title: "In Stock",
      dataIndex: "inStock",
      key: "inStock",
      // width: "120px",

      render: (val: any) => <div className=" font13 text-end">{val}</div>,
      width: 150,
    },
    {
      title: " ",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (value, record, index) => (
        <>
          <Button
            size="small"
            onClick={() => {
              setSelectedRecord(record);
              setShow("Summary");
            }}
          >
            Summary
          </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    setLoading(true);
    ReportServices.getProductReport(
      category,
      listParams.nameSearch,
      listParams.subCategorySearch,
      listParams.assigneeSearch,
      listParams.seatSearch,
      listParams.start,
      listParams.length,
      listParams.fromDate,
      listParams.toDate,
      listParams.sortCol,
      listParams.sortDir
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            productName: data?.name,
            category: data?.category,
            subCategory: data?.subCategory?.name,
            assignedPerson: data?.assignee,
            committedStock: data?.committedStock,
            inStock: data?.numberOfStock - data?.committedStock,
            numberOfStock: data?.numberOfStock,
            status: data?.status,
            productStatusFlag: data?.productStatusFlag,
            seatAllotted: data?.seatAllotted,
          }))
        );
        setTotal(res?.total);
        setTotalRecords(res?.totalRecords);
        setLoading(false);
      }
    });
  }, [category, listParams, shouldRefresh]);

  return (
    <>
      {contextHolder}
      <Card
        title={"Committed Stock Report"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Select
                  style={{ width: "190px" }}
                  placeholder="Select Category"
                  defaultValue={"0"}
                  onChange={(val) => setCategory(val)}
                  options={CategoryOption}
                />
              </Col>
              <Col>
                <RangePicker
                  onChange={(val: any) => {
                    if (val !== null) {
                      changeListParams("fromDate", val[0].toISOString());
                      changeListParams("toDate", val[1].toISOString());
                      setShouldRefresh((x) => !x);
                    } else {
                      changeListParams("fromDate", "");
                      changeListParams("toDate", "");
                      setShouldRefresh((x) => !x);
                    }
                  }}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  style={{ fontWeight: "600" }}
                  disabled
                  onClick={() => {
                    if (
                      listParams.fromDate !== null &&
                      listParams.toDate !== null
                    ) {
                      messageApi.success(
                        `${category} Report form ${listParams.fromDate} to ${listParams.toDate} is downloaded successfully`,
                        1
                      );
                    } else {
                      messageApi.error("Please select date");
                    }
                  }}
                  icon={
                    <VerticalAlignBottomOutlined style={{ fontSize: "24px" }} />
                  }
                />
              </Col>
            </Row>
          </>
        }
      >
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          // className="InvTable"
          scroll={{ y: `calc(100vh - 345px)` }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="fw600">
                  <Table.Summary.Cell index={0} className="ps-2">
                    TOTAL
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    align="left"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    align="left"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} align="right">
                    {total.totalStock}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} align="right">
                    {total.totalCommittedStock}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} align="right">
                    {total.totalStock - total.totalCommittedStock}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        {show === "Summary" && (
          <ReportSummary
            productData={selectedRecord}
            onDismiss={() => {
              setShow("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default CommittedStockReport;
