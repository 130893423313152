import React, { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import {
  Modal,
  Divider,
  Row,
  Col,
  Select,
  Button,
  Form,
  message,
  Radio,
  Checkbox,
  Input,
  DatePicker,
} from "antd";
import ProductService, {
  Category,
  IProductApiProps,
} from "../../Services/ProductService";

const { Option } = Select;

interface IMoveProductProps {
  seatingOptions: any;
  category: Category;
  productData?: IProductApiProps;
  onDismiss: (rec: boolean) => void;
  setIsDataSaved: React.Dispatch<React.SetStateAction<boolean>>;
}
const MoveDigitalProduct = (props: IMoveProductProps) => {
  const [form] = useForm();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [moveItem, setMoveItem] = useState("");
  const [searchOption, setSearchOption] = useState("");
  const [productOptions, setProductOptions] = useState<any>([]);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    if (searchOption === "") return;
    ProductService.productOptions(props?.category, searchOption).then((res) => {
      setProductOptions(
        res?.map((itm: any) => ({ label: itm?.name, value: itm?.id }))
      );
    });
  }, [props?.category, searchOption]);

  const onSave = () => {
    setBtnDisabled(true);
    form.validateFields().then((val) => {
      let values = {
        ...val,
        seatAllotted: {
          id: val?.seatAllotted?.value,
          name: val?.seatAllotted?.label,
        },
        productName: {
          id: val?.productName?.value,
          name: val?.productName?.label,
        },
        productId: props?.productData?.id,
      };
      ProductService.moveProduct(values).then((res) => {
        if (res?.status) {
          messageApi.success(
            `${
              moveItem === "moveProduct" ? "Product" : "Variant"
            } moved successfully.`
          );
          form.resetFields();
          setBtnDisabled(false);
          setMoveItem("");
          props?.onDismiss(true);
          props.setIsDataSaved(true);
        } else {
          messageApi.error(res?.message);
          setBtnDisabled(false);
        }
      });
    });
  };

  const checkSeating = async (rule: any, value: any) => {
    await ProductService.verifySeating(
      props?.productData?.subCategory?.id ?? "",
      value?.value
    ).then((res: any) => {
      if (res?.result === true) {
        throw new Error("Seat already allotted.");
      }
    });
  };
  return (
    <>
      {contextHolder}
      <Modal
        className="InvModal"
        zIndex={1010}
        title="Move Product"
        open={true}
        onCancel={() => {
          form.resetFields();
          props.onDismiss(false);
        }}
        width={800}
        style={{ top: 20, margin: "0 0 0 27vw" }}
        footer={
          <>
            <Divider />
            <Button
              onClick={() => {
                // form.resetFields();
                props.onDismiss(false);
              }}
            >
              Cancel
            </Button>
            <Button type="primary" disabled={btnDisabled} onClick={onSave}>
              Save
            </Button>
          </>
        }
      >
        <Divider />
        <Row gutter={[45, 0]} className="fw500 font16">
          <Col>
            <Form.Item
              label="Choose what to move"
              rules={[
                {
                  required: true,
                  message: "Choose what to move in this product.",
                },
              ]}
            >
              <Radio.Group>
                <Radio
                  value="moveProduct"
                  onClick={(e) => {
                    setMoveItem("moveProduct");
                    setBtnDisabled(false);
                  }}
                >
                  Move Product
                </Radio>
                <Radio
                  value="moveVariant"
                  onClick={(e) => {
                    setMoveItem("moveVariant");
                    setBtnDisabled(false);
                  }}
                >
                  Move Variant
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 800 }}
          layout="vertical"
          autoComplete="off"
          size="large"
        >
          {moveItem === "moveProduct" && (
            <>
              <Row gutter={[45, 0]} className="pb-3 fw500">
                <Col lg={8}>
                  <Form.Item label="Issue Date" name="issueDate">
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD/MM/YYYY"}
                      // disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      onChange={(val: any) => {
                        if (val === null) {
                          form.setFieldValue("issueDate", undefined);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[45, 0]} className="pb-3 fw500">
                <Col lg={8}>
                  <Form.Item
                    initialValue={
                      props?.productData?.seatAllotted?.name === null
                        ? "No Seat Allotted"
                        : props?.productData?.seatAllotted?.name
                    }
                    name="currentSeat"
                    label="Current Seat"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item
                    label="Destination Seat"
                    name="seatAllotted"
                    rules={[
                      {
                        validator: checkSeating,
                      },
                    ]}
                  >
                    <Select
                      listHeight={100}
                      labelInValue
                      placeholder="Select Destination"
                      allowClear
                      options={props.seatingOptions}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label=" " name="noSeat" valuePropName="checked">
                    <Checkbox>No Seat</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          {moveItem === "moveVariant" && (
            <>
              <Row gutter={[45, 0]} className="fw500">
                <Col lg={8}>
                  <Form.Item name="variantId" label="Select Variant">
                    <Select allowClear mode="multiple">
                      {props?.productData?.variants?.map(
                        (itm: any, idx: number) => (
                          <Option value={itm?.id} key={idx}>
                            {`${itm?.type?.name}-${itm?.option?.name}`}
                          </Option>
                        )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item label="Issue Date" name="issueDate">
                    <DatePicker
                      placeholder="Select Date"
                      format={"DD/MM/YYYY"}
                      // disabledDate={disabledDate}
                      style={{ width: "100%" }}
                      onChange={(val: any) => {
                        if (val === null) {
                          form.setFieldValue("issueDate", undefined);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[45, 0]} className="pb-3 fw500">
                <Col lg={8}>
                  <Form.Item
                    initialValue={props?.productData?.name}
                    name="currentProduct"
                    label="Current Location"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col lg={8}>
                  <Form.Item label="Move to Product" name="productName">
                    <Select
                      labelInValue
                      listHeight={100}
                      placeholder="Search Product"
                      allowClear
                      showSearch
                      onSearch={(val) => setSearchOption(val)}
                      filterOption={false}
                      options={productOptions}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label=" "
                    name="moveToStock"
                    valuePropName="checked"
                  >
                    <Checkbox>Move To Stock</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default MoveDigitalProduct;
