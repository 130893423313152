import React, { useState, useEffect } from "react";
import {
  HomeOutlined,
  LoadingOutlined,
  PlusOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  ConfigProvider,
  Empty,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
  Spin,
  Table,
  TreeSelect,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import ProductService, { Category } from "../../Services/ProductService";
import "../../Common/Common.css";
import AddDigitalProduct from "./AddDigitalProduct";
import PendingImg from "../../Assets/images/newImg/pending_sign.svg";
import { dayjs } from "../../../Utilities/dayjs";
import MasterService from "../../Services/MasterService";
import {
  DigitalProductStatus,
  ProductStatusFlag,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import DigitalProductDetails from "./DigitalProductDetails";
import DigitalModifyState from "./DigitalModifyState";
import MoveManyProducts from "./MoveManyProducts";
let isName = false;
let isSno = false;
let isExp = false;
const { Search } = Input;

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div
        // className="searchDivStyle"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {/* <div>{title}</div> */}
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("digiStatus", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            style={{ width: "100%" }}
            bordered={false}
            allowClear
            size="small"
            onClear={() => {
              changeListParams("digiStatus");
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            className="invSelectVendor"
            placeholder={title}
            options={DigitalProductStatus}
          />
        ) : (
          <Search
            // placeholder="Search..."
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) =>
              changeListParams(inputName, e.target?.value?.trim())
            }
            onSearch={(val) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const DigitalProductList = ({ category }: any) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [variantList, setVariantList] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState<any>([]);
  const [show, setShow] = useState("");
  const [loading, setLoading] = useState(false);
  const [seatingOptions, setSeatingOptions] = useState<any>([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isPending, setIsPending] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  // const [stockType, setStockType] = useState(StockType.SingleStock);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [spinLoading, setSpinLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [listParams, setListParams] = useState({
    nameSearch: "",
    subCategorySearch: "",
    productGroupSearch: "",
    assigneeSearch: "",
    digiStatus: "",
    seatName: "",
    workFromHome: false,
    start: 0,
    length: 15,
    sortDir: "",
    sortCol: "",
  });

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };

  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setExpandedRowKeys([]);
    }
    setShouldRefresh((x) => !x);
  };

  const getSubCategory = () =>
    MasterService.getSubCategoryOptions(category, "").then((res) => {
      setSubCategoryOptions(
        res?.map((r: any) => ({
          label: r.name,
          value: r.id,
          defaultVariants: r?.defaultVariants,
        }))
      );
    });

  const getSeatingOptions = () =>
    MasterService.getSeating("", "", 0, 20, false).then((res) => {
      if (res) {
        setSeatingOptions(
          res?.items?.map((r: any) => ({
            label: r.name,
            value: r.id,
          }))
        );
      }
    });
  useEffect(() => {
    getSubCategory();
    getSeatingOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    setLoading(true);
    ProductService.getList(
      category,
      // stockType,
      listParams.nameSearch,
      listParams.subCategorySearch,
      listParams.productGroupSearch,
      listParams.assigneeSearch,
      listParams.seatName,
      "",
      listParams.digiStatus,
      listParams.start,
      listParams.length,
      listParams.sortCol,
      listParams.sortDir,
      listParams.workFromHome
    ).then((res: any) => {
      if (res !== null || res?.totalRecords !== 0) {
        // res?.items?.map;
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: data?.id,
            id: data?.id,
            productName: data?.name,
            category: data?.category,
            subCategory: data?.subCategory,
            // stockType: data?.stockType,
            seatAllotted: data?.seatAllotted,
            assignee: data?.assignee,
            productGroup: data?.productGroup,
            expiryDate:
              data?.expiryDate === "0001-01-01T00:00:00Z"
                ? "-"
                : dayjs(data?.expiryDate).format("DD/MM/YYYY"),
            digiStatus: data?.digiStatus,
            productStatusFlag: data?.productStatusFlag,
            workFromHome: data?.workFromHome,
            issueDate: data?.issueDate,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [category, listParams, shouldRefresh]);

  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Seat No"}
          inputName={"seatName"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "seatAllotted",
      key: "SeatAllotted.Name",

      sorter: true,
      showSorterTooltip: false,
      render: (seatAllotted: any, record: any) => (
        <div
          className="ps-2 text-dark  font13 cursorPointer"
          onClick={() => {
            setSelectedRowKeys(record?.key);
            setShow("ProductDetails");
            setSelectedRecord(record);
            setOpenDrawer(true);
            if (record.productStatusFlag === ProductStatusFlag.Pending) {
              setIsPending(ProductStatusFlag.Pending);
            } else {
              setIsPending(ProductStatusFlag.Approved);
            }
          }}
        >
          {seatAllotted === null || seatAllotted?.id === null
            ? `No Seat`
            : seatAllotted?.name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Product Name"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productName",
      key: "Name",
      sorter: true,
      showSorterTooltip: false,
      render: (name, record) => (
        <div
          onClick={() => {
            setSelectedRowKeys(record?.key);
            if (record.productStatusFlag === ProductStatusFlag.Pending) {
              setIsPending(ProductStatusFlag.Pending);
            } else {
              setIsPending(ProductStatusFlag.Approved);
            }
            setShow("ProductDetails");
            setSelectedRecord(record);
            setOpenDrawer(true);
          }}
          className="ps-2  cursorPointer font13"
        >
          {name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Product Category"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),

      dataIndex: "subCategory",
      key: "subCategory",
      responsive: ["xl", "xxl"],
      render: (val) => (
        <div className="ps-2  font13">
          {val === null || val?.id === null ? "-" : val?.name}
        </div>
      ),
      // className: "font13",
    },
    {
      title: (
        <THeader
          title={"Product Group"}
          inputName={"productGroupSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productGroup",
      key: "productGroup",
      responsive: ["xl", "xxl"],
      // className: "font13",
      render: (val) => (
        <div className="ps-2  font13">
          {val === null || val?.id === null ? "-" : val?.name}
        </div>
      ),
    },
    {
      title: (
        <THeader
          title={"Assigned Person"}
          inputName={"assigneeSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "assignee",
      key: "assignee",
      render: (val: any) => (
        <div className="ps-2  font13">
          {val?.length > 0 ? val?.map((v: any, i: number) => v?.name) : "-"}
        </div>
      ),
    },
    {
      title: "Warranty",
      dataIndex: "expiryDate",
      key: "expiryDate",
      responsive: ["xl", "xxl"],
      width: 90,
      // className: "font13",
      render: (val: any) => <div className="font13">{val}</div>,
    },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"status"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      width: 120,
      // className: "font13",

      dataIndex: "digiStatus",
      key: "digiStatus",
      render: (val: any, record: any) => (
        <div className="ps-2  font13">
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Button
                className="InvTableBtn"
                // style={{ width: "100px" }}
                size="small"
                onClick={() => {
                  if (record.productStatusFlag === ProductStatusFlag.Pending) {
                    messageApi.error(
                      "Product in Pending status after Approved can Access"
                    );
                  } else {
                    setSelectedRecord(record);
                    setShow("ModifyState");
                    setSelectedRowKeys(record?.key);
                  }
                }}
              >
                {val === 0
                  ? "In Stock"
                  : val === 1
                  ? "In Use"
                  : val === 2
                  ? "Defective"
                  : val === 3
                  ? "In Repair"
                  : val === 4
                  ? "Repaired"
                  : val === 5
                  ? "Replaced"
                  : "Dead"}
              </Button>
            </Col>
            <Col>
              {record.productStatusFlag === ProductStatusFlag.Pending ? (
                <img alt="" src={PendingImg} />
              ) : null}
            </Col>
            <Col span={2}>
              {record?.workFromHome === true ? <HomeOutlined /> : null}
            </Col>
          </Row>
        </div>
      ),
    },
  ];
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const handleExpand = (expanded: any, record: any) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const treeData = [{ title: "Work from home", value: true }];

  return (
    <>
      <Card
        title={Category[category]}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <TreeSelect
                  style={{ width: "190px" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={treeData}
                  placeholder="Add Filter"
                  treeDefaultExpandAll
                  treeCheckable={true}
                  onChange={(val) => {
                    changeListParams("workFromHome", val[0]);
                    setListParams({ ...listParams, start: 0 });
                    setShouldRefresh((x: boolean) => !x);
                  }}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow("AddProduct")}
                >
                  Product
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<SwapOutlined style={{}} />}
                  onClick={() => setShow("MoveMany")}
                >
                  Move
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {contextHolder}

        <Table
          // size="small"
          rowClassName={rowClassName}
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          // className="InvTable"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 300px)` }}
          onChange={onChangeTable}
          expandable={{
            columnWidth: 40,
            onExpand: (expanded, record) => {
              setSpinLoading(true);
              if (expanded) {
                // setSelectedRowKeys(record?.key);
                isExp = false;
                isName = false;
                isSno = false;
                ProductService.getVariants(record?.id, category).then(
                  (res: any) => {
                    setVariantList(res);
                    // eslint-disable-next-line array-callback-return
                    res?.map((item: any) => {
                      if (item?.name) isName = true;
                      if (item?.serialNo) isSno = true;
                      if (
                        item?.expiryDate &&
                        item?.expiryDate !== "0001-01-01T00:00:00Z"
                      )
                        isExp = true;
                    });

                    setSpinLoading(false);
                  }
                );
              } else {
                isExp = false;
                isName = false;
                isSno = false;
                // setSelectedRowKeys("");
              }
              handleExpand(expanded, record);
            },

            expandedRowKeys,
            expandedRowRender: (
              record: any,
              index: number,
              indent: number,
              expanded: boolean
            ) => {
              return (
                <>
                  {variantList?.length > 0 ? (
                    <Skeleton
                      key={record?.id}
                      active={true}
                      round={true}
                      loading={spinLoading}
                    >
                      <Row>
                        <Col className="mt-2 fw500 font16">Variants</Col>
                        {/* <Col className="pt-2 px-2">
                        <Badge
                          count={
                            variantList.length > 0 ? variantList.length : ""
                          }
                          color="#cfc3c3"
                        />
                      </Col> */}
                      </Row>

                      <Form
                        key={index}
                        style={{ paddingTop: 0 }}
                        layout="horizontal"
                      >
                        {variantList?.map((item: any, idx: any) => {
                          return (
                            <Row key={idx} style={{ marginBottom: -25 }}>
                              <Col md={1}>
                                <Form.Item label={""}>{idx + 1}.</Form.Item>
                              </Col>
                              {isName ? (
                                <Col md={5}>
                                  <Form.Item label={"Name"}>
                                    {item?.name ? item?.name : " - "}
                                  </Form.Item>
                                </Col>
                              ) : null}
                              <Col md={4}>
                                <Form.Item label={"Type"}>
                                  {item?.variantType?.name}
                                </Form.Item>
                              </Col>
                              <Col md={3}>
                                <Form.Item label={"Option"}>
                                  {item?.variantOption?.name}
                                </Form.Item>
                              </Col>
                              {/* <Form.Item label={"Brand"}>
                          {item?.brand}
                        </Form.Item> */}
                              {isSno ? (
                                <Col md={5}>
                                  <Form.Item label={"Serial No."}>
                                    {item?.serialNo ? item?.serialNo : " - "}
                                  </Form.Item>
                                </Col>
                              ) : null}
                              {isExp ? (
                                <Col md={5}>
                                  <Form.Item label={"Warranty"}>
                                    {item?.expiryDate === "0001-01-01T00:00:00Z"
                                      ? " - "
                                      : dayjs(item?.expiryDate).format(
                                          "DD/MM/YYYY"
                                        )}
                                  </Form.Item>
                                </Col>
                              ) : null}
                            </Row>
                          );
                        })}
                      </Form>
                    </Skeleton>
                  ) : (
                    <>
                      <Empty description={<span>No Variants</span>} />
                    </>
                  )}
                </>
              );
            },
          }}
        />

        {show === "ProductDetails" && (
          <DigitalProductDetails
            openDrawer={openDrawer}
            isPending={isPending}
            subCategoryOptions={subCategoryOptions}
            seatingOptions={seatingOptions}
            category={category}
            selectedRecord={selectedRecord}
            onDismiss={(rec: boolean) => {
              setSelectedRowKeys("");
              setShow("");
              setOpenDrawer(false);
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "AddProduct" && (
          <AddDigitalProduct
            category={category}
            getSubCategory={getSubCategory}
            getSeatingOptions={getSeatingOptions}
            seatingOptions={seatingOptions}
            subCategoryOptions={subCategoryOptions}
            // setShouldRefresh={setShouldRefresh}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
            setShouldRefresh={() => setShouldRefresh((x) => !x)}
          />
        )}

        {show === "ModifyState" && (
          <DigitalModifyState
            productData={selectedRecord}
            setShow={setShow}
            onDismiss={(rec: boolean) => {
              setSelectedRowKeys("");
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "MoveMany" && (
          <MoveManyProducts
            seatingOptions={seatingOptions}
            category={category}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x: boolean) => !x);
            }}
          />
        )}
      </Card>
    </>
  );
};

export default DigitalProductList;
