import React, { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin, Button, Table, Select, Input, message, Card } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  CategoryOption,
  DigitalProductStatus,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";

const { Search } = Input;
const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        {ifSelect && inputName === "status" ? (
          <Select
            onSelect={(value) => {
              changeListParams("status", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            style={{ width: "100%" }}
            className="invSelectVendor"
            placeholder={title}
            options={DigitalProductStatus}
          />
        ) : ifSelect && inputName === "category" ? (
          <Select
            onSelect={(value) => {
              changeListParams("category", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            style={{ width: "100%" }}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            options={CategoryOption}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={() => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};
const ServiceList = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [show, setShow] = useState("");
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [listParams, setListParams] = useState({
    category: "",
    subCategorySearch: "",
    nameSearch: "",
    productGroupSearch: "",
    status: "",
    start: 0,
    length: 15,
  });

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    // setListParams((p) => {
    //   p.sortCol = sorter.columnKey;
    //   p.sortDir = sorter.order;
    //   return p;
    // });
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };
  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Product Name"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productName",
      key: "productName",
      // render: (name, record) => (
      //   <div className="p-0 text-dark fw500">{name}</div>
      // ),
    },
    {
      title: (
        <THeader
          title={"Category"}
          inputName={"category"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      dataIndex: "category",
      key: "category",
    },
    {
      title: (
        <THeader
          title={"Sub-Category"}
          inputName={"subCategorySearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: (
        <THeader
          title={"Product-Group"}
          inputName={"productGroupSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productGroup",
      key: "productGroup",
    },
    {
      title: "Issue",
      dataIndex: "issue",
      key: "issue",
      responsive: ["xl", "xxl"],
    },
    {
      title: "Expected Date",
      dataIndex: "expectedDate",
      key: "expectedDate",
      responsive: ["xl", "xxl"],
    },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"status"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
        />
      ),
      dataIndex: "status",
      key: "status",

      render: (val: any, record: any) => (
        <>
          <div>
            <Button
              style={{ width: "90px" }}
              onClick={() => setShow("ModifyServiceState")}
            >
              {val === 0
                ? "In Stock"
                : val === 1
                ? "In Use"
                : val === 2
                ? "Defective"
                : val === 3
                ? "In Repair"
                : val === 4
                ? "Repaired"
                : val === 5
                ? "Replaced"
                : "Dead"}
            </Button>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      {/* <Row justify={"end"}>
        <Col>
          <Button
            type="primary"
            size="large"
            onClick={() => setShow("AddService")}
          >
            Add Service
          </Button>
        </Col>
      </Row> */}
      <Card
        title={"Services"}
        bordered={false}
        extra={
          <>
            {/* <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setUpdateToggle("close");
                    setShow(true);
                    setButtonDisable(false);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row> */}
          </>
        }
      >
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          // className="InvTable"
          scroll={{ y: `calc(100vh - 300px)` }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,

            position: ["bottomRight"],
          }}
        />
      </Card>
    </>
  );
};

export default ServiceList;
