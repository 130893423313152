import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class CabService {
  route = `/v1/api`;

  ////////////////////////////Vehicle Api Request Methods//////////////////////////
  getVehicleById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IVehicleMaster/${id}`);

  getVehicleList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IVehicleMaster`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postVehicle = (params: any) =>
    ApiUtility.post(`${this.route}/IVehicleMaster`, params);

  deleteVehicle = (id: any) =>
    ApiUtility.postForm(`${this.route}/IVehicleMaster/delete`, { id });

  ////////////////////////////Driver Api Request Methods//////////////////////////
  getDriverById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IDriver/${id}`);

  getDriverList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IDriver`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postDriver = (params: any) =>
    ApiUtility.post(`${this.route}/IDriver`, params);

  deleteDriver = (id: any) =>
    ApiUtility.postForm(`${this.route}/IDriver/delete`, { id });

  ////////////////////////////Passenger Api Request Methods//////////////////////////
  getPassengerById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IPassenger/${id}`);

  getPassengerList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = false
  ) =>
    ApiUtility.getResult(`${this.route}/IPassenger`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postPassenger = (params: any) =>
    ApiUtility.post(`${this.route}/IPassenger`, params);

  deletePassenger = (id: any) =>
    ApiUtility.postForm(`${this.route}/IPassenger/delete`, { id });
  ////////////////////////////Shift Api Request Methods//////////////////////////
  getShiftById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IShift/${id}`);

  getShiftList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IShift`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postShift = (params: any) => ApiUtility.post(`${this.route}/IShift`, params);

  deleteShift = (id: any) =>
    ApiUtility.postForm(`${this.route}/IShift/delete`, { id });

  ////////////////////////////Round Api Request Methods//////////////////////////
  getRoundById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IRound/${id}`);

  getRoundList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IRound`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postRound = (params: any) => ApiUtility.post(`${this.route}/IRound`, params);

  deleteRound = (id: any) =>
    ApiUtility.postForm(`${this.route}/IRound/delete`, { id });

  ////////////////////////////TripSchedule Api Request Methods//////////////////////////
  getTripScheduleById = (id: string) =>
    ApiUtility.getResult(`${this.route}/ITripSchedule/${id}`);

  getTripOptions = (tripId: string, name: string) =>
    ApiUtility.getResult(`${this.route}/ITripSchedule/TripOptions`, {
      tripId,
      name,
    });

  getTripScheduleList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/ITripSchedule`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postTripSchedule = (params: any) =>
    ApiUtility.post(`${this.route}/ITripSchedule`, params);

  deleteTripSchedule = (id: any) =>
    ApiUtility.postForm(`${this.route}/ITripSchedule/delete`, { id });

  ////////////////////////////PassengerType Api Request Methods//////////////////////////
  getPassengerTypeById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IPassengerType/${id}`);

  getPassengerTypeList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IPassengerType`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postPassengerType = (params: any) =>
    ApiUtility.post(`${this.route}/IPassengerType`, params);

  deletePassengerType = (id: any) =>
    ApiUtility.postForm(`${this.route}/IPassengerType/delete`, { id });

  ////////////////////////////LogStatus Api Request Methods//////////////////////////
  getLogStatusById = (id: string) =>
    ApiUtility.getResult(`${this.route}/ILogStatus/${id}`);

  getLogStatusList = (
    name: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/ILogStatus`, {
      name,
      company,
      start,
      length,
      setLength,
    });

  postLogStatus = (params: any) =>
    ApiUtility.post(`${this.route}/ILogStatus`, params);

  deleteLogStatus = (id: any) =>
    ApiUtility.postForm(`${this.route}/ILogStatus/delete`, { id });

  ////////////////////////////ITripLogs Api Request Methods//////////////////////////
  getTripLogsById = (id: string) =>
    ApiUtility.getResult(`${this.route}/ITripLogs/${id}`);

  getTripLogsList = (
    name: string,
    company: string,
    start?: number,
    length?: number
  ) =>
    ApiUtility.getResult(`${this.route}/ITripLogs`, {
      name,
      company,
      start,
      length,
    });

  postTripLogs = (params: any) =>
    ApiUtility.post(`${this.route}/ITripLogs`, params);

  deleteTripLogs = (id: any) =>
    ApiUtility.postForm(`${this.route}/ITripLogs/delete`, { id });
}
export default new CabService();
