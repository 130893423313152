import { Row, Col, Table, Card } from "antd";
import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import CleaningImg from "../../../Assets/images/newImg/cleaning.svg";
import DigitalProductImg from "../../../Assets/images/newImg/digital_product.svg";
import GardenOrPlantsImg from "../../../Assets/images/newImg/graden&plants.svg";
import MedicalKitImg from "../../../Assets/images/newImg/medical_kit.svg";
import OfficeEquipmentsImg from "../../../Assets/images/newImg/office_equipment.svg";
import PaidSoftwareImg from "../../../Assets/images/newImg/paid_software.svg";
import StationeryImg from "../../../Assets/images/newImg/stationery.svg";
import { useNavigate } from "react-router-dom";
import { ApplicationRouteLink } from "../../../Routes/ApplicationRoutePath";
import ScrollContainer from "react-indiana-drag-scroll";
import ReactECharts from "echarts-for-react";
import DashboardServices from "../../../Services/DashboardServices";

type Props = {};

const ChartColor: any = {
  "Digital Products": "#f64c4c",
  Cleaning: "#db4cf6",
  "Office Equipment": "#4cc7f6",
  "Paid Software": "#4cf69b",
  "Garden Or Plants": "#b8f64c",
  Stationery: "#f6b84c",
  "Medical Kit": "#594cf6",
};

const JsonData = [
  {
    // Chart Data //
    legendHeading: [
      "DigitalProducts",
      "Cleaning",
      "OfficeEquipment",
      "PaidSoftware",
      "GardenOrPlants",
      "Stationery",
      "MedicalKit",
    ],
    rangeData: ["2017", "2018", "2019", "2020", "2021", "2022", "2023"],
    series: [
      {
        name: "DigitalProducts",
        data: [120, 132, 101, 134, 90, 230, 210000],
      },
      {
        name: "Cleaning",
        data: [220, 182, 191, 234, 290, 330, 310],
      },
      {
        name: "OfficeEquipment",
        data: [150, 232, 201, 154, 190, 330, 410],
      },
      {
        name: "PaidSoftware",
        data: [320, 332, 301, 334, 390, 330, 32000],
      },
      {
        name: "GardenOrPlants",
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
      {
        name: "Stationery",
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
      {
        name: "MedicalKit",
        data: [820, 932, 901, 934, 1290, 1330, 1320],
      },
    ],

    // Table Data //
    tableData: [
      {
        cateories: "Digital Products",
        products: 80,
        amount: 10000,
      },
      {
        cateories: "Cleaning",
        products: 90,
        amount: 5000,
      },
      {
        cateories: "Office Equipments",
        products: 1,
        amount: 0,
      },
      {
        cateories: "Digital Products",
        products: 0,
        amount: 0,
      },
      {
        cateories: "Garden Or Plants",
        products: 0,
        amount: 0,
      },
      {
        cateories: "Stationery",
        products: 0,
        amount: 0,
      },
      {
        cateories: "Medical Kit",
        products: 0,
        amount: 0,
      },
    ],

    // Total Summary //
    totalSummary: {
      cateories: "Total :",
      product: "-",
      amount: "-",
    },
  },
];

const Dashboard = (props: Props) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(
    localStorage.getItem("activeTab") || "1"
  );
  localStorage.setItem("expensesGraphMode", "1");

  // Chart Data //
  const [legendHeading, setLegendHeading] = useState<string[]>([]);
  const [rangeData, setRangeData] = useState<string[]>([]);
  const [series, setSeries] = useState<any[]>([]);

  // Table Data //
  const [tableData, setTableData] = useState<any[]>([]);
  const getData = async () => {
    const convertArray: any = [];
    const tableArray: any = [];

    await DashboardServices.getInventoryExpenses().then((res) => {
      setLegendHeading(res?.heading);
      setRangeData(res?.years);
      // Convert API Json Data of Series //

      // eslint-disable-next-line array-callback-return
      res?.products?.map((item: any) => {
        convertArray.push({
          name: item?.name,
          type: "line",
          smooth: true,
          color: ChartColor[item?.name],
          data: item?.count,
        });
      });

      // Set Convert Data to Series //
      setSeries(convertArray);
    });

    // Convert tableData to UI Table Data //
    // eslint-disable-next-line array-callback-return
    JsonData[0].tableData.map((item: any, index: any) => {
      tableArray.push({
        key: index,
        cateories: item.cateories,
        products: "-",
        amount: "-",
        // products: item.products === 0 ? "-" : item.products,
        // amount: item.amount === 0 ? "-" : item.amount,
      });
    });
    tableArray.push({
      key: "total",
      cateories: JsonData[0].totalSummary.cateories,
      products: JsonData[0].totalSummary.product,
      amount: JsonData[0].totalSummary.amount,
    });
    // Temporary Remove
    setTableData(tableArray);
  };
  const chartData = {
    color: [
      "#f64c4c",
      "#db4cf6",
      "#4cc7f6",
      "#4cf69b",
      "#b8f64c",
      "#f6b84c",
      "#594cf6",
    ],

    tooltip: {
      trigger: "axis",
    },

    legend: {
      top: "bottom",
      textStyle: {
        fontFamily: "REM, sans- serif",
        fontSize: "11",
        marginLeft: "20",
        fontWeight: "600",
        padding: 1,
      },
      icon: "circle",
      data: legendHeading,
      itemGap: 16,
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "22%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: true,
      data: rangeData, // Weekly Days or Monthly Month Name and Yearly Years  by API //
    },
    yAxis: {
      type: "value",
    },
    emphasis: {
      focus: "series",
    },
    series: series,
    width: "100%",
  };

  // Table Data //
  const columns = [
    {
      title: <span>CATEGORIES</span>,
      dataIndex: "cateories",
      key: "cateories",
    },
    {
      title: <span>PRODUCTS</span>,
      dataIndex: "products",
      key: "products",
    },
    {
      title: <span>AMOUNT</span>,
      dataIndex: "amount",
      key: "amount",
    },
  ];

  const navigate = useNavigate();

  const changeData = (key: string) => {
    localStorage.setItem("activeTab", key);
    setCurrentActiveTab(key);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <ScrollContainer
          style={{ display: "flex", width: "99%", userSelect: "auto" }}
        >
          <div style={{ display: "flex" }}>
            <div>
              <div
                className="cardStyle"
                style={{ background: "#f2f4ff", borderColor: "#9eadfd" }}
                onClick={() => {
                  localStorage.setItem("Heading", "Cleaning");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.Cleaning
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={CleaningImg} alt="CleaningImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Cleaning</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fffaed", borderColor: "#FFD770" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .GardenOrPlantsDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Garden Or Plants");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.GardenOrPlants
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={GardenOrPlantsImg} alt="GardenOrPlantsImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Garden or plants</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#eff9fc", borderColor: "#87D1E8" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks.StationeryDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Stationery");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.Stationery
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={StationeryImg} alt="StationeryImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Stationery</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#f2f4ff", borderColor: "#7086ff" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .EmergencyMedicalKitDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Emergency Medical Kit");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.EmergencyMedicalKit
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={MedicalKitImg} alt="MedicalKitImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Medical kit</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fcf3fd", borderColor: "#E085EA" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .PaidSoftwareDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Paid Software");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.PaidSoftware
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={PaidSoftwareImg} alt="PaidSoftwareImg" />
                </div>
              </div>
              <div className="cardSubHeadingStyle">
                <span>Paid Software</span>
              </div>
            </div>

            <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#fff7ef", borderColor: "#FFB870" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .DigitalProductDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Digital Product");
                  navigate(
                    // ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    ApplicationRouteLink.DigitalProduct
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={DigitalProductImg} alt="DigitalProductImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Digital Products</span>
              </div>
            </div>

            {/* <div>
              <div
                className="cardStyle"
                style={{ backgroundColor: "#eff0f5", borderColor: "#9BA1C0" }}
                // onClick={() =>
                //   navigate(
                //     ApplicationRouteLink.MainDashboardLinks
                //       .OfficeEquipmentsDashboard
                //   )
                // }
                onClick={() => {
                  localStorage.setItem("Heading", "Office Equipments");
                  navigate(
                    ApplicationRouteLink.MainDashboardLinks.CleaningDashboard
                    // ApplicationRouteLink.OfficeEquipments
                  );
                }}
              >
                <div className="cardImgStyle">
                  <img src={OfficeEquipmentsImg} alt="OfficeEquipmentsImg" />
                </div>
              </div>

              <div className="cardSubHeadingStyle">
                <span>Office Equipments</span>
              </div>
            </div> */}
          </div>
        </ScrollContainer>
        <div style={{ marginTop: 50 }}>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Card title="Inventory Expenses">
                <ReactECharts
                  option={chartData}
                  opts={{ renderer: "svg" }}
                  style={{ height: "380px", width: "100%" }}
                />
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
