import React from 'react'
import ConstructionList from '../../../CapsitechConstruction/Components/ConstructionList'

type Props = {}

const Construction = (props: Props) => {
    return (
        <ConstructionList />
    )
}

export default Construction