import React, { useState, useEffect, useContext, useRef } from "react";
import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
  FileTextOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
  ProfileTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import ConstructionDetails from "./ConstructionDetails";
import AddConstruction from "./AddConstruction";
import {
  ProductStatusFlag,
  pageSizeOption,
} from "../../CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";
import PurchasesService from "../Services/PurchasesService";
import ApplicationContext from "../../CapsitechInventoryComponents/Context/ApplicationContext/CreateApplicationContext";
import AddMultiple from "./AddMultiple";
import ConstructionHistory from "./ConstructionHistory";
import "../../CapsitechInventoryComponents/Common/Common.css";
import { dayjs } from "../../Utilities/dayjs";
const { Search } = Input;
const { RangePicker } = DatePicker;

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="">
        <Search
          placeholder={title}
          className="InvSearchInput"
          allowClear
          onChange={(e) => changeListParams(inputName, e.target?.value?.trim())}
          onSearch={(val) => {
            setListParams({ ...listParams, start: 0 });
            setShouldRefresh((x: boolean) => !x);
          }}
          size="small"
          bordered={false}
        />
      </div>
    </>
  );
};
const ConstructionList = (props: any) => {
  const {
    getMaterialOptions,
    getItemOption,
    getMeasurementOption,
    getCVendorOptions,
    getGstOptions,
    materialOptions,
  } = useContext(ApplicationContext);
  const [itemOpt, setItemOpt] = useState<any>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<any>({
    id: "",
    name: "",
  });
  const [show, setShow] = useState("");
  const [showHistory, setShowHistory] = useState("");
  const [data, setData] = useState<any>({
    tData: [],
    summary: {
      gAmount: 0,
      ggAmount: 0,
      gtAmount: 0,
    },
  });
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isPending, setIsPending] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [listParams, setListParams] = useState({
    recordStatusType: 0,
    billNoSearch: "",
    materialNameSearch: "",
    itemNameSearch: "",
    vendorNameSearch: "",
    fromDate: "",
    toDate: "",
    sortCol: "",
    sortDir: "",
    start: 0,
    length: 15,
  });
  const [form] = Form.useForm();
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter.columnKey;
      p.sortDir = sorter.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };

  const columns: any = [
    {
      title: (
        <THeader
          title={"Invoice Number"}
          inputName={"billNoSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "billNoSearch",
      key: "billNoSearch",
      // width: 270,
      width: "14%",
      sorter: true,
      // showSorterTooltip: false,
      render: (name: any, record: any) => (
        <div
          onClick={() => {
            setSelectedRowKeys(record.key);
            if (record.productStatusFlag === ProductStatusFlag.Pending) {
              setIsPending(ProductStatusFlag.Pending);
            } else {
              setIsPending(ProductStatusFlag.Approved);
            }
            setShow("AddConstructionMultiple");
            setId(record?.id);
            setOpenDrawer(true);
          }}
          // className="p-0 text-dark fw500"
          className="font13 cursorPointer"
        >
          <Button type="link" size="small">
            {name}
          </Button>
        </div>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "billingDate",
      key: "billingDate",
      // sorter: true,
      showSorterTooltip: false,
      // align: "right",
      render: (val: any) => (
        <div className=" font13">{dayjs(val).format("DD/MM/YYYY")}</div>
      ),
      width: "10%",
    },
    // {
    //   title: (
    //     <THeader
    //       title={"Category"}
    //       inputName={"materialNameSearch"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setShouldRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "materialNameSearch",
    //   key: "materialNameSearch",
    //   render: (val: any) => <div className="ps-2 font13">{val}</div>,
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Sub Category"}
    //       inputName={"itemNameSearch"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setShouldRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "itemNameSearch",
    //   key: "itemNameSearch",
    //   // width: 300,
    //   render: (val: any) => <div className="ps-2  font13">{val}</div>,
    // },
    {
      title: (
        <THeader
          title={"Vendor Name"}
          inputName={"vendorNameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "vendorNameSearch",
      key: "vendorNameSearch",
      width: "20%",
      render: (val: any) => <div className=" font13  ps-2">{val}</div>,
    },
    // {
    //   title: "GST",
    //   dataIndex: "gst",
    //   key: "gst",
    // sorter: true,
    //   showSorterTooltip: false,
    //   align: "right",
    //   width: 70,
    //   render: (val: any) => (
    //     <div className=" font13">{val ? `${val}%` : `-`}</div>
    //   ),
    // },
    // {
    //   title: "Qty",
    //   dataIndex: "quantity",
    //   key: "quantity",
    // sorter: true,
    //   showSorterTooltip: false,
    //   align: "right",
    //   width: 80,
    //   render: (val: any) => <div className=" font13">{val}</div>,
    // },
    // {
    //   title: "Rate",
    //   dataIndex: "rate",
    //   key: "rate",
    // sorter: true,
    //   align: "right",
    //   width: 90,
    //   showSorterTooltip: false,
    //   render: (val: any) => <div className=" font13">{val}</div>,
    // },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      // sorter: true,
      align: "right",
      showSorterTooltip: false,
      width: "12%",
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: "GST Amt",
      dataIndex: "gstAmount",
      key: "gstAmount",
      // sorter: true,
      align: "right",
      showSorterTooltip: false,
      width: "12%",
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    // {
    //   title: "Round Off",
    //   dataIndex: "roundOff",
    //   key: "roundOff",
    //   // sorter: true,
    //   align: "right",
    //   showSorterTooltip: false,
    //   width: "8%",
    //   render: (val: any) => <div className=" font13">{val}</div>,
    // },
    {
      title: "Net Amount",
      dataIndex: "netTotalAmount",
      key: "netTotalAmount",
      // sorter: true,
      align: "right",
      showSorterTooltip: false,
      width: "11%",
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: " ",
      dataIndex: "history",
      key: "history",
      // sorter: true,
      align: "right",
      showSorterTooltip: false,
      width: "5%",
      render: (_: any, record: any) => (
        <div style={{ display: "flex" }}>
          <div style={{ marginRight: 0 }}>
            <Button
              type="text"
              icon={
                <HistoryOutlined
                  style={{
                    color: "#377dff",
                  }}
                  title="History"
                />
              }
              className="cursorPointer"
              style={{ color: "#377dff" }}
              onClick={() => {
                setSelectedRecord(record);
                setShowHistory("History");
              }}
              size="small"
            >
              {/* History */}
            </Button>
          </div>
          {listParams?.recordStatusType === 0 ? (
            <div>
              <Popconfirm
                title="Are you sure?"
                onConfirm={() => deleteList(record?.id)}
                okText="Yes"
                cancelText="No"
                placement="top"
                icon={<DeleteOutlined style={{ color: "red" }} />}
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  danger
                  title="Delete"
                  className="invSelectVendor"
                  size="small"
                >
                  {/* Delete */}
                </Button>
              </Popconfirm>
            </div>
          ) : (
            <div>
              <Tag icon={<CloseCircleOutlined />} color="error">
                Removed
              </Tag>{" "}
            </div>
          )}
        </div>
      ),
    },
  ];
  const deleteStatus: any = [
    {
      label: "None",
      value: 0,
    },
    {
      label: "Delete",
      value: 3,
    },
  ];
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const deleteList = (id: string) => {
    PurchasesService.delete(id).then((res: any) => {
      if (res?.status) {
        message.success("Deleted Successfully");
        setShouldRefresh((x) => !x);
      } else {
        message.error(res?.message);
      }
    });
  };
  useEffect(() => {
    getMaterialOptions();
    getItemOption();
    getMeasurementOption();
    getCVendorOptions();
    // getGstOptions()
  }, []);

  useEffect(() => {
    setLoading(true);
    PurchasesService.getList(
      listParams.recordStatusType,
      listParams.billNoSearch,
      listParams.materialNameSearch,
      listParams.itemNameSearch,
      listParams.vendorNameSearch,
      listParams.fromDate,
      listParams.toDate,
      listParams.sortCol,
      listParams.sortDir,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        // setData(
        //   res?.items?.map((data: any, i: number) => ({
        //     key: i,
        //     id: data?.id,
        //     billNoSearch: data?.billingNo,
        //     materialNameSearch: data?.materialType?.name,
        //     itemNameSearch: data?.item?.name,
        //     vendorNameSearch: data?.vendor?.name,
        //     quantity: parseFloat((data?.quantity)?.toFixed(2)),
        //     rate: parseFloat((data?.rate)?.toFixed(2)),
        //     amount: parseFloat((data?.amount)?.toFixed(2)),
        //     gst: data?.gst?.name,
        //     totalAmount: parseFloat((data?.totalAmount)?.toFixed(2)),
        //     gstAmount: parseFloat((data?.gstAmount)?.toFixed(2)),
        //   }))
        // );
        setData({
          tData: res?.items[0]?.pResult?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            billNoSearch: data?.billingNo,
            billingDate: data?.billingDate,
            vendorNameSearch: data?.vendor?.name,
            amount: parseFloat(data?.tAmount?.toFixed(2)),
            gstAmount: parseFloat(data?.tGstAmount?.toFixed(2)),
            totalAmount: parseFloat(data?.tTotalAmount?.toFixed(2)),
            netTotalAmount: data?.netTotalAmount,
            noOfCount: data?.noOfCount,
            roundOff: data?.roundOff,
          })),
          summary: {
            gAmount: res?.items[0]?.summary?.gAmount,
            ggAmount: res?.items[0]?.summary?.ggAmount,
            gtAmount: res?.items[0]?.summary?.gtAmount,
          },
        });
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [listParams, shouldRefresh]);
  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  // function getWindowSize() {
  //   return { innerWidth };
  // width:"12%",
  // }
  // const [windowSize, setWindowSize] = useState(window.innerWidth);

  // useEffect(() => {
  //   function handleWindowResize() {
  //     setWindowSize(window.innerWidth);
  //   }

  //   window.addEventListener('resize', handleWindowResize);

  //   return () => {
  //     window.removeEventListener('resize', handleWindowResize);
  //   };
  // }, []);
  // console.log("res = ", windowSize)
  return (
    <>
      {show === "" && (
        <>
          <Card
            title={"Purchases"}
            bordered={false}
            extra={
              <>
                {/* <Row justify={"end"} gutter={[18, 16]}>
                  <Col>
                    <Button
                      type="primary"
                      ghost
                      size="middle"
                      // className="mb-3"
                      // title="Vendor"
                      style={{ fontWeight: "600" }}
                      icon={<PlusOutlined style={{}} />}
                      onClick={() => {
                        setUpdateToggle("close");
                        setShow(true);
                        setButtonDisable(false);
                      }}
                    >
                      New
                    </Button>
                  </Col>
                </Row> */}
                <Form form={form}>
                  <Row justify={"end"} gutter={[14, 14]}>
                    {/* <Col span={4}>
                      <Form.Item name="materialType">
                        <Select
                          placeholder="Select Category"
                          style={{ width: 150 }}
                          labelInValue
                          allowClear
                          showSearch
                          listHeight={224}
                          options={materialOptions}
                          onChange={(_: any, op: any) => {
                            setItemOpt(
                              op?.items?.map((itm: any) => ({
                                label: itm?.name,
                                value: itm?.id,
                              }))
                            );
                          }}
                          onSelect={(val: any) => {
                            setSelectedMaterial({
                              id: val?.value,
                              name: val?.label,
                            });
                            form.setFieldValue("item", []);
                          }}
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item name="item">
                        <Select
                          placeholder="Select SubCategory"
                          style={{ width: 150 }}
                          labelInValue
                          allowClear
                          showSearch
                          listHeight={224}
                          options={itemOpt}
                          filterOption={(input, option) =>
                            (option?.label?.toString() ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col> */}
                    <Col >
                      <RangePicker
                        format={"DD/MM/YYYY"}
                        onChange={(val: any) => {
                          if (val !== null) {
                            changeListParams("fromDate", val[0].toISOString());
                            changeListParams("toDate", val[1].toISOString());
                            setShouldRefresh((x) => !x);
                          } else {
                            changeListParams("fromDate", "");
                            changeListParams("toDate", "");
                            setShouldRefresh((x) => !x);
                          }
                        }}
                      />
                    </Col>
                    <Col >
                      <Select
                        options={deleteStatus}
                        labelInValue
                        placeholder={"Filter"}
                        // allowClear
                        onSelect={(val: any) => {
                          // console.log(val);
                          setListParams({
                            recordStatusType: val?.value,
                            billNoSearch: "",
                            materialNameSearch: "",
                            itemNameSearch: "",
                            vendorNameSearch: "",
                            fromDate: "",
                            toDate: "",
                            sortCol: "",
                            sortDir: "",
                            start: 0,
                            length: 15,
                          });
                        }}
                        onClear={() => {
                          setListParams({
                            recordStatusType: 0,
                            billNoSearch: "",
                            materialNameSearch: "",
                            itemNameSearch: "",
                            vendorNameSearch: "",
                            fromDate: "",
                            toDate: "",
                            sortCol: "",
                            sortDir: "",
                            start: 0,
                            length: 15,
                          });
                        }}
                        style={{ width: 100 }}
                      />
                    </Col>
                    <Col >
                      <Button
                        type="primary"
                        ghost
                        size="middle"
                        style={{ fontWeight: "600" }}
                        icon={<PlusOutlined style={{}} />}
                        onClick={() => setShow("AddConstructionMultiple")}
                      // style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        Construction
                      </Button>
                    </Col>
                    {/* <Col>
              <Button
                type="primary"
                onClick={() => setShow("AddConstructionMultipleModal")}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Add Multiple Modal
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setShow("AddConstruction")}
                style={{ marginBottom: 10 }}
              >
                Add Construction
              </Button>
            </Col> */}
                  </Row>
                </Form>
              </>
            }
          >
            <Table
              rowClassName={rowClassName}
              loading={tableLoading}
              columns={columns}
              dataSource={data?.tData}
              onChange={onChangeTable}
              // className="InvTable"
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
              summary={() => {
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1} className="fw600">
                        <div className="ps-2">TOTAL</div>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        className="fw600"
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        className="fw600"
                      ></Table.Summary.Cell>

                      <Table.Summary.Cell
                        index={4}
                        className="ps-2 fw600"
                        align="right"
                      >
                        {data?.summary?.gAmount}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={5}
                        className="fw600"
                        align="right"
                      >
                        {data?.summary?.ggAmount}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={6}
                        className="fw600"
                        align="right"
                      >
                        {data?.summary?.gtAmount}
                      </Table.Summary.Cell>
                      {/* <Table.Summary.Cell
                        index={7}
                        className="fw600"
                        align="right"
                      ></Table.Summary.Cell> */}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Card>
        </>
      )}

      {/* {show === "ConstructionDetails" && (
        <ConstructionDetails
          openDrawer={openDrawer}
          selectedRecord={id}
          onDismiss={(rec: boolean) => {
            setShow("");
            setOpenDrawer(false);
            setSelectedRowKeys("");
            if (rec) setShouldRefresh((x) => !x);
          }}
        />
      )} */}

      {/* {show === "AddConstruction" && (
        <AddConstruction
          onDismiss={(rec: boolean) => {
            setShow("");
            if (rec) setShouldRefresh((x) => !x);
          }}
        />
      )} */}

      {show === "AddConstructionMultiple" && (
        <div
          // style={{
          //   backgroundColor: "#f0f2f5",
          //   padding: "5px 20px 20px 20px",
          //   borderRadius: 10,
          //   height: "calc(100vh - 120px)",
          // }}
          style={
            {
              // backgroundColor: "rgb(236 244 255)",
              // padding: "7px 20px 7px 20px",
              // padding: "20px 20px",
              // borderRadius: "10px",
              // height: "calc(100vh - 140px)",
            }
          }
        >
          <Card
            title={
              id ? "Edit Construction Item(s)" : "Add Construction Item(s)"
            }
            extra={
              <Button
                // type="primary"
                size="small"
                // ghost
                icon={<CloseOutlined />}
                // style={{ marginBottom: 8 }}
                onClick={() => {
                  setId("");
                  setShow("");
                  setSelectedRowKeys("");
                }}
              />
            }
            // className="purchaseC"
            bordered={false}
            style={{ height: "calc(100vh - 110px)", overflowY: "auto" }}
          >
            <AddMultiple
              id={id}
              height={271}
              onDismiss={(rec: boolean) => {
                setId("");
                setShow("");
                setSelectedRowKeys("");
                if (rec) setShouldRefresh((x) => !x);
              }}
            />
          </Card>
        </div>
      )}
      {showHistory === "History" && (
        <Drawer
          width="35vw"
          zIndex={1005}
          open={true}
          title={selectedRecord?.billNoSearch}
          className="InvDetailsDrawer"
          // className="InvAddDrawer-A"
          closable={false}
          onClose={() => {
            setShow("");
            setShowHistory("");
          }}
          extra={
            <Button
              // className="border-none"
              icon={<CloseOutlined />}
              onClick={() => {
                setShowHistory("");
                setSelectedRecord("");
              }}
            />
          }
        >
          <ConstructionHistory
            id={selectedRecord?.id}
            shouldRefresh={shouldRefresh}
          />
        </Drawer>
      )}
      {/* {
        show === "AddConstructionMultipleModal" &&
        <>
          <Modal
            // style={{ left: windowSize < 1200 ? 10 : 109 }}
            zIndex={1010}
            width={"calc(100vw - 235px)"}
            title="Add Multiple Entries"
            open={true}
            onOk={() => { }}
            onCancel={() => {
              setShow("")
            }}
            footer={false}
          // footer={
          //   <>
          //     <Button
          //       htmlType="submit"
          //       ghost
          //       type="primary"
          //     // className="Inv-SaveBtn"
          //     // onClick={() => onSave(true)}
          //     // disabled={buttonDisable}
          //     >
          //       Save
          //     </Button>
          //     <Button
          //       htmlType="submit"
          //       danger
          //       onClick={() => {
          //         setShow("")
          //       }}
          //     // className="Inv-SaveAndNewBtn"
          //     // onClick={() => onSave(false)}
          //     // disabled={buttonDisable}
          //     >
          //       Cancel
          //     </Button>
          //   </>


          // }
          // onCancel={(rec: any) => {
          //   setShow("");
          //   if (rec) setShouldRefresh((x) => !x);
          // }}
          >
            <AddMultiple height={300} onDismiss={() => {
              setShow("")
            }} />
          </Modal>
        </>
      } */}
    </>
  );
};

export default ConstructionList;
