import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import ProductService, { Category } from "../../Services/ProductService";
import { dayjs } from "../../../Utilities/dayjs";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import MasterService from "../../Services/MasterService";
import "../../Common/Common.css";

interface AddProductsProp {
  category: Category;
  subCategoryOptions: any;
  onDismiss: (rec: boolean) => void;
  getSubCategory: () => Promise<void>;
  setShouldRefresh: () => void;
}

const AddInOption = ({ funcType, category, getSubCategory }: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [status, setStatus] = useState<any>("success");

  const { getUnits } = useContext(ApplicationContext);

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "subCategory")
        MasterService.postSubCategory({ name: name, category: category }).then(
          (r: any) => {
            if (r?.message) {
              message.error("Product category already exist");
            } else {
              getSubCategory();
              setName("");
              inputRef.current?.focus();
            }
          }
        );
      else if (funcType === "unit")
        MasterService.postUnit({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error(r?.message);
          } else {
            getUnits();
            setName("");
            inputRef.current?.focus();
          }
        });
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Row gutter={[18, 18]} className="InvAddForm">
        <Col lg={20}>
          <Input
            placeholder="Enter Option"
            ref={inputRef}
            style={{ width: "100%" }}
            value={name}
            onChange={onNameChange}
            status={status}
            onPressEnter={addOption}
          />
        </Col>
        <Col lg={4}>
          <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
        </Col>
      </Row>
    </>
  );
};

const AddProduct = (props: AddProductsProp) => {
  const [form1Values, setForm1Values] = useState<any>({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const inputRef = React.useRef<any>(null);
  const selectRef = React.useRef<any>(null);
  const { unitOptions, vendorOptions, /*disabledDate,*/ getVendorOptions } =
    useContext(ApplicationContext);

  useEffect(() => {
    setTimeout(() => {
      activeKey === "1"
        ? inputRef.current?.focus()
        : selectRef.current?.focus();
    }, 2);
  }, [activeKey]);

  const onSaveAndContinue = () => {
    form1.validateFields().then((val) => {
      setForm1Values(val);
      setActiveKey("2");
    });
  };

  const resetForm = () => {
    props.setShouldRefresh();
    form1.resetFields();
    // form2.resetFields();
    // setActiveKey("1");
  };
  const onSave = (onlySave: boolean) => {
    form1.validateFields().then(async (values) => {
      let val = {
        ...values,
        subCategory: {
          id: values?.subCategory?.value,
          name: values?.subCategory?.label,
        },
        unit: {
          id: values?.unit?.value,
          name: values?.unit?.label,
        },
        vendor: {
          id: values?.vendor?.value,
          name: values?.vendor?.label,
        },
        category: props.category,
      };

      setButtonDisable(true);
      await ProductService.post(val).then((res) => {
        if (res?.status) {
          messageApi.success(`Product ${val?.name} Added Successfully`, 1);
          setTimeout(() => {
            onlySave ? props.onDismiss(true) : resetForm();
          }, 1000);
        } else {
          messageApi.error(res?.message);
        }
        setButtonDisable(false);
      });
    });
  };

  // const items: TabsProps["items"] = [
  //   {
  //     key: "1",
  //     label: `Dashboard`,
  //     children: (
  //       <>
  //         <Form
  //           form={form1}
  //           labelCol={{ span: 18 }}
  //           wrapperCol={{ span: 24 }}
  //           style={{ maxWidth: 800 }}
  //           layout="vertical"
  //           initialValues={{ remember: true }}
  //           autoComplete="off"
  //           // size="large"
  //           className="InvAddForm"
  //         >
  //           <Row gutter={[24, 12]}>
  //             <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Name"
  //                 name="name"
  //                 rules={[
  //                   { required: true, message: "Please input Product Name" },
  //                   {
  //                     whitespace: true,
  //                   },
  //                 ]}
  //               >
  //                 <Input placeholder="Enter Product Name" ref={inputRef} />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //               <Form.Item
  //                 label="Product-Category"
  //                 name="subCategory"
  //                 required
  //                 rules={[
  //                   {
  //                     required: true,
  //                     message: "Please Select Product-Category",
  //                   },
  //                 ]}
  //               >
  //                 <Select
  //                   placeholder="Select Product Category"
  //                   style={{ width: "100%" }}
  //                   labelInValue
  //                   allowClear
  //                   showSearch
  //                   listHeight={224}
  //                   options={props.subCategoryOptions}
  //                   filterOption={(input, option) =>
  //                     (option?.label?.toString() ?? "")
  //                       .toLowerCase()
  //                       .includes(input.toLowerCase())
  //                   }
  //                   dropdownRender={(menu) => (
  //                     <>
  //                       {menu}
  //                       <AddInOption
  //                         funcType={"subCategory"}
  //                         category={props.category}
  //                         getSubCategory={props.getSubCategory}
  //                       />
  //                     </>
  //                   )}
  //                 />
  //               </Form.Item>
  //             </Col>

  //             <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 // initialValue={dayjs()}
  //                 label="Purchase Date"
  //                 name="purchaseDate"
  //               >
  //                 <DatePicker
  //                   placeholder="Select Date"
  //                   style={{ width: "100%" }}
  //                   format={"DD/MM/YYYY"}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           {/* <Row gutter={[24, 12]}>
  //             <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //               <Form.Item label="Brand" name="brand">
  //                 <Input placeholder={} />
  //               </Form.Item>
  //             </Col>
  //           </Row> */}
  //           <Row gutter={[24, 12]}>
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Vendor Name"
  //                 name="vendor"
  //                 rules={[{ required: true, message: "Please Select Vendor" }]}
  //               >
  //                 <Select
  //                   ref={selectRef}
  //                   labelInValue
  //                   placeholder="Search Vendor"
  //                   options={vendorOptions}
  //                   allowClear
  //                   style={{ width: "100%" }}
  //                   filterOption={false}
  //                   showSearch
  //                   onSearch={(val) => getVendorOptions(val)}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Unit"
  //                 name="unit"
  //                 rules={[{ required: true, message: "Please Select Unit" }]}
  //               >
  //                 <Select
  //                   placeholder="Select Unit"
  //                   style={{ width: "100%" }}
  //                   labelInValue
  //                   allowClear
  //                   options={unitOptions}
  //                   listHeight={224}
  //                   filterOption={(input, option) =>
  //                     (option?.label?.toString() ?? "")
  //                       .toLowerCase()
  //                       .includes(input.toLowerCase())
  //                   }
  //                   dropdownRender={(menu) => (
  //                     <>
  //                       {menu}
  //                       <AddInOption funcType={"unit"} />
  //                     </>
  //                   )}
  //                 />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Number of stock"
  //                 name="numberOfStock"
  //                 rules={[
  //                   {
  //                     pattern: new RegExp(/^[0-9]/i),
  //                     message: "Please input Number only!",
  //                   },
  //                   { required: true, message: "Enter No. of Stock" },
  //                 ]}
  //               >
  //                 <Input placeholder="Enter No. of Quantity Purchased" />
  //               </Form.Item>
  //             </Col>
  //             {/* <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //               <Form.Item
  //                 label="Issue Date"
  //                 name="issueDate"
  //                 initialValue={dayjs()}
  //               >
  //                 <DatePicker
  //                   placeholder="Select Date"
  //                   format={"DD/MM/YYYY"}
  //                   style={{ width: "100%" }}
  //                 />
  //               </Form.Item>
  //             </Col> */}
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item label="Invoice Number" name="invoiceNumber">
  //                 <Input placeholder="Enter Invoice Number" />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item label="Total Price" name="totalPrice">
  //                 <Input placeholder="Enter Total Price" />
  //               </Form.Item>
  //             </Col>
  //             <Col lg={{ span: 24 }} sm={{ span: 12 }}>
  //               <Form.Item label="Expiry Date" name="expiryDate">
  //                 <DatePicker
  //                   placeholder="Select Date"
  //                   format={"DD/MM/YYYY"}
  //                   // disabledDate={disabledDate}
  //                   style={{ width: "100%" }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //           <Row gutter={[24, 12]}>
  //             <Col lg={{ span: 24 }} md={{ span: 16 }}>
  //               <Form.Item label="Description" name="description">
  //                 <Input.TextArea
  //                   placeholder="Description"
  //                   autoSize={{ minRows: 2, maxRows: 6 }}
  //                 />
  //               </Form.Item>
  //             </Col>
  //           </Row>
  //         </Form>
  //       </>
  //     ),
  //   },
  //   // {
  //   //   key: "2",
  //   //   label: `Purchase Information`,

  //   //   children: (
  //   //     <>
  //   //       <Form
  //   //         form={form2}
  //   //         labelCol={{ span: 18 }}
  //   //         wrapperCol={{ span: 24 }}
  //   //         style={{ maxWidth: 800 }}
  //   //         layout="vertical"
  //   //         initialValues={{ remember: true }}
  //   //         autoComplete="off"
  //   //         size="large"
  //   //         className="InvAddForm"
  //   //       >
  //   //         <Row gutter={[24, 12]}>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item
  //   //               label="Vendor Name"
  //   //               name="vendor"
  //   //               rules={[{ required: true, message: "Please Select Vendor" }]}
  //   //             >
  //   //               <Select
  //   //                 ref={selectRef}
  //   //                 labelInValue
  //   //                 placeholder="Search Vendor"
  //   //                 options={vendorOptions}
  //   //                 allowClear
  //   //                 style={{ width: "100%" }}
  //   //                 filterOption={false}
  //   //                 showSearch
  //   //                 onSearch={(val) => getVendorOptions(val)}
  //   //               />
  //   //             </Form.Item>
  //   //           </Col>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item
  //   //               // initialValue={dayjs()}
  //   //               label="Purchase Date"
  //   //               name="purchaseDate"
  //   //             >
  //   //               <DatePicker
  //   //                 placeholder="Select Date"
  //   //                 style={{ width: "100%" }}
  //   //                 format={"DD/MM/YYYY"}
  //   //               />
  //   //             </Form.Item>
  //   //           </Col>
  //   //         </Row>
  //   //         <Row gutter={[24, 12]}>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }} xs={{ span: 18 }}>
  //   //             <Form.Item
  //   //               label="Purchase Type"
  //   //               name="purchaseType"
  //   //               // rules={[
  //   //               //   { required: true, message: "Please Select Purchase Type" },
  //   //               // ]}
  //   //             >
  //   //               <Select
  //   //                 placeholder="Select Purchase Type"
  //   //                 allowClear
  //   //                 style={{ width: "100%" }}
  //   //                 options={[
  //   //                   { value: 0, label: "UPI" },
  //   //                   { value: 1, label: "Cash" },
  //   //                   { value: 2, label: "Cheque" },
  //   //                   { value: 3, label: "Net Banking" },
  //   //                   { value: 4, label: "Credit/Debit Cards" },
  //   //                 ]}
  //   //               />
  //   //             </Form.Item>
  //   //           </Col>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item label="Transaction ID" name="transactionId">
  //   //               <Input placeholder="Enter Transaction ID" />
  //   //             </Form.Item>
  //   //           </Col>
  //   //         </Row>
  //   //         <Row gutter={[24, 12]}>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item label="Invoice Number" name="invoiceNumber">
  //   //               <Input placeholder="Enter Invoice Number" />
  //   //             </Form.Item>
  //   //           </Col>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item
  //   //               label="Price Per Unit"
  //   //               name="pricePerUnit"
  //   //               rules={[
  //   //                 {
  //   //                   pattern: new RegExp(/^[0-9]/i),
  //   //                   message: "Please input Number only!",
  //   //                 },
  //   //               ]}
  //   //             >
  //   //               <Input placeholder="Enter Price Per Unit" />
  //   //             </Form.Item>
  //   //           </Col>
  //   //         </Row>
  //   //         <Row gutter={[24, 12]} className="">
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item label="Tax Paid (%)" name="tax">
  //   //               <Input placeholder="Enter Tax Paid" />
  //   //             </Form.Item>
  //   //           </Col>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item label="Total Price" name="totalPrice">
  //   //               <Input placeholder="Enter Total Price" />
  //   //             </Form.Item>
  //   //           </Col>
  //   //         </Row>
  //   //         <Row gutter={[24, 12]} className="pb-5">
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item
  //   //               label="Issue Date"
  //   //               name="issueDate"
  //   //               initialValue={dayjs()}
  //   //             >
  //   //               <DatePicker
  //   //                 placeholder="Select Date"
  //   //                 format={"DD/MM/YYYY"}
  //   //                 style={{ width: "100%" }}
  //   //               />
  //   //             </Form.Item>
  //   //           </Col>
  //   //           <Col lg={{ span: 8 }} sm={{ span: 12 }}>
  //   //             <Form.Item label="Expiry Date" name="expiryDate">
  //   //               <DatePicker
  //   //                 placeholder="Select Date"
  //   //                 format={"DD/MM/YYYY"}
  //   //                 // disabledDate={disabledDate}
  //   //                 style={{ width: "100%" }}
  //   //               />
  //   //             </Form.Item>
  //   //           </Col>
  //   //         </Row>
  //   //       </Form>
  //   //     </>
  //   //   ),
  //   // },
  // ];
  // const onTabChange = (newActiveKey: string) => {
  //   setActiveKey(newActiveKey);
  // };
  return (
    <>
      {contextHolder}
      <Drawer
        zIndex={1005}
        title={"Add Product"}
        open={true}
        extra={<CloseOutlined onClick={() => props.onDismiss(false)} />}
        closable={false}
        onClose={() => props.onDismiss(false)}
        afterOpenChange={() => setActiveKey("1")}
        width="35vw"
        className="InvAddDrawer-A"
        footer={
          <>
            {/* {activeKey === "2" ? ( */}
            <Space align="end">
              <Button
                htmlType="submit"
                // size="small"
                // type="primary"
                className="Inv-SaveBtn"
                type="primary"
                ghost
                onClick={() => onSave(true)}
                disabled={buttonDisable}
              >
                Save
              </Button>
              <Button
                htmlType="submit"
                // type="primary"
                // size="small"
                className="Inv-SaveAndNewBtn"
                type="primary"
                ghost
                onClick={() => onSave(false)}
                disabled={buttonDisable}
              >
                Save and New
              </Button>

              <Button
                htmlType="button"
                className="Inv-CancelBtn"
                // size="small"
                // type="primary"
                danger
                // ghost
                onClick={() => props.onDismiss(false)}
              >
                Cancel
              </Button>
              {/* ) : (
              <>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="Inv-SaveAndNewBtn"
                  onClick={() => onSaveAndContinue()}
                >
                  Save and Continue
                </Button>
              </>
            )} */}
            </Space>
          </>
        }
      >
        <Form
          form={form1}
          initialValues={{ remember: true }}
          colon={false}
          requiredMark={false}
          labelAlign="left"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          className="InvAddForm"

          // size="small"
          // className="InvDForm"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please input Product Name" },
              {
                whitespace: true,
              },
            ]}
          >
            <Input placeholder="Enter Product Name" ref={inputRef} />
          </Form.Item>

          <Form.Item
            label="Product-Category"
            name="subCategory"
            required
            rules={[
              {
                required: true,
                message: "Please Select Product-Category",
              },
            ]}
          >
            <Select
              placeholder="Select Product Category"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              showSearch
              listHeight={224}
              options={props.subCategoryOptions}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <AddInOption
                    funcType={"subCategory"}
                    category={props.category}
                    getSubCategory={props.getSubCategory}
                  />
                </>
              )}
            />
          </Form.Item>

          {/* 
              <Col lg={{ span: 8 }} sm={{ span: 12 }}>
                <Form.Item label="Brand" name="brand">
                  <Input placeholder={} />
                </Form.Item>
              </Col>
            */}

          <Form.Item
            // initialValue={dayjs()}
            label="Purchase Date"
            name="purchaseDate"
          >
            <DatePicker
              placeholder="Select Date"
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
            />
          </Form.Item>
          <Form.Item
            label="Vendor Name"
            name="vendor"
            rules={[{ required: true, message: "Please Select Vendor" }]}
          >
            <Select
              labelInValue
              placeholder="Search Vendor"
              options={vendorOptions}
              allowClear
              style={{ width: "100%" }}
              filterOption={false}
              showSearch
              onSearch={(val) => getVendorOptions(val)}
            />
          </Form.Item>
          <Form.Item
            label="Unit"
            name="unit"
            rules={[{ required: true, message: "Please Select Unit" }]}
          >
            <Select
              placeholder="Select Unit"
              style={{ width: "100%" }}
              labelInValue
              allowClear
              options={unitOptions}
              listHeight={224}
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <AddInOption funcType={"unit"} />
                </>
              )}
            />
          </Form.Item>
          <Form.Item
            label="Number of stock"
            name="numberOfStock"
            rules={[
              {
                pattern: new RegExp(/^[0-9]/i),
                message: "Please input Number only!",
              },
              { required: true, message: "Enter No. of Stock" },
            ]}
          >
            <Input placeholder="Enter No. of Quantity Purchased" />
          </Form.Item>
          {/* <Col lg={{ span: 12 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Issue Date"
                  name="issueDate"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    placeholder="Select Date"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col> */}
          <Form.Item label="Invoice Number" name="invoiceNumber">
            <Input placeholder="Enter Invoice Number" />
          </Form.Item>
          <Form.Item
            label="Total Price"
            name="totalPrice"
            rules={[
              // {
              //   required: true,
              //   message: "Please enter rate",
              // },
              {
                pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
                message: "Please enter number upto two decimal places only!",
              },
            ]}
          >
            <Input placeholder="Enter Total Price" />
          </Form.Item>
          <Form.Item label="Expiry Date" name="expiryDate">
            <DatePicker
              placeholder="Select Date"
              format={"DD/MM/YYYY"}
              // disabledDate={disabledDate}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea
              placeholder="Description"
              autoSize={{ minRows: 3, maxRows: 14 }}
            />
          </Form.Item>
        </Form>

        {/* <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          className="InvTab"
        /> */}
      </Drawer>
    </>
  );
};

export default AddProduct;
