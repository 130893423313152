import { TabsProps, Tabs } from "antd";
import React, { useContext } from "react";
import "./InventoryMainDashboard.css";
import Dashboard from "../../Components/InventoryDashboard/DashboardTab/Dashboard";
import RecentUpdate from "../../Components/InventoryDashboard/RecentUpdatesTab/RecentUpdate";
import Approval from "../../Components/InventoryDashboard/ApprovalTab/Approval";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import secureLocalStorage from "react-secure-storage";
import {
  getSecureSessionItem,
  loginUserSessionKey,
  userRightsSessionKey,
} from "../../Context/ApplicationContext/ApplicationState";
type Props = {};

const InventoryMainDashboard = (props: Props) => {
  const loginUser: any = getSecureSessionItem(loginUserSessionKey);
  const loginUserRights: any = getSecureSessionItem(userRightsSessionKey);
  const items: any = [
    loginUserRights?.dashboard?.showDashboardTab1
      ? {
        key: "Dashboard",
        label: <span>Dashboard</span>,
        style: { height: "100%" },
        children: <Dashboard />,
      }
      : null,
    // loginUserRights?.dashboard?.showDashboardTab2
    //   ? {
    //       key: "RecentUpdates",
    //       label: <span>Recent Updates</span>,
    //       children: <RecentUpdate />,
    //     }
    //   : null,
    loginUserRights?.dashboard?.showDashboardTab3
      ? {
        key: "Approvals",
        label: <span>Approvals</span>,
        children: <div style={{ marginTop: 53 }}><Approval /></div>,
      }
      : null,
  ];

  const renderTabBar: TabsProps["renderTabBar"] = (props, DefaultTabBar) => (
    <div
      style={{ zIndex: 1005, position: "fixed", top: "50px", width: "100%" }}
    >
      <DefaultTabBar
        {...props}
        className="invTab"
        style={{ backgroundColor: "" }}
      />
    </div>
  );

  return (
    <>
      <Tabs
        size="large"
        type="line"
        defaultActiveKey="Dashboard"
        items={items}
        renderTabBar={renderTabBar}
        // className="invTab"
        style={{ zIndex: "-1" }}
      />
    </>
  );
};

export default InventoryMainDashboard;
