import React from "react";
import DigitalProductList from "../../../Components/ParentCategory-B/DigitalProductList";
import { Category } from "../../../Services/ProductService";
import PaidSoftwareList from "../../../Components/PaidSoftware/PaidSoftwareList";

type Props = {};

const PaidSoftware = (props: Props) => {
  return (
    <>
      {/* <DigitalProductList category={Category.PaidSoftware} /> */}
      <PaidSoftwareList />
    </>
  );
};

export default PaidSoftware;
