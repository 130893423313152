import { Select, Col, Row } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React, { useEffect } from 'react'
import "./CleaningDashboard.css"
import SeeAllImg from "../../../Assets/images/see_all.svg"
import { useNavigate } from 'react-router-dom';
type Props = {}

const CleaningDashboard = (props: Props) => {

  const navigate = useNavigate()


  // Main Table Components //
  const columns: ColumnsType<any> = [
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>CATEGORIES</span>
          </div>
        </div>
      ,
      dataIndex: 'categories',
      key: 'categories',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>IN STOCK</span>
          </div>
        </div>
      ,
      dataIndex: 'instock',
      key: 'instock',
      ellipsis: true,
      render: (inStock: string) => <span className='instockStyle'>{inStock}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>IN USE</span>
          </div>
        </div>
      ,
      dataIndex: 'inuse',
      key: 'inuse',
      ellipsis: true,
      render: (inUse: string) => <span className='inuseStyle'>{inUse}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>DISPOSED</span>
          </div>
        </div>
      ,
      dataIndex: 'disposed',
      key: 'disposed',
      ellipsis: true,
      render: (disposed: string) => <span className='disposedStyle'>{disposed}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>IN SERVICE</span>
          </div>
        </div>
      ,
      dataIndex: 'inservice',
      key: 'inservice',
      ellipsis: true,
      render: (inService: string) => <span className='inserviceStyle'>{inService}</span>
    },
  ];

  // Main Table Data //

  const MainTableData = [
    {
      id: "aaaaa1",
      categories: "Laptop",
      instock: "450",
      inuse: "50",
      disposed: "-",
      inservice: "-",
    },
    {
      id: "aaaaa2",
      categories: "Computer",
      instock: "780",
      inuse: "500",
      disposed: "5",
      inservice: "2",
    },
    {
      id: "aaaaa3",
      categories: "Monitor",
      instock: "800",
      inuse: "570",
      disposed: "5",
      inservice: "2",
    },
    {
      id: "aaaaa4",
      categories: "Mouse",
      instock: "1000",
      inuse: "550",
      disposed: "20",
      inservice: "-",
    },
    {
      id: "aaaaa5",
      categories: "Keyboard",
      instock: "1009",
      inuse: "550",
      disposed: "35",
      inservice: "3",
    },
  ]

  // Section 2 Low Extra Stock Column //

  const lowExtraStock: ColumnsType<any> = [
    {
      title:
        <div className=''>
          <div>
            <span>Category</span>
          </div>
        </div>
      ,
      dataIndex: 'category',
      key: 'category',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className=''>
          <div>
            <span>Low Stock</span>
          </div>
        </div>
      ,
      dataIndex: 'lowextraStock',
      key: 'lowextraStock',
      ellipsis: true,
      render: (lowextraStock: string) => <span className='instockStyle'>{lowextraStock}</span>
    },

  ];

  const lowStockData = [
    {
      key: "1",
      category: "Laptop",
      lowextraStock: 2
    },
    {
      key: "2",
      category: "Mouse",
      lowextraStock: 1
    },
    {
      key: "3",
      category: "Keyboard",
      lowextraStock: 1
    },
    {
      key: "4",
      category: "Monitor",
      lowextraStock: 1
    },
    {
      key: "5",
      category: "Total",
      lowextraStock: 5
    },

  ]

  // Top Purhase Stock Table Columns //
  const topPurhaseStock: ColumnsType<any> = [
    {
      title:
        <div className=''>
          <div>
            <span>Category</span>
          </div>
        </div>
      ,
      dataIndex: 'category',
      key: 'category',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className=''>
          <div>
            <span>Number of Products</span>
          </div>
        </div>
      ,
      dataIndex: 'lowextraStock',
      key: 'lowextraStock',
      ellipsis: true,
      render: (lowextraStock: string) => <span className='instockStyle'>{lowextraStock}</span>
    },

  ];
  const topPurchaseStockData = [
    {
      key: "1",
      category: "Laptop",
      lowextraStock: 2
    },
    {
      key: "2",
      category: "Mouse",
      lowextraStock: 1
    },
    {
      key: "3",
      category: "Keyboard",
      lowextraStock: 1
    },
    {
      key: "4",
      category: "Monitor",
      lowextraStock: 1
    },
    {
      key: "5",
      category: "Total",
      lowextraStock: 5
    },

  ]

  // Stock who're closed to expiry Column
  const closedToExpiryColumn: ColumnsType<any> = [
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>NAME</span>
          </div>
        </div>
      ,
      dataIndex: 'name',
      key: 'name',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>CATEGORY</span>
          </div>
        </div>
      ,
      dataIndex: 'categories',
      key: 'categories',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>SERIAL NUMBER</span>
          </div>
        </div>
      ,
      dataIndex: 'serialNo',
      key: 'serialNo',
      ellipsis: true,
      render: (inStock: string) => <span className='instockStyle'>{inStock}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>ASSIGN TO</span>
          </div>
        </div>
      ,
      dataIndex: 'assignTo',
      key: 'assignTo',
      ellipsis: true,
      render: (inUse: string) => <span className='inuseStyle'>{inUse}</span>
    },
    {
      title:
        <div className='headerColumnStyle'>
          <div>
            <span>WARRANTY</span>
          </div>
        </div>
      ,
      dataIndex: 'warranty',
      key: 'warranty',
      ellipsis: true,
      render: (disposed: string) => <span className='disposedStyle'>{disposed}</span>
    }
  ];

  const closedToExpiryData = [
    {
      id: "aaaaa1",
      name: "Asus Laptop 3500",
      categories: "Laptop",
      serialNo: "987456625520",
      assignTo: "Lalit Bohra",
      warranty: "24, Jun 2023",

    },
    {
      id: "aaaaa2",
      name: "HP Monitor",
      categories: "Monitor",
      serialNo: "987456625520",
      assignTo: "Lalit Bohra",
      warranty: "24, Jun 2023",

    },
    {
      id: "aaaaa3",
      name: "Dell Mouse",
      categories: "Mouse",
      serialNo: "987456625520",
      assignTo: "Lalit Bohra",
      warranty: "24, Jun 2023",

    },
    {
      id: "aaaaa4",
      name: "Logictech Keyboard",
      categories: "Keyboard",
      serialNo: "987456625520",
      assignTo: "Lalit Bohra",
      warranty: "24, Jun 2023",

    },
    {
      id: "aaaaa5",
      name: "HP Laptop",
      categories: "Laptop",
      serialNo: "987456625520",
      assignTo: "Lalit Bohra",
      warranty: "24, Jun 2023",

    },
  ]

  // Top Vendor Column
  const topVendorColumn: ColumnsType<any> = [
    {
      title:
        <div className=''>
          <div>
            <span>Vendors</span>
          </div>
        </div>
      ,
      dataIndex: 'name',
      key: 'name',

      render: (categoriesName: string) => <span className='categoriesStyle'>{categoriesName}</span>
    },
    {
      title:
        <div className=''>
          <div>
            <span>Purchase Orders</span>
          </div>
        </div>
      ,
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      render: (lowextraStock: string) => <span className='instockStyle'>{lowextraStock}</span>
    },

  ];

  const topVendorData = [
    {
      id: "aaaaa1",
      name: "Rathi Kirana",
      amount: 50000
    },
    {
      id: "aaaaa2",
      name: "Satyam Computer",
      amount: 40000
    },
    {
      id: "aaaaa3",
      name: "Balaji Cleaners",
      amount: 30000
    },
    {
      id: "aaaaa4",
      name: "Apple",
      amount: 10000
    },

  ]
  useEffect(() => {


  }, [])
  return (
    <>

      <div style={{ marginTop: "-16px" }}>
        <div className='headingStyle'><span>Cleaning Dashboard</span></div>
        {/* Section 1 */}
        {/* Main Category Table  1 */}
        <div>
          {
            window.innerWidth < 600 ?
              <Table size="small" className='invMainTable' columns={columns} dataSource={MainTableData} pagination={false}

                footer={() => (
                  <div className='invMainTableParentDivStyle'>
                    <div className='invMainTableFooter' onClick={() => navigate("/inventory/report")}>
                      <span>See All</span>
                    </div>
                    <div className='invMainTableFooterImgStyle' onClick={() => navigate("/inventory/report")}>
                      <img src={SeeAllImg} alt="See All" />
                    </div>
                  </div>
                )}
              />
              :
              <Table className='invMainTable' columns={columns} dataSource={MainTableData} pagination={false}

                footer={() => (
                  <div className='invMainTableParentDivStyle'>
                    <div className='invMainTableFooter' onClick={() => navigate("/inventory/report")}>
                      <span>See All</span>
                    </div>
                    <div className='invMainTableFooterImgStyle' onClick={() => navigate("/inventory/report")}>
                      <img src={SeeAllImg} alt="See All" />
                    </div>
                  </div>
                )}
              />
          }

        </div>
        <div className='invetoryReportStyle' onClick={() => navigate("/inventory/report")}>
          <span>Inventory report</span>
        </div>
        {/* Section 1 */}

        {/* Section 2 */}

        <div>

          <Row className='section2RowStye' gutter={[24, 10]}>
            {/* Product Details */}
            <Col xl={7} lg={12}>
              <div >
                <div className='productDetailsHeadingStyle'>
                  <span>Product Details</span>
                </div>
                <div className='productDetailsDateTimeStyle'>
                  <span>Last updated : 4:04 pm, 16 Feb 2023</span>
                </div>
                <div className='productDetailsDivStyle'>
                  <div className='loextoDivStyle loextoSelected'>
                    <div className='productloextoTextStyle'>
                      <span>Low Stock:</span>
                    </div>
                    <div className='productloextoDataStyle'>
                      <span>5</span>
                    </div>
                  </div>
                  <div className='loextoDivStyle'>
                    <div className='productloextoTextStyle'>
                      <span>Extra Stock:</span>
                    </div>
                    <div className='productloextoDataStyle'>
                      <span>18</span>
                    </div>
                  </div>
                  <div className='loextoDivStyle '>
                    <div className='productloextoTextStyle'>
                      <span>Total Stock:</span>
                    </div>
                    <div className='productloextoDataStyle'>
                      <span>100</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {/* Low Stock */}
            <Col xl={7} lg={12}>
              <div className='loexTableDivStyle'>
                <Table className='invMainTable' size='small' dataSource={lowStockData} columns={lowExtraStock} pagination={false} />
                <div className='loexLinkStyle'>
                  <span>Low Stock report</span>
                </div>
              </div>
            </Col>
            {/* Top Purchase Stock */}
            <Col xl={10}>
              <div className='topPurchaseOuterDivStyle'>
                <div className='topPurchaseStockInnerDivStyle'>
                  <div>
                    <div className='topPurchaseStockHeading'>
                      <span>Top Purchase Stock</span>
                    </div>
                    <div className='topproductStockDateTimeStyle'>
                      <span>Last updated : 4:04 pm, 16 Feb 2023</span>
                    </div>
                  </div>
                  <div>
                    <Select
                      className='invDashboardSelect'
                      size='large'
                      defaultValue={"Monthly"}
                      options={[
                        { label: "Monthly", value: "Monthly" },
                        { label: "Yearly", value: "Yearly" }

                      ]} />
                  </div>
                </div>
                <div className='numberofProductTableDivStyle' >
                  <Table className='invMainTable' dataSource={topPurchaseStockData} columns={topPurhaseStock} pagination={false} />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Section 2 */}

        {/* Section 3 */}
        <div>
          <Row gutter={[24, 16]}>
            <Col xl={24}>
              <div>
                <div className='section3InnerDivStyle'>
                  <div>
                    <div>
                      <div className='topPurchaseStockHeading'>
                        <span>Stock who're closed to expiry </span>
                      </div>
                      <div className='topproductStockDateTimeStyle'>
                        <span>Last updated : 4:04 pm, 16 Feb 2023</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Select
                      className='invDashboardSelect'
                      size='large'
                      defaultValue={"Monthly"}
                      options={[
                        { label: "Monthly", value: "Monthly" },
                        { label: "Yearly", value: "Yearly" }

                      ]} />
                  </div>
                </div>
                <div>
                  <Table className='invMainTable' dataSource={closedToExpiryData} columns={closedToExpiryColumn} pagination={false}
                    footer={() => (
                      <div className='invMainTableParentDivStyle'>
                        <div className='invMainTableFooter' onClick={() => navigate("/inventory/report")}>
                          <span>See All</span>
                        </div>
                        <div className='invMainTableFooterImgStyle' onClick={() => navigate("/inventory/report")}>
                          <img src={SeeAllImg} alt="See All" />
                        </div>
                      </div>
                    )}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>

        {/* Section 4 */}
        <div>
          <Row gutter={[24, 16]} className='row4Style'>
            <Col xl={8}>
              <div>
                <div className='topPurchaseStockInnerDivStyle'>
                  <div>
                    <div className='topPurchaseStockHeading'>
                      <span>Top Vendors</span>
                    </div>
                    <div className='topproductStockDateTimeStyle'>
                      <span>Last updated : 4:04 pm, 16 Feb 2023</span>
                    </div>
                  </div>
                  <div>
                    <Select
                      className='invDashboardSelect'
                      size='large'
                      defaultValue={"Monthly"}
                      options={[
                        { label: "Monthly", value: "Monthly" },
                        { label: "Yearly", value: "Yearly" }

                      ]} />
                  </div>
                </div>
                <div>
                  <Table className='invMainTable' columns={topVendorColumn} dataSource={topVendorData} pagination={false} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CleaningDashboard