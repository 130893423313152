import React from "react";
import ProductList from "../../../Components/ParentCategory-A/ProductList";
import { Category } from "../../../Services/ProductService";

type Props = {};

const Cleaning = (props: Props) => {
  return (
    <div>
      {/* <CleaningTableComp /> */}
      <ProductList category={Category.Cleaning } />
    </div>
  );
};

export default Cleaning;
