/* eslint-disable */
import { useContext, useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { pageSizeOption } from "../../CapsitechInventoryComponents/Context/ApplicationContext/ApplicationState";
import "../../CapsitechInventoryComponents/Common/Common.css";
import MastersService from "../Services/MastersService";
import { dayjs } from "../../Utilities/dayjs";
import MasterHistory from "../../CapsitechInventoryComponents/Pages/Masters/MasterHistory";
import { EntitiesEnum } from "../../CapsitechInventoryComponents/Services/LogService";
import MasterService, {
  Masters,
} from "../../CapsitechInventoryComponents/Services/MasterService";

const { Search } = Input;
type Props = {};

/********* Materials(Category) *********/

const Materials = (props: Props) => {
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [materialForm] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [show, setShow] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [editData, setEditData] = useState<any>({});
  const [listParams, setListParams] = useState<any>({
    search: "",
    start: 0,
    length: 15,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getMaterials = (listParams: any) => {
    setLoading(true);
    MastersService.getMaterialTypeOptions(
      listParams.search,
      listParams.start,
      listParams.length
    ).then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r?.id,
            name: r?.name,
            value: r?.id,
            recordStatus: r?.recordStatus,
            isEditAllowed: r?.isEditAllowed,
            createdBy: dayjs(r?.createdBy.date).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setLoading(false);
    setShowDrawer("");
    materialForm.resetFields();
  };
  // Message on Submit

  useEffect(() => {}, [exist]);

  useEffect(() => {
    setLoading(true);
    inputRef.current?.focus();
    getMaterials(listParams);
  }, [shouldRefresh, listParams]);

  const subMaterial = (saveTog: boolean) => {
    setDisableBtn(true);
    materialForm.validateFields().then((values) => {
      if (values) {
        MastersService.postMaterialType(values).then((res) => {
          if (res?.status === true) {
            if (saveTog === true) {
              info("submitted");
              setDisableBtn(false);
              // getMaterials();
              onDismiss();
            } else {
              materialForm.resetFields();
              setDisableBtn(false);
              setShouldRefresh((x) => !x);
            }
          } else {
            setDisableBtn(false);
            errorMsg(res?.message);
          }
          // if (res?.status) {
          //   info("Added Successfully");
          //   onDismiss();
          //   materialForm.resetFields();
          // } else {
          //   errorMsg(res?.message);
          // }
        });
      }
    });
  };

  const searchMaterial = (e: any) => {
    setListParams({
      search: e.trim(),
      start: 0,
      length: 15,
    });
  };

  const editRowData = async (item: any) => {
    MastersService.getMaterialType(item?.id).then(async (res: any) => {
      materialForm.setFieldsValue(res);
      setShow(true);
      setUpdateToggle(false);
      materialForm.setFieldsValue({ ...item });
      setEditData({ ...res });
    });
  };

  const updateData = () => {
    setDisableBtn(true);
    let values = materialForm.getFieldsValue(true);
    let changesInFields1 = materialForm.isFieldsTouched();

    // if (fieldChange) {
    materialForm.validateFields().then(async () => {
      let val = {
        id: values?.id,
        name: values?.name.toString(),
      };
      await MastersService.postMaterialType(val).then((res: any) => {
        if (res?.result) {
          setDisableBtn(false);
          onDismiss();
          setUpdateToggle(true);
          info("Updated Successfully");
          // getMaterials();
          setSelectedRowKeys("");
        } else {
          setDisableBtn(false);
          errorMsg(res?.message);
        }
      });
    });
    // } else {
    //   setShow(false);
    // }
  };

  const deleteList = (record: any) => {
    // MastersService.getExistMaterial(Masters.Material, record?.id).then((res) => {
    //   if (res === true) {
    //     warning();
    //   } else {

    MastersService.getMaterialType(record?.id).then((res: any) => {
      if (res?.isEditAllowed) {
        MasterService.isExist(Masters.ConstructionCategory, record?.id).then(
          (res) => {
            if (res === true) {
              messageApi.open({
                type: "error",
                content: "Category is already used in purchase  ",
              });
            } else {
              MastersService.deleteMaterialType(record?.id).then((res) => {
                messageApi.success("Category is Deleted Successfully");
                onDismiss();
              });
            }
          }
        );
      } else {
        messageApi.open({
          type: "warning",
          content: "Category is added by admin Can't be deleted ",
        });
      }
    });
  };
  // });
  // };
  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Category Is Assigned  Can't be deleted",
    });
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const columns: ColumnsType<any> = [
    {
      title: (
        <>
          {/* <span className="searchDivStyle ms-2">CATEGORY </span> */}

          <Search
            placeholder="Category"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => searchMaterial(e)}
            size="small"
            bordered={false}
          />
        </>
      ),
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span
          className="font13 ps-2"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
      width: 240,
    },
    {
      title: (
        <>
          <div className="searchDivStyle">Date</div>
        </>
      ),

      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
            size="small"
          >
            Edit
          </Button>
          {
            // <Button icon={<DeleteOutlined />} type="text" danger onClick={() => {
            //   deleteList(record.id)
            // }}>
            //   Delete
            // </Button>
            <Popconfirm
              title="Are you sure?"
              onConfirm={() => deleteList(record)}
              okText="Yes"
              cancelText="No"
              placement="top"
              icon={<DeleteOutlined style={{ color: "red" }} />}
            >
              <Button icon={<DeleteOutlined />} type="text" danger size="small">
                Delete
              </Button>
            </Popconfirm>
          }
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              className="cursorPointer"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
              size="small"
            >
              History
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Category"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            materialForm.resetFields();
            setUpdateToggle(true);
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          title={
            updateToggle === true ? (
              "Add Category"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Category
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Category"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>  */}
              </>
            )
          }
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // onOk={() => {
          //   subMaterial();
          // }}
          footer={
            <>
              {updateToggle === true ? (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      materialForm.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    disabled={disableBtn}
                    onClick={() => {
                      subMaterial(true);
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    disabled={disableBtn}
                    onClick={() => {
                      subMaterial(false);
                    }}
                  >
                    Save and New
                  </Button>
                </div>
              ) : (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      materialForm.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed || disableBtn}
                  >
                    Update
                  </Button>
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="MaterialBasic"
            form={materialForm}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Enter Category"
              name="name"
              rules={[
                {
                  pattern: /^[^\s\d\W][^\s\W]*([\s][^\s\W]+)*$/,
                  message:
                    "Spaces, digits, and special symbols are not allowed at beginning or end of the input.",
                },
                {
                  required: true,
                  message: "Please enter value.",
                },
                {
                  whitespace: true,
                },
              ]}
            >
              <Input ref={inputRef} />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>

        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Material}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Materials;
