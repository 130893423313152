/* eslint-disable eqeqeq */
import React, { useState, useContext, useRef, useEffect } from "react";
import TextArea from "antd/es/input/TextArea";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  // Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  Row,
  Select,
  Space,
  Spin,
  // Tabs,
  // TabsProps,
  message,
} from "antd";
import {
  // ArrowLeftOutlined,
  // CloseOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import "../../CapsitechInventoryComponents/Common/Common.css";
import ApplicationContext from "../../CapsitechInventoryComponents/Context/ApplicationContext/CreateApplicationContext";
import PurchasesService from "../Services/PurchasesService";
import MastersService from "../Services/MastersService";
import { dayjs } from "../../Utilities/dayjs";
import { number } from "echarts";
import VendorService from "../../CapsitechInventoryComponents/Services/VendorService";
import Meta from "antd/es/card/Meta";
import AddVendor from "../../CapsitechInventoryComponents/Components/Vendor/AddVendor";
import MasterService from "../../CapsitechInventoryComponents/Services/MasterService";

const AddInOption = ({ funcType, selectedMaterial, setItemOpt }: any) => {
  const {
    getMaterialOptions,
    getMeasurementOption,
    getGstOptions,
    getCVendorOptions,
    getUnits,
  } = useContext(ApplicationContext);
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [status, setStatus] = useState<any>("success");
  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = async (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "materialType")
        await MastersService.postMaterialType({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Material is already exist");
          } else {
            getMaterialOptions();
            setName("");
            inputRef.current?.focus();
          }
        });
      else if (funcType === "item") {
        await MastersService.postItems({
          name: name,
          materialUsed: { ...selectedMaterial },
        }).then(async (r: any) => {
          if (r?.message) {
            message.error(r?.message);
          } else {

            await MastersService.getItemsOptions(
              "",
              "",
              selectedMaterial?.id,
              0,
              0,
              false
            ).then((res: any) => {
              if (res) {
                setItemOpt(
                  res?.items.map((itm: any) => ({
                    label: itm?.name,
                    value: itm?.id,
                  }))
                );
                getMaterialOptions()
              }
            });
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "measurement") {
        await MastersService.postMeasurement({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Measurement is already exist");
          } else {
            getMeasurementOption();
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "gst") {
        await MastersService.postGst({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("GST is already exist");
          } else {
            getGstOptions();
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "unit") {
        await MasterService.postUnit({ name: name }).then((r: any) => {
          if (r?.message) {
            message.error("Unit is already exist");
          } else {
            getUnits();
            setName("");
            inputRef.current?.focus();
          }
        });
      } else if (funcType === "vendor") {
        await VendorService.post({ name: name, vendorType: 1 }).then(
          (r: any) => {
            if (r?.message) {
              message.error("Vendor is already exist");
            } else {
              getCVendorOptions();
              setName("");
              inputRef.current?.focus();
            }
          }
        );
      }
    }
  };
  return (
    <>
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px" }}>
        <Input
          placeholder="Add"
          ref={inputRef}
          value={name}
          onChange={onNameChange}
          status={status}
          onPressEnter={addOption}
        />
        <Button
          size="small"
          type="text"
          icon={<PlusOutlined />}
          onClick={addOption}
        />
      </Space>
    </>
  );
};

const AddMultiple = (props: any) => {
  const {
    materialOptions,
    // itemOption,
    cVendorOption,
    measurementOption,
    gstOption,
    // getMaterialOptions,
    // getItemOption,
    // getMeasurementOption,
    getCVendorOptions,
    // getGstOptions,
    // getUnits,
    // unitOptions,
  } = useContext(ApplicationContext);

  const [itemOpt, setItemOpt] = useState<any>([]);
  // const [isChanges, setIsChanges] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<any>({
    id: "",
    name: "",
  });
  const [buttonDisable, setButtonDisable] = useState(false);
  const [formDisable, setFormDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("0");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [loadingOnSave, setLoadingOnSave] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const inputRef = React.useRef<any>(null);
  const selectRef = React.useRef<any>(null);
  const [isChange, setIsChange] = useState<any>(false);
  const [show, setShow] = useState("");
  const [removeValue, setRemoveValue] = useState(false);
  useEffect(() => {
    inputRef.current?.focus();
  }, [shouldRefresh]);

  const handleInputClick = () => {
    if (inputRef.current) {
      inputRef.current.select();
    }
  };
  // const resetForm = () => {
  //   form1.resetFields();
  //   form2.resetFields();
  //   setShouldRefresh(!shouldRefresh);
  // };
  const restore = () => {
    PurchasesService.restore(props?.id).then((res: any) => {
      if (res?.status) {
        messageApi.success("Restored successfully");
      } else {
        messageApi.error(res?.message);
      }
      props.onDismiss(true);
    });
  };
  const onSave = (onlySave: boolean) => {
    form1.validateFields().then(async (values: any) => {
      setLoadingOnSave(true);
      let val: any = {
        id: props?.id ?? "",
        ...values,
        // materialType: {
        //     id: values?.materialType?.value,
        //     name: values?.materialType?.label,
        // },
        // item: {
        //     id: values?.item?.value,
        //     name: values?.item?.label,
        // },
        vendor: {
          id: values?.vendor?.value,
          name: values?.vendor?.label,
        },
        // measurement: {
        //     id: values?.measurement?.value,
        //     name: values?.measurement?.label,
        // },
        // gst: {
        //     id: values?.gst?.value,
        //     name: values?.gst?.label,
        // },
        purchase: values?.purchase?.map((itm: any) => ({
          ...itm,
          gst: { id: itm?.gst?.value, name: itm?.gst?.label },
          item: { id: itm?.item?.value, name: itm?.item?.label }, // SubCategory
          materialType: {
            id: itm?.materialType?.value,
            name: itm?.materialType?.label,
          }, // Category
          measurement: {
            id: itm?.measurement?.value,
            name: itm?.measurement?.label,
          },
          unit: {
            id: itm?.unit?.value,
            name: itm?.unit?.label,
          },
          quantity: parseInt(itm?.quantity),
          quantityName: itm?.quantity,
        })),
      };

      let data: any = {
        ...val,
        purchase: val?.purchase?.filter(
          (x: any) =>
            x?.amount != 0 &&
            x?.totalAmount != 0 &&
            x?.rate != 0 &&
            x?.quantityName != 0 &&
            x?.item?.id != undefined &&
            x?.item?.name != undefined &&
            x?.materialType?.id != undefined &&
            x?.materialType?.name != undefined
        ),
      };
      setButtonDisable(true);
      await PurchasesService.postPurchases(data).then((res) => {
        if (res?.status) {
          messageApi.success(`Purchase Added Successfully`, 1);
          setTimeout(() => {
            setLoadingOnSave(false);
            props.onDismiss(true);
          }, 1000);
        } else {
          messageApi.error(res?.message);
          setLoadingOnSave(false);
        }
        setLoadingOnSave(false);
        setButtonDisable(false);
      });
    });
  };

  const valueStorage = (name: any) => {
    // let val = form1.getFieldValue(["purchase", name, "rate"]);
    // let stringDotValue = val?.toString();
    // console.log(
    //   "stringDotValue =========>",
    //   parseInt(stringDotValue?.split(".")[1])
    // );
    // console.log("rateValue", form1.getFieldValue(["purchase", name, "rate"]));
    // console.log( "type", typeof val);
    // let roundVAl = val - 34;
    // console.log(Math.fround(roundVAl), "round");
    // form1.setFieldValue(["purchase", name, "rate"], Math.round(val));
  };
  const calAmount = (name: any) => {
    form1.setFieldValue(
      ["purchase", name, "amount"],
      parseFloat(
        (
          parseFloat(form1.getFieldValue(["purchase", name, "quantity"])) *
          form1.getFieldValue(["purchase", name, "rate"])
        ).toFixed(4)
      )
    );
    form1.setFieldValue(
      ["purchase", name, "totalAmount"],
      parseFloat(
        (
          (parseFloat(form1.getFieldValue(["purchase", name, "gst"])?.label) /
            100) *
          form1.getFieldValue(["purchase", name, "amount"]) +
          form1.getFieldValue(["purchase", name, "amount"])
        ).toFixed(2)
      )
        ? parseFloat(
          (
            (parseFloat(
              form1.getFieldValue(["purchase", name, "gst"])?.label
            ) /
              100) *
            form1.getFieldValue(["purchase", name, "amount"]) +
            form1.getFieldValue(["purchase", name, "amount"])
          ).toFixed(2)
        )
        : form1.getFieldValue(["purchase", name, "amount"])
    );
    form1.setFieldValue(
      ["purchase", name, "gstAmount"],
      parseFloat(
        (
          (parseFloat(form1.getFieldValue(["purchase", name, "gst"])?.label) /
            100) *
          form1.getFieldValue(["purchase", name, "amount"])
        ).toFixed(2)
      )
        ? parseFloat(
          (
            (parseFloat(
              form1.getFieldValue(["purchase", name, "gst"])?.label
            ) /
              100) *
            form1.getFieldValue(["purchase", name, "amount"])
          ).toFixed(2)
        )
        : 0
    );
    getTotalSummary();
  };
  const getTotalSummary = () => {
    // setSummary({
    //     totalAmt: form1.getFieldValue(["purchase"])?.reduce((acc: any, curr: any) => {
    //         return (acc += Number(curr?.amount));
    //     }, 0)?.toFixed(2) || 0,
    //     totalNetAmt: form1.getFieldValue(["purchase"])?.reduce((acc: any, curr: any) => {
    //         return (acc += Number(curr?.totalAmount));
    //     }, 0)?.toFixed(2) || 0,
    //     totalGstAmt: form1.getFieldValue(["purchase"]).reduce((acc: any, curr: any) => {
    //         return (acc += Number(curr?.gstAmount));
    //     }, 0)?.toFixed(2) || 0,
    // })

    form1.setFieldValue(
      "amount",
      form1
        .getFieldValue(["purchase"])
        ?.reduce((acc: any, curr: any) => {
          return (acc += Number(curr?.amount));
        }, 0)
        ?.toFixed(2) || 0
    );
    form1.setFieldValue(
      "totalAmount",
      form1
        .getFieldValue(["purchase"])
        ?.reduce((acc: any, curr: any) => {
          return (acc += Number(curr?.totalAmount));
        }, 0)
        ?.toFixed(2) || 0
    );
    form1.setFieldValue(
      "gst",
      form1
        .getFieldValue(["purchase"])
        ?.reduce((acc: any, curr: any) => {
          return (acc += Number(curr?.gstAmount));
        }, 0)
        ?.toFixed(2) || 0
    );
    let parseVal = parseFloat(
      "0." + form1?.getFieldValue("totalAmount")?.toString()?.split(".")?.pop()
    );
    form1.setFieldValue(
      "netTotalAmount",
      Math.round(
        isNaN(form1.getFieldValue("totalAmount"))
          ? "0"
          : form1.getFieldValue("totalAmount")
      )
    );
    form1.setFieldValue(
      "roundOff",
      (parseVal >= 0.5 ? (1 - parseVal).toFixed(2) : parseVal) ?? 0.0
    );
    setIsChange(!isChange);
  };
  useEffect(() => {
    if (props?.id) {
      setLoading(true);
      PurchasesService.getPurchases(props?.id).then((res: any) => {
        if (res) {
          // res?.purchase?.push({
          //   quantityName: 0,
          //   totalAmount: 0,
          //   amount: 0,
          //   rate: 0,
          //   gstAmount: 0,
          //   description: "",
          // });

          setFormDisable(res?.recordStatus == 3 ? true : false);
          form1.setFieldsValue({
            ...res,
            billingDate: dayjs(res?.billingDate),
            vendor: { label: res?.vendor?.name, value: res?.vendor?.id },
            purchase: res?.purchase?.map((itm: any) => ({
              id: itm?.id,
              item: { label: itm?.item?.name, value: itm?.item?.id },
              materialType: {
                label: itm?.materialType?.name,
                value: itm?.materialType?.id,
              },
              measurement: {
                label: itm?.measurement?.name,
                value: itm?.measurement?.id,
              },
              gst: { label: itm?.gst?.name, value: itm?.gst?.id },
              description: itm?.description,
              totalAmount: itm?.totalAmount,
              gstAmount: itm?.gstAmount,
              amount: itm?.amount,
              rate: itm?.rate,
              quantity: itm?.quantityName,
            })),
          });
          setLoading(false);
          getTotalSummary();
        }
      });
    }
  }, [props?.id, shouldRefresh]);
  // console.log("dis", dis);

  // const addNew = (
  //   currentIndex: number,
  //   position: number,
  //   length: number,
  //   add: any
  // ) => {
  //   console.log(currentIndex);
  //   console.log(position);
  //   console.log(length);
  //   console.log(add);
  //   if (currentIndex === length) {
  //     add({
  //       quantity: 0,
  //       totalAmount: 0,
  //       amount: 0,
  //       rate: 0,
  //       gstAmount: 0,
  //       description: "",
  //     });
  //   }
  // };
  // const setFocus = (f: any) => {
  //   let x = form1.scrollToField("purchase");
  // };

  return (
    <>
      {loading ? (
        <Spin
          style={{ position: "absolute", top: "50%", left: "45%" }}
          spinning={true}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      ) : (
        <>
          <div>
            {contextHolder}
            <Form
              form={form1}
              disabled={formDisable}
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 34 }}
              colon
              layout="vertical"
              initialValues={{
                remember: true,
                purchase: [
                  {
                    quantity: 0,
                    totalAmount: 0,
                    amount: 0,
                    rate: 0,
                    gstAmount: 0,
                    description: "",
                  },
                ],
                netTotal: 0.0,
                netGstTotal: 0.0,
                netGrandTotal: 0.0,
              }}
              autoComplete="off"
              // size="middle"
              requiredMark
              className="InvAddForm"
            >
              <Row gutter={[24, 18]}>
                <Col lg={5} sm={8}>
                  <Form.Item
                    label="Invoice No."
                    name="billingNo"
                    rules={[
                      { required: true, message: "Enter Invoice No." },
                      //   {
                      //     pattern: new RegExp(/^(?=.{1,10}$)[a-zA-Z0-9\-/]*$/i),
                      //     message: "Invalid input value.",
                      //   },
                    ]}
                  >
                    <Input
                      placeholder="Enter Invoice Number"
                      ref={inputRef}
                      onClick={() => handleInputClick()}
                    />
                  </Form.Item>
                </Col>
                <Col lg={5} sm={8} xs={8}>
                  <Form.Item
                    initialValue={dayjs()}
                    label="Invoice Date"
                    name="billingDate"
                    rules={[
                      { required: true, message: "Please Select Invoice Date" },
                    ]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col lg={10} sm={8}>
                  <Form.Item
                    label="Vendor Name"
                    name="vendor"
                    rules={[
                      { required: true, message: "Please Select Vendor" },
                    ]}
                  >
                    <Select
                      labelInValue
                      placeholder="Search Vendor"
                      options={cVendorOption}
                      allowClear
                      listHeight={224}
                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{ width: "100%" }}
                      showSearch
                      // onSearch={(val) => getCVendorOptions(val)}

                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {/* <AddInOption funcType={"vendor"} /> */}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space
                            style={{ width: "100%", padding: "0 0px 4px" }}
                          >
                            <Button
                              // size="small"
                              type="primary"
                              ghost
                              style={{ border: "none" }}
                              icon={<PlusOutlined />}
                              onClick={() => {
                                setShow("AddVendor");
                              }}
                            >
                              New Vendor
                            </Button>
                          </Space>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* ------------------ */}
              <Form.List name={"purchase"}>
                {(fields, { add, remove }, { errors }) => (
                  <>
                    <div
                      style={{
                        height: props?.height,
                        // height: `calc(100vh - ${props?.height + 300}px)`,
                        overflow: "auto",
                      }}
                    >
                      <Row justify={"end"}>
                        <Button
                          ghost
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={() => {
                            add({
                              quantity: 0,
                              totalAmount: 0,
                              amount: 0,
                              rate: 0,
                              gstAmount: 0,
                              description: "",
                            });
                          }}
                        />
                      </Row>
                      {fields.map(({ key, name, ...restField }, index) => {
                        // setFocus(restField);
                        return (
                          <div key={index}>
                            <Row
                              gutter={[4, 0]}
                              style={{ marginBottom: "-12px" }}
                            >
                              <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Category" : ""}
                                  name={[name, "materialType"]}

                                // rules={[{ required: true, message: "Please Select Category" }]}
                                >
                                  <Select
                                    ref={selectRef}
                                    placeholder="Select Category"
                                    style={{ width: "100%" }}
                                    labelInValue
                                    allowClear
                                    showSearch
                                    listHeight={224}
                                    options={materialOptions}
                                    onChange={(_: any, op: any) => {
                                      setItemOpt(
                                        op?.items.map((itm: any) => ({
                                          label: itm?.name,
                                          value: itm?.id,
                                        }))
                                      );
                                    }}
                                    onSelect={(val: any) => {
                                      setSelectedMaterial({
                                        id: val?.value,
                                        name: val?.label,
                                      });
                                      form1.setFieldValue("item", []);
                                    }}
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <AddInOption
                                          funcType={"materialType"}
                                        />
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} sm={2} xs={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "SubCategory" : ""}
                                  name={[name, "item"]}
                                // required
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please Select SubCategory",
                                //   },
                                // ]}
                                >
                                  <Select
                                    placeholder="Select SubCategory"
                                    style={{ width: "100%" }}
                                    labelInValue
                                    allowClear
                                    showSearch
                                    listHeight={224}
                                    options={itemOpt}
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <AddInOption
                                          funcType={"item"}
                                          selectedMaterial={selectedMaterial}
                                          setItemOpt={setItemOpt}
                                        />
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Measurement" : ""}
                                  name={[name, "measurement"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please Select Measurement",
                                //   },
                                // ]}
                                // rules={[{ required: true, message: "Please Select Unit" }]}
                                >
                                  <Select
                                    placeholder="Select Measurement"
                                    style={{ width: "100%" }}
                                    labelInValue
                                    allowClear
                                    options={measurementOption}
                                    listHeight={224}
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <AddInOption funcType={"measurement"} />
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              {/* <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Unit" : ""}
                                  name={[name, "unit"]}
                                  // rules={[
                                  //   {
                                  //     required: true,
                                  //     message: "Please Select Measurement",
                                  //   },
                                  // ]}
                                  // rules={[{ required: true, message: "Please Select Unit" }]}
                                >
                                  <Select
                                    placeholder="Select Unit"
                                    style={{ width: "100%" }}
                                    labelInValue
                                    allowClear
                                    options={unitOptions}
                                    listHeight={224}
                                    showSearch
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <AddInOption funcType={"unit"} />
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </Col> */}
                              <Col span={2}>
                                {/* <Form.Item label="Qty"> */}
                                {/* <Space.Compact block> */}
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Qty" : ""}
                                  name={[name, "quantity"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please Enter Qty",
                                //   },
                                //   {
                                //     pattern: new RegExp(/^\d{1,4}$/i),
                                //     message: "Please input number only upto 4 digits!",
                                //   },
                                // ]}
                                // noStyle
                                >
                                  <Input
                                    onBlur={() => {
                                      // valueStorage(name);
                                      calAmount(name);
                                    }}
                                    ref={inputRef}
                                    onClick={() => handleInputClick()}
                                    // minLength={1}
                                    // maxLength={3}
                                    placeholder="Enter Qty"
                                    style={{
                                      width: "100%",
                                      // borderRadius: "0px",
                                    }}
                                  />
                                </Form.Item>
                                {/* <Form.Item
                                    {...restField}
                                    label={index === 0 ? " " : ""}
                                    name={[name, "qtString"]}
                                    // noStyle

                                    // rules={[
                                    //   {
                                    //     required: true,
                                    //     message: "Please Enter Qty",
                                    //   },
                                    //   {
                                    //     pattern: new RegExp(/^\d{1,4}$/i),
                                    //     message: "Please input number only upto 4 digits!",
                                    //   },
                                    // ]}
                                  >
                                    <Input
                                      style={
                                        {
                                          // width: "100%",
                                          // border: "none",
                                          // borderRadius: "1px",
                                        }
                                      }
                                    />
                                  </Form.Item> */}
                                {/* </Space.Compact> */}
                                {/* </Form.Item> */}
                              </Col>

                              <Col span={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Rate" : ""}
                                  name={[name, "rate"]}
                                  rules={[
                                    // {
                                    //   required: true,
                                    //   message: "Please enter rate",
                                    // },
                                    // {
                                    //   pattern: new RegExp(
                                    //     /^\d{1,10}(\.\d{1,2})?$/i
                                    //   ),
                                    //   message: "2 decimal places only!",

                                    // },
                                    {
                                      validator: (x: any, y: any) => {
                                        // let result: string = "";
                                        // let arr = y?.toString()?.split(".");
                                        // let afterPoint: string = "";
                                        // if (arr?.length >= 1) {
                                        //   afterPoint = arr[1];
                                        //   if (afterPoint?.length >= 3) {
                                        //     console.log(
                                        //       "afterPoint = ",
                                        //       arr[0](
                                        //         (afterPoint = afterPoint?.slice(
                                        //           0,
                                        //           2
                                        //         ))
                                        //       )
                                        //     );
                                        //     console.log(
                                        //       typeof (
                                        //         arr[0] +
                                        //         "." +
                                        //         afterPoint
                                        //       ),
                                        //       "After Point"
                                        //     );
                                        //   }
                                        // }
                                        const arr = y?.toString()?.split(".");
                                        let result = 0;
                                        if (arr?.length > 1) {
                                          result = parseFloat(
                                            arr[0] +
                                            "." +
                                            arr[1]?.substring(0, 2)
                                          );
                                        } else {
                                          result = parseInt(
                                            y?.toString()?.replace(".", "")
                                          );
                                        }
                                        form1?.setFieldValue(
                                          ["purchase", name, "rate"],
                                          result
                                        );
                                        // form1?.setFieldValue(
                                        //   ["purchase", name, "rate"],
                                        //   parseFloat(y?.toString()?.slice(0, 4))
                                        // );
                                        // form1?.setFieldValue(
                                        //   ["purchase", name, "rate"],
                                        //   parseFloat(before + "." + after)
                                        // );
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <InputNumber
                                    ref={inputRef}
                                    onClick={() => handleInputClick()}
                                    type="number"
                                    onBlur={() => {
                                      // valueStorage(name);
                                      calAmount(name);
                                    }}
                                    // onBlur={() => valueStorage(name)}
                                    aria-current
                                    minLength={1}
                                    // maxLength={3}
                                    controls={false}
                                    placeholder="Enter Rate"
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Amount" : ""}
                                  name={[name, "amount"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Please enter rate",
                                //   },
                                //   {
                                //     pattern: new RegExp(/^\d{1,8}(\.\d{1,2})?$/i),
                                //     message: "Please enter number upto two decimal places only!",
                                //   },
                                // ]}
                                >
                                  <InputNumber
                                    readOnly
                                    onChange={() => calAmount(name)}
                                    type="number"
                                    controls={false}
                                    placeholder="Enter Amount"
                                    maxLength={12}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "GST" : ""}
                                  name={[name, "gst"]}
                                // rules={[
                                //   {
                                //     required: true,
                                //     message: "Select GST",
                                //   },
                                // ]}
                                >
                                  <Select
                                    onSelect={() => calAmount(name)}
                                    placeholder="Select GST"
                                    style={{ width: "100%" }}
                                    labelInValue
                                    // allowClear
                                    options={gstOption}
                                    listHeight={224}
                                    showSearch
                                    // onClear={() => {
                                    //     form1.setFieldValue(
                                    //         ["purchase", name, "gstAmount"],
                                    //         0
                                    //     );
                                    // }}
                                    filterOption={(input, option) =>
                                      (option?.label?.toString() ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <AddInOption funcType={"gst"} />
                                      </>
                                    )}
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={2} sm={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "GST Amount" : ""}
                                  name={[name, "gstAmount"]}
                                >
                                  <InputNumber
                                    type="number"
                                    controls={false}
                                    readOnly
                                    style={{ width: "100%" }}
                                    placeholder="Gst Amount"
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={2}>
                                <Form.Item
                                  {...restField}
                                  label={index === 0 ? "Total Amount" : ""}
                                  name={[name, "totalAmount"]}
                                >
                                  <InputNumber
                                    type="number"
                                    controls={false}
                                    readOnly
                                    style={{ width: "100%" }}
                                    placeholder="Total Amount"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={6}>
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      marginRight: "6px",
                                    }}
                                  >
                                    <Form.Item
                                      {...restField}
                                      label={index === 0 ? "Description" : ""}
                                      name={[name, "description"]}
                                    >
                                      <TextArea
                                        placeholder="Description"
                                        autoSize={{ minRows: 1, maxRows: 6 }}
                                      // onBlur={() => {

                                      //   addNew(
                                      //     index,
                                      //     10,
                                      //     fields?.length - 1,
                                      //     add
                                      //   );
                                      // }}
                                      />
                                      {/* <InputNumber placeholder="Description" /> */}
                                    </Form.Item>
                                  </div>
                                  <div>
                                    {fields.length === 1 && index === 0 ? (
                                      <>
                                        {/* <Button
                                    style={{ marginTop: 32 }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                      add({
                                        quantity: 0,
                                        totalAmount: 0,
                                        amount: 0,
                                        rate: 0,
                                        gstAmount: 0,
                                        description: "",
                                      });
                                      getTotalSummary();
                                    }}
                                    ghost
                                    icon={<PlusOutlined />}
                                  ></Button> */}
                                      </>
                                    ) : index + 1 === fields?.length ? (
                                      <>
                                        {/* <Col md={1} xl={1} sm={12}> */}
                                        <Space align="baseline">
                                          {/* <Button
                                      //   style={{ marginTop: 3 }}
                                      type="primary"
                                      size="small"
                                      onClick={() => {
                                        add({
                                          quantity: 0,
                                          totalAmount: 0,
                                          amount: 0,
                                          rate: 0,
                                          gstAmount: 0,
                                          description: "",
                                        });
                                        getTotalSummary();
                                      }}
                                      ghost
                                      icon={<PlusOutlined />}
                                    ></Button> */}
                                          {/* </Col> */}
                                          {/* <Col md={1} xl={1} sm={12}> */}
                                          <Button
                                            style={{ marginTop: 4 }}
                                            danger
                                            size="small"
                                            onClick={() => {
                                              remove(name);
                                              getTotalSummary();
                                            }}
                                            icon={<DeleteOutlined />}
                                          />
                                        </Space>
                                        {/* </Col> */}
                                      </>
                                    ) : (
                                      <>
                                        <Button
                                          style={{
                                            marginTop: index === 0 ? 35 : 4,
                                          }}
                                          danger
                                          size="small"
                                          onClick={() => {
                                            remove(name);
                                            getTotalSummary();
                                          }}
                                          icon={<DeleteOutlined />}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </Form.List>

              {/* ++++++++++++++++ */}
              <Divider />
              <Row justify={"end"}>
                <Card title="Summary" size="small" style={{ width: "400px" }}>
                  <Card.Grid style={{ width: "100%" }}>
                    <Row gutter={[0, 0]}>
                      <Col span={12}>Amount</Col>
                      <Col span={12}>
                        <Form.Item name="amount" style={{ margin: "0px" }}>
                          {/* <Meta title={`₹ ${summary?.totalAmt} `} description="Grand Total Amount" /> */}
                          {/* <Meta
                            title={`₹ ${form1.getFieldValue("netTotal") ?? 0} `}
                            // description="Grand Total Amount"
                          /> */}
                          <Meta
                            title={`₹ ${isNaN(form1.getFieldValue("amount"))
                              ? 0
                              : form1.getFieldValue("amount")
                              }`}
                          // description="Grand Total Net Amount"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Grid>

                  <Card.Grid style={{ width: "100%" }}>
                    <Row>
                      <Col span={12}>GST</Col>
                      <Col span={12}>
                        <Form.Item name="gst" style={{ margin: "0px" }}>
                          {/* <Meta title={`₹ ${summary?.totalAmt} `} description="Grand Total Amount" /> */}
                          {/* <Meta
                            title={`₹ ${
                              form1.getFieldValue("netGstTotal") ?? 0
                            }`}
                            // description="Grand Total GST Amount"
                          /> */}
                          <Meta
                            title={`₹ ${isNaN(form1.getFieldValue("gst"))
                              ? "0"
                              : form1.getFieldValue("gst")
                              }`}
                          // description="Grand Total Net Amount"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Grid>
                  <Card.Grid style={{ width: "100%" }}>
                    <Row>
                      <Col span={12}>Total Amount</Col>
                      <Col span={12}>
                        <Form.Item name="totalAmount" style={{ margin: "0px" }}>
                          <Meta
                            title={`₹ ${isNaN(form1.getFieldValue("totalAmount"))
                              ? "0"
                              : form1.getFieldValue("totalAmount")
                              }`}
                          // description="Grand Total Net Amount"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Grid>
                  <Card.Grid style={{ width: "100%" }}>
                    <Row>
                      <Col span={12}>Round-off</Col>
                      <Col span={12}>
                        <Form.Item name="roundOff" style={{ margin: "0px" }}>
                          <Meta
                            title={
                              `₹ ${Math.round(
                                form1.getFieldValue("totalAmount")
                              ) <= form1.getFieldValue("totalAmount")
                                ? `-`
                                : `+`
                              }` +
                              (form1?.getFieldValue("roundOff")
                                ? form1?.getFieldValue("roundOff")
                                : " 0.0")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Grid>
                  <Card.Grid style={{ width: "100%" }}>
                    <Row>
                      <Col span={12}>Net Total Amount</Col>
                      <Col span={12}>
                        <Form.Item
                          name="netTotalAmount"
                          style={{ margin: "0px" }}
                        >
                          <Meta
                            title={`₹ ${isNaN(form1.getFieldValue("netTotalAmount"))
                              ? "0"
                              : form1.getFieldValue("netTotalAmount")
                              }`}
                          // description="Grand Total Net Amount"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card.Grid>
                </Card>
              </Row>
            </Form>
            <Row justify={"end"} gutter={[24, 16]} style={{ marginTop: 10 }}>
              <Col>
                {formDisable == false ? (
                  <Button
                    htmlType="submit"
                    size="middle"
                    ghost
                    loading={loadingOnSave}
                    type="primary"
                    // className="Inv-SaveBtn"
                    onClick={() => onSave(true)}
                  // disabled={buttonDisable}
                  >
                    Save
                  </Button>
                ) : (
                  <></>
                  // <Button
                  //   htmlType="submit"
                  //   size="middle"
                  //   ghost
                  //   loading={loadingOnSave}
                  //   type="default"
                  //   style={{ color: "green", border: "1px solid green" }}
                  //   // className="Inv-SaveBtn"
                  //   onClick={() => restore()}
                  //   // disabled={buttonDisable}
                  // >
                  //   Restore
                  // </Button>
                )}
              </Col>
              <Col>
                <Button
                  size="middle"
                  htmlType="reset"
                  danger
                  onClick={() => {
                    props?.onDismiss();
                  }}
                // className="Inv-SaveAndNewBtn"
                // onClick={() => onSave(false)}
                // disabled={buttonDisable}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div>
          {show === "AddVendor" && (
            <AddVendor
              onDismiss={(rec: boolean) => {
                getCVendorOptions();
                setShow("");
              }}
              setShouldRefresh={() => { }}
            />
          )}
        </>
      )}
    </>
  );
};

export default AddMultiple;
