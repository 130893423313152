import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import "../../../Common/Common.css";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import MasterService from "../../../Services/MasterService";
import AttendanceService from "../../../Services/AttendanceService";
const { Option } = Select;
interface IProps {
  openDrawer: boolean;
  drawerClose: Function;
  setTrigger: Function;
}
const AddEditAttendance = ({ openDrawer, drawerClose, setTrigger }: IProps) => {
  const columns: ColumnsType<any> = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: "70%",
      render: (text: any, record: any) => (
        <div>
          <div className=" text-dark font13" title={record?.name?.first}>
            {record?.name?.first}
          </div>
          <div
            style={{
              fontSize: "9px",
              fontWeight: "900",
              color: "#4096ff",
            }}
            title={record?.email}
          >
            {record?.email}
          </div>
        </div>
      ),
      // render: (value: any, record: any, index: number) => (
      //   <span
      //     onClick={() => {
      //       setModelOpen(true);
      //       setData(record);
      //       // setHolidayId(record?.id);
      //       // setOpenDrawer(true);
      //     }}
      //     style={{ cursor: "pointer", color: "#4096FF" }}
      //   >
      //     {value}
      //   </span>
      // ),
      // render: (value, record, index) => {
      //   return (
      //     <>
      //       <Button
      //         onClick={() => {
      //         }}
      //       >
      //         {value}
      //       </Button>
      //     </>
      //   );
      // },
    },
    {
      key: "attendance",
      title: "Attendance",
      dataIndex: "attendance",
      width: "30%",
      render: (_, record) => (
        <div>
          <Tag
            color={record?.status === 0 ? "error" : "success"}
            style={{
              fontWeight: 500,
              fontSize: 12,
              padding: "0px 25px",
            }}
          >
            {record?.status === 0 ? "Absent" : "Present"}
          </Tag>
        </div>
      ),
    },
  ];
  const [form] = useForm();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });

  const [drawerLoader, setIsDrawerLoader] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const [data, setData] = useState<any>({});
  const [tableLoader, setTableLoader] = useState(false);
  const [batchLoader, setBatchLoader] = useState(false);
  const [tableAttendanceData, setTableAttendanceData] = useState<any>([]);
  const [date, setDate] = useState<string>(dayjs(dayjs()).format("MM-DD-YYYY"));
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);
  const [userCategoryOptions, setUserCategoryOptions] = useState<any[]>([]);
  const [selectedUserCategory, setSelectedUserCategory] = useState<string>("");
  const getUserCategory = async () => {
    let res = await MasterService.getUserCategoryList("", "", 0, 15, true);
    if (res?.items?.length >= 0) {
      const result = res?.items;
      const transformedItems = result.map((item: any) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });
      setUserCategoryOptions([...transformedItems]);
    } else {
      message.error(res?.message);
    }
  };
  useEffect(() => {
    if (openDrawer) {
      setIsDrawerLoader(true);
      getUserCategory();
    }
  }, [openDrawer]);
  useEffect(() => {
    if (openDrawer) {
      if (date && selectedUserCategory) {
        getAttendanceData(
          (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
          pagingItems?.pageSize
        );
      }
    }
  }, [selectedUserCategory, date, pagingItems]);
  useEffect(() => {
    if (!tableLoader && !batchLoader) {
      setIsDrawerLoader(false);
    }
  }, [batchLoader, tableLoader]);

  const getAttendanceData = async (start: any, length: any) => {
    if (date && selectedUserCategory) {
      let params = {
        date,
        uId: selectedUserCategory,
        start,
        length,
      };
      setTableLoader(true);
      let res: any = await AttendanceService.get_all_att_by_batch_and_date(
        params
      );
      if (res?.items?.length > 0) {
        setPagingItems((p) => {
          p.totalRecords = res?.totalRecords;
          return p;
        });
        setTableAttendanceData(() =>
          res?.items?.map((itm: any, index: number) => {
            return {
              id: itm?.id,
              key: index,
              name: itm?.name,
              email: itm?.email,
              status: itm?.status,
            };
          })
        );
      } else {
        setPagingItems((p) => {
          p.totalRecords = 0;
          return p;
        });
        setTableAttendanceData([]);
      }
      setTableLoader(false);
    }
  };
  const OnSave = async () => {
    if (selectedUserCategory && date) {
      setSaveBtnLoader(true);
      let finalVal = {
        entryDate: date,
        UserCategoryId: selectedUserCategory,
        user: tableAttendanceData?.map((itm: any) => {
          return {
            user: {
              id: itm?.id,
              name: itm?.name?.first,
            },
            email: itm?.email,
            type: itm?.status,
          };
        }),
      };
      let res = await AttendanceService.postBulkAtt(finalVal);
      // closeDrawer();
      if (res.status) {
        message.success("Attendance saved successfully");
        setTrigger((x: boolean) => !x);
      } else {
        message.error(res?.message);
      }
      setSaveBtnLoader(false);
    } else {
      message.error("Please select valid batch");
    }
  };
  const closeDrawer = () => {
    setTableAttendanceData([]);
    drawerClose();
    setSelectedUserCategory("");
    setDate(dayjs(dayjs()).format("MM-DD-YYYY"));
  };
  const getSelectedRow = () => {
    let selectedRow: any = [];
    if (tableAttendanceData) {
      tableAttendanceData?.map((itm: any, index: number) => {
        if (itm.status === 1) {
          selectedRow.push(index);
        }
        return itm;
      });
    }
    return selectedRow;
  };

  return (
    <Drawer
      title="Mark Attendance"
      width={600}
      zIndex={1005}
      open={openDrawer}
      // onClose={closeDrawer}
      // maskClosable={false}
      closeIcon={false}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="curser"
          onClick={closeDrawer}
        />
      }
    >
      {/* <Spin
        spinning={drawerLoader}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      > */}
      <Row gutter={[26, 26]} className="px-2 py-3">
        <Col lg={24}>
          <Row gutter={[14, 14]}>
            <Col span={6}>
              <DatePicker
                disabledDate={(current) => {
                  return current && current > dayjs().endOf("day");
                }}
                format="DD/MM/YYYY"
                defaultValue={dayjs()}
                style={{ width: "100%" }}
                onChange={(val) => setDate(dayjs(val)?.format("MM-DD-YYYY"))}
              />
            </Col>
            <Col span={6}>
              <Select
                popupClassName="cit-select-box"
                value={selectedUserCategory}
                onSelect={(val) => {
                  setSelectedUserCategory(val);
                }}
                placeholder="Select Category"
                allowClear
                listHeight={224}
                showSearch
                style={{ width: "100%" }}
                options={userCategoryOptions}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onClear={() => {
                  setSelectedUserCategory("");
                }}
              ></Select>
            </Col>
          </Row>
        </Col>
        <Col lg={24}>
          <Table
            dataSource={tableAttendanceData}
            columns={columns}
            footer={() => (
              <Row justify="end">
                <Button
                  disabled={tableAttendanceData.length > 0 ? false : true}
                  loading={saveBtnLoader}
                  onClick={() => OnSave()}
                >
                  Save
                </Button>
              </Row>
            )}
            loading={{
              spinning: tableLoader,
              indicator: <Spin indicator={<LoadingOutlined />} />,
            }}
            rowSelection={{
              selectedRowKeys: getSelectedRow(),
              preserveSelectedRowKeys: true,
              onSelect: (record, selected, selectedRows, nativeEvent) => {
                setTableAttendanceData(
                  tableAttendanceData.map((x: any) => {
                    if (x?.id === record?.id) {
                      return {
                        name: x?.name,
                        key: x?.key,
                        id: x?.id,
                        email: x?.email,
                        status: selected ? 1 : 0,
                      };
                    } else {
                      return x;
                    }
                  })
                );
              },
              onSelectAll: (selected, selectedRows, changeRows) => {
                setTableAttendanceData(
                  tableAttendanceData.map((x: any) => {
                    return {
                      name: x?.name,
                      key: x?.key,
                      id: x?.id,
                      email: x?.email,
                      status: selected ? 1 : 0,
                    };
                  })
                );
              },
              getCheckboxProps: (record: any) => {
                return {
                  disabled: record.name === "Disabled User", //Column configuration not to be checked.
                  name: record.name,
                };
              },
            }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              selectPrefixCls: "custom-table-select",
              current: pagingItems.currentPage,
              pageSize: pagingItems.pageSize,
              showSizeChanger: true,
              total: pagingItems.totalRecords,
              pageSizeOptions: [15, 20, 30, 50],
              position: ["bottomRight"],
            }}
            onChange={(paging, filter, sort: any) => {
              paging &&
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 0,
                  pageSize: paging?.pageSize ?? 15,
                });
            }}
          />
        </Col>
      </Row>
      {/* </Spin> */}
      {/* <Modal open={modelOpen}>
        <Card>{JSON.stringify(data)}</Card>
      </Modal> */}
    </Drawer>
  );
};
export default AddEditAttendance;
