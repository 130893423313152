import React, { useState, useEffect, useContext } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  // Divider,
  Input,
  // Popover,
  Row,
  Select,
  Spin,
  Table,
  // message,
} from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
// import AddProduct from "./AddProduct";
// import ProductDetails from "./ProductDetails";
import ProductService, { Category } from "../../Services/ProductService";
// import MasterService from "../../Services/MasterService";
// import PendingImg from "../../Assets/images/pending_sign.svg";
import {
  // ProductStatusFlag,
  pageSizeOption,
} from "../../Context/ApplicationContext/ApplicationState";
import AddPaidSoftware from "./AddPaidSoftware";
import { dayjs } from "../../../Utilities/dayjs";
import PaidSoftwareDetails from "./PaidSoftwareDetails";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import MasterService from "../../Services/MasterService";
// import ModifyState from "./ModifyState";
const { RangePicker } = DatePicker;
interface ListDataType {
  key: string;
  id: string;
  productName: string;
  subCategory: string;
  assignedPerson: string;
  committedStock: number;
  inStock: number;
  status: string;
  productStatusFlag: number;
}
const { Search } = Input;
// const { RangePicker } = DatePicker;
const CurrencyLabel: any = {
  0: "$",
  1: "€",
  2: "£",
  3: "¥",
  4: "₹",
};

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
}: any) => {
  return (
    <>
      <div className="invSelectVendor">
        {ifSelect ? (
          <Select
            onSelect={(value) => {
              changeListParams("status", value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            bordered={false}
            size="small"
            className="invSelect"
            placeholder={title}
            // defaultValue={{ value: "-1", label: "All" }}
            options={[
              { value: "-1", label: "All" },
              { value: "0", label: "In Stock" },
              { value: "1", label: "In Use" },
              { value: "2", label: "Used" },
            ]}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => {
              e.stopPropagation();
              changeListParams(inputName, e.target?.value?.trim());
            }}
            onSearch={(e) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};
const PaidSoftwareList = () => {
  const [show, setShow] = useState("");
  const [data, setData] = useState<any>([]);
  // const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  // const [isPending, setIsPending] = useState(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<any>({});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);

  const [listParams, setListParams] = useState({
    nameSearch: "",
    subCategorySearch: "",
    assigneeSearch: "",
    status: "",
    start: 0,
    length: 15,
    sortDir: "",
    sortCol: "",
    startDate: "",
    endDate: "",
  });

  // const [messageApi, contextHolder] = message.useMessage();
  // const error = (mes: string) => {
  //   messageApi.error(mes, 1);
  // };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    setListParams((p) => {
      p.sortCol = sorter?.columnKey;
      p.sortDir = sorter?.order;
      return p;
    });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
    }
    setShouldRefresh((x) => !x);
  };

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const getSubCategory = () =>
    MasterService.getSubCategoryOptions(Category.PaidSoftware, "").then(
      (res) => {
        setSubCategoryOptions(
          res?.map((r: any) => ({
            label: r.name,
            value: r.id,
          }))
        );
      }
    );

  useEffect(() => {
    getSubCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    ProductService.getList(
      Category.PaidSoftware,
      listParams.nameSearch,
      "",
      "",
      "",
      "",
      "",
      "",
      listParams.start,
      listParams.length,
      listParams.sortCol,
      listParams.sortDir,
      undefined,
      "",
      "",
      listParams.startDate,
      listParams.endDate
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        setData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            productName: data?.name,
            numberOfStock: (data?.numberOfStock).toFixed(2),
            pricePerUnit: {
              pricePerUnit: data?.pricePerUnit,
              currency: data?.currency,
            },
            totalPrice: (data?.totalPrice).toFixed(2),
            inrRate: (data?.inrRate).toFixed(2),
            // expiryDate:
            //   data?.expiryDate === "0001-01-01T00:00:00Z"
            //     ? ""
            //     : dayjs(data?.expiryDate).format("DD/MM/YYYY"),
            purchaseDate:
              data?.purchaseDate === "0001-01-01T00:00:00Z"
                ? ""
                : dayjs(data?.purchaseDate).format("DD/MM/YYYY"),
            amount: (data?.amount).toFixed(2),
            gst: data?.gst?.name,
            subscription:
              data?.subscription === null || undefined
                ? {
                    startDate: "-",
                    endDate: "-",
                  }
                : {
                    startDate: dayjs(data?.subscription?.startDate).format(
                      "DD/MM/YYYY"
                    ),
                    endDate: dayjs(data?.subscription?.endDate).format(
                      "DD/MM/YYYY"
                    ),
                  },
            // totalAmount: data?.totalAmount,
            gstAmount: (data?.gstAmount).toFixed(2),
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  }, [listParams, shouldRefresh]);

  const columns: ColumnsType<ListDataType> = [
    {
      title: (
        <THeader
          title={"Product Name"}
          inputName={"nameSearch"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productName",
      key: "name",
      width: 270,
      // sorter: true,
      // showSorterTooltip: false,
      render: (name, record) => (
        <Button
          type="link"
          size="small"
          onClick={() => {
            // setSelectedRowKeys(record.key);
            // if (record.productStatusFlag === ProductStatusFlag.Pending) {
            //   setIsPending(ProductStatusFlag.Pending);
            // } else {
            //   setIsPending(ProductStatusFlag.Approved);
            // }
            setShow("PaidSoftwareDetails");
            setSelectedRecord(record);
            setOpenDrawer(true);
          }}
          className="text-dark ps-1 font13"
        >
          {name}
        </Button>
      ),
    },
    {
      title: "Purchase Date",
      dataIndex: "purchaseDate",
      key: "invoiceDate",
      // render: (val: any) => (
      //   <div className="ps-1 font13 ">{val?.startDate}</div>
      // ),
    },
    {
      title: "Start Date",
      dataIndex: "subscription",
      key: "startDate",
      render: (val: any) => (
        <div className="ps-1 font13 ">{val?.startDate}</div>
      ),
    },
    {
      title: "End Date",
      dataIndex: "subscription",
      key: "endDate",
      render: (val: any) => <div className="ps-1 font13 ">{val?.endDate}</div>,
    },
    // {
    //   title: "GST",
    //   dataIndex: "gst",
    //   key: "gst",
    //   sorter: true,
    //   showSorterTooltip: false,
    //   align: "right",
    //   // width: 70,
    //   render: (val: any) => <div className=" font13">{val}%</div>,
    // },
    {
      title: "Qty",
      align: "right",
      dataIndex: "numberOfStock",
      key: "numberOfStock",
      sorter: true,
      showSorterTooltip: false,
      // width: 70,
      render: (val: any) => <div className="font13 text-end">{val}</div>,
    },
    {
      title: "Rate",
      align: "right",
      dataIndex: "pricePerUnit",
      key: "pricePerUnit",
      sorter: true,
      // width: 70,
      showSorterTooltip: false,
      render: (val: any) => {
        return (
          <>
            <div className="font13 text-end">
              <span style={{ color: "black" }}>
                {CurrencyLabel[val?.currency]}
              </span>
              <span>{val?.pricePerUnit}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "INR",
      align: "right",
      // width: 70,
      dataIndex: "inrRate",
      key: "inrRate",
      sorter: true,
      showSorterTooltip: false,
      render: (val: any) => {
        return (
          <div className="font13 text-end">
            <span style={{ color: "black" }}>₹‎</span>
            <span>{val}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: true,
      align: "right",
      // width: 105,
      showSorterTooltip: false,
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: "GST Amt",
      dataIndex: "gstAmount",
      key: "gstAmount",
      sorter: true,
      align: "right",
      // width: 100,
      showSorterTooltip: false,
      render: (val: any) => <div className=" font13">{val}</div>,
    },
    {
      title: "Total Amt",
      align: "right",

      dataIndex: "totalPrice",
      key: "totalPrice",
      sorter: true,
      showSorterTooltip: false,
      // width: 110,

      render: (val: any) => <div className="font13 text-end">{val}</div>,
    },
  ];

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  return (
    <>
      <Card
        title="Software"
        bordered={false}
        extra={
          <>
            {" "}
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <RangePicker
                  format={"DD/MM/YYYY"}
                  onChange={(val: any) => {
                    if (val !== null) {
                      changeListParams("startDate", val[0].toISOString());
                      changeListParams("endDate", val[1].toISOString());
                      setShouldRefresh((x) => !x);
                    } else {
                      changeListParams("startDate", "");
                      changeListParams("endDate", "");
                      setShouldRefresh((x) => !x);
                    }
                  }}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow("AddPaidSoftware")}
                >
                  Software
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* {contextHolder} */}

        <Table
          rowClassName={rowClassName}
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          // className="InvTable"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          scroll={{ y: `calc(100vh - 300px)` }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} className="fw600">
                    {" "}
                    <div className="ps-2">TOTAL</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={3}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="fw600" align="right">
                    {data?.reduce((acc: any, curr: any) => {
                      return (acc += Number(curr?.numberOfStock));
                    }, 0) || 0}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600" align="right">
                    {/* {parseFloat(
                    (
                      data?.reduce((acc: any, curr: any) => {
                        return (acc += Number(
                          curr?.pricePerUnit?.pricePerUnit
                        ));
                      }, 0) || 0
                    ).toFixed(4)
                  )} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6} className="fw600" align="right">
                    {/* {data?.reduce((acc: any, curr: any) => {
                    return (acc += Number(curr?.inrRate));
                  }, 0) || 0} */}
                    {/* {parseFloat(
                    (
                      data?.reduce((acc: any, curr: any) => {
                        return (acc += Number(curr?.inrRate));
                      }, 0) || 0
                    ).toFixed(4)
                  )} */}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7} className="fw600" align="right">
                    {/* {" "}
                  {data?.reduce((acc: any, curr: any) => {
                    return (acc += Number(curr?.amount));
                  }, 0) || 0} */}
                    {parseFloat(
                      (
                        data?.reduce((acc: any, curr: any) => {
                          return (acc += Number(curr?.amount));
                        }, 0) || 0
                      ).toFixed(2)
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8} className="fw600" align="right">
                    {/* {data?.reduce((acc: any, curr: any) => {
                    return (acc += Number(curr?.gstAmount));
                  }, 0) || 0} */}
                    {parseFloat(
                      (
                        data?.reduce((acc: any, curr: any) => {
                          return (acc += Number(curr?.gstAmount));
                        }, 0) || 0
                      ).toFixed(2)
                    )}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9} className="fw600" align="right">
                    {" "}
                    {/* {data?.reduce((acc: any, curr: any) => {
                    return (acc += Number(curr?.totalPrice));
                  }, 0) || 0} */}
                    {parseFloat(
                      (
                        data?.reduce((acc: any, curr: any) => {
                          return (acc += Number(curr?.totalPrice));
                        }, 0) || 0
                      ).toFixed(2)
                    )}
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />

        {show === "PaidSoftwareDetails" && (
          <PaidSoftwareDetails
            openDrawer={openDrawer}
            // isPending={isPending}
            // subCategoryOptions={subCategoryOptions}
            selectedRecord={selectedRecord}
            onDismiss={(rec: boolean) => {
              setShow("");
              setOpenDrawer(false);
              setSelectedRowKeys("");
              if (rec) setShouldRefresh((x) => !x);
            }}
          />
        )}

        {show === "AddPaidSoftware" && (
          <AddPaidSoftware
            getSubCategory={getSubCategory}
            subCategoryOptions={subCategoryOptions}
            onDismiss={(rec: boolean) => {
              setShow("");
              if (rec) setShouldRefresh((x) => !x);
            }}
            setShouldRefresh={() => setShouldRefresh((x) => !x)}
          />
        )}
      </Card>
    </>
  );
};

export default PaidSoftwareList;
