import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Tabs,
  TabsProps,
  message,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import VendorService, { IApiDataProps } from "../../Services/VendorService";
import "../../Common/Common.css";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
const { TextArea } = Input;
const { Option } = Select;
interface IAddVendorProps {
  vendor?: IApiDataProps[];
  vendorId?: string;
  onDismiss: (rec: boolean) => void;
  setShouldRefresh: () => void;
}
export const VendorType = [
  { label: "Product", value: 0 },
  { label: "Construction", value: 1 },
];
const AddVendor = (props: IAddVendorProps) => {
  const { getVendorOptions } = useContext(ApplicationContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  // const [form1Values, setForm1Values] = useState({});
  const [activeKey, setActiveKey] = useState("0");
  const [form1] = Form.useForm();
  // const [form2] = Form.useForm();
  const inputRef = React.useRef<any>(null);
  const selectRef = React.useRef<any>(null);

  const onSaveAndContinue = () => {
    form1.validateFields().then((val) => {
      // setForm1Values(val);
      setActiveKey("2");
    });
  };
  const info = (msg: any) => {
    messageApi.success(msg, 1);
  };
  const error = (mes: any) => {
    messageApi.error(mes);
  };
  const resetVendor = () => {
    form1.resetFields();
    props.setShouldRefresh();
    // form2.resetFields();
    // setActiveKey("1");
  };
  const onSave = (onlySave: boolean) => {
    form1.validateFields().then(async (values) => {
      setButtonDisable(true);
      await VendorService.post(values).then((res) => {
        if (res?.status) {
          info("Vendor Added Successfully");
          setTimeout(() => {
            onlySave ? props.onDismiss(true) : resetVendor();
          }, 1000);
          getVendorOptions();
        } else {
          error(res?.message);
        }
      });
      setButtonDisable(false);
    });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Details`,
      children: (
        <>
          <Form
            form={form1}
            labelCol={{ span: 18 }}
            wrapperCol={{ span: 24 }}
            onFinish={onSaveAndContinue}
            // style={{ maxWidth: 800 }}
            layout="vertical"
            initialValues={{ remember: true }}
            autoComplete="off"
            // size="large"
            className="InvAddForm"
          >
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Company Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input company name",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                      message: "Please input alphabets only!",
                    },
                    {
                      whitespace: true,
                    },
                  ]}
                >
                  <Input ref={inputRef} placeholder="Company name" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Contact Person"
                  name="contactPerson"
                  rules={[
                    // { required: true, message: "Please input contact" },
                    {
                      pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                      message: "Please input alphabets only!",
                    },
                  ]}
                >
                  <Input placeholder="Contact person" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item label="Known For" name="companyType">
                  <Select allowClear placeholder="Select known for">
                    <Option value="0">Supplier & Service Provider</Option>
                    <Option value="1">Supplier</Option>
                    <Option value="2">Service Provider</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item
                  label="Vendor Type"
                  name="vendorType"
                  rules={[
                    {
                      required: true,
                      message: "Please input vendorType",
                    },
                  ]}
                >
                  <Select
                    options={VendorType}
                    allowClear
                    placeholder="Select vendorType"
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={[24, 12]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item label="Known For" name="companyType">
                  <Select allowClear placeholder="Select known for">
                    <Option value="0">Supplier & Service Provider</Option>
                    <Option value="1">Supplier</Option>
                    <Option value="2">Service Provider</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item
                  label="Vendor Type"
                  name="vendorType"
                  rules={[
                    {
                      required: true,
                      message: "Please input vendorType",
                    },
                  ]}
                >
                  <Select
                    options={VendorType}
                    allowClear
                    placeholder="Select vendorType"
                  />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Phone No."
                  name="workPhone"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input phone number!",
                    // },
                    {
                      pattern: new RegExp(/^\d{10}$/),
                      message: "Please input valid number!",
                    },
                  ]}
                >
                  <Input placeholder="Phone no." />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Mobile No."
                  name="mobileNo"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please input mobile number!",
                    // },
                    {
                      pattern: new RegExp(/^\d{10}$/),
                      message: "Please input valid number!",
                    },
                  ]}
                >
                  <Input placeholder="Mobile no." />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    // { required: true, message: "Please input email!" },
                    {
                      type: "email",
                      message: "Please input valid email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item label="City" name="city">
                  <Input placeholder="City" />
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item
                  label="Zip code"
                  name="zipCode"
                  rules={[
                    {
                      pattern: new RegExp(/^\d{6}$/),
                      message: "Please input valid zipCode",
                    },
                  ]}
                >
                  <Input placeholder="ZipCode" />
                </Form.Item>
              </Col>
              {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="State" name="state">
                  <Input placeholder="State" />
                </Form.Item>
              </Col> */}
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="Country" name="country">
                  <Select allowClear placeholder="Select country">
                    <Option value="India">India</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 12]}>
              <Col lg={{ span: 10 }} sm={{ span: 12 }}>
                <Form.Item label="Street 1" name="street1">
                  <TextArea
                    ref={selectRef}
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    placeholder="Street 1"
                  />
                </Form.Item>
              </Col>
              <Col lg={{ span: 10 }} sm={{ span: 12 }}>
                <Form.Item label="Street 2" name="street2">
                  <TextArea
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    placeholder="Street 2"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[18, 18]}>
              <Col lg={{ span: 20 }} md={{ span: 16 }}>
                <Form.Item label="Description" name="description">
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 5 }}
                    placeholder="Description"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      ),
    },
    // {
    // key: "2",
    // label: `Address Information`,
    // children: (
    //   <>
    //     <Form
    //       form={form2}
    //       labelCol={{ span: 18 }}
    //       wrapperCol={{ span: 24 }}
    //       style={{ maxWidth: 800 }}
    //       layout="vertical"
    //       initialValues={{ remember: true }}
    //       autoComplete="off"
    //       size="large"
    //       className="InvAddForm"
    //     >
    //       <Row gutter={[24, 12]}>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }}>
    //           <Form.Item label="Street 1" name="street1">
    //             <TextArea
    //               ref={selectRef}
    //               autoSize={{ minRows: 2, maxRows: 6 }}
    //               placeholder="Street 1"
    //             />
    //           </Form.Item>
    //         </Col>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }}>
    //           <Form.Item label="Street 2" name="street2">
    //             <TextArea
    //               autoSize={{ minRows: 2, maxRows: 6 }}
    //               placeholder="Street 2"
    //             />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={[24, 12]}>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
    //           <Form.Item label="City" name="city">
    //             <Input placeholder="City" />
    //           </Form.Item>
    //         </Col>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }}>
    //           <Form.Item
    //             label="Zip code"
    //             name="zipCode"
    //             rules={[
    //               {
    //                 pattern: new RegExp(/^\d{6}$/),
    //                 message: "Please input valid zipcode",
    //               },
    //             ]}
    //           >
    //             <Input placeholder="Zipcode" />
    //           </Form.Item>
    //         </Col>
    //       </Row>
    //       <Row gutter={[24, 12]}>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }}>
    //           <Form.Item label="State" name="state">
    //             <Input placeholder="State" />
    //           </Form.Item>
    //         </Col>
    //         <Col lg={{ span: 5 }} sm={{ span: 12 }}>
    //           <Form.Item label="Country" name="country">
    //             <Select allowClear placeholder="Select country">
    //               <Option value="India">India</Option>
    //             </Select>
    //           </Form.Item>
    //         </Col>
    //       </Row>
    /* Temp comment */

    /* <Row gutter={[16, 12]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }}>
                <Form.Item label="Issue Date" name="issueDate">
                  <DatePicker
                    placeholder="Select date"
                    style={{ width: "100%" }}
                    format={"DD/MM/YYYY"}
                    onChange={(val: any) => {
                      if (val === null) {
                        form2.setFieldValue("issueDate", undefined);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row> */
    //     </Form>
    //   </>
    // ),
    // },
  ];

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  useEffect(() => {
    setTimeout(() => {
      activeKey === "1"
        ? inputRef.current?.focus()
        : selectRef.current?.focus();
    }, 5);
  }, [activeKey]);

  return (
    <div>
      {contextHolder}
      <Drawer
        title={"Add Vendor"}
        className="InvAddDrawer-A"
        closable={false}
        open={true}
        width="35vw"
        zIndex={1005}
        extra={<CloseOutlined onClick={() => props.onDismiss(false)} />}
        onClose={() => props.onDismiss(false)}
        // getContainer={false}
        // style={{ height: `calc(100vh - 100px)` }}
        afterOpenChange={() => {
          setActiveKey("1");
        }}
        footer={
          <>
            <>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                className="Inv-SaveBtn"
                onClick={() => onSave(true)}
                disabled={buttonDisable}
              >
                Save
              </Button>
              <Button
                type="primary"
                ghost
                className="Inv-SaveAndNewBtn"
                onClick={() => onSave(false)}
                disabled={buttonDisable}
              >
                Save and New
              </Button>
            </>
            <Button
              danger
              // className="Inv-CancelBtn"
              onClick={() => props.onDismiss(false)}
            >
              Cancel
            </Button>
          </>
        }
      >
        {/* <Tabs
          activeKey={activeKey}
          items={items}
          onChange={onTabChange}
          className="InvTab"
        /> */}
        <Form
          form={form1}
          onFinish={onSaveAndContinue}
          initialValues={{ remember: true }}
          colon={false}
          requiredMark={false}
          labelAlign="left"
          labelCol={{ span: 7 }}
          className="InvAddForm"
          wrapperCol={{ span: 16 }}
          autoComplete="off"
          // style={{ maxWidth: 800 }}
          // size="large"
          // className="InvAddForm"
        >
          {/* <Row gutter={[18, 18]}> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Company Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input company name",
              },
              {
                pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                message: "Please input alphabets only!",
              },
              {
                whitespace: true,
              },
            ]}
          >
            <Input ref={inputRef} placeholder="Company name" />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Contact Person"
            name="contactPerson"
            rules={[
              // { required: true, message: "Please input contact" },
              {
                pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                message: "Please input alphabets only!",
              },
            ]}
          >
            <Input placeholder="Contact person" />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          <Form.Item label="Known For" name="companyType">
            <Select allowClear placeholder="Select known for">
              <Option value="0">Supplier & Service Provider</Option>
              <Option value="1">Supplier</Option>
              <Option value="2">Service Provider</Option>
            </Select>
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          <Form.Item
            label="Type"
            name="vendorType"
            rules={[
              {
                required: true,
                message: "Please input vendorType",
              },
            ]}
          >
            <Select
              options={VendorType}
              allowClear
              placeholder="Select vendorType"
            />
          </Form.Item>
          <Form.Item
            label="GST No."
            name="gstNumber"
            rules={[
              {
                pattern: new RegExp(
                  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                ),
                message: "Please input valid GST number!",
              },
            ]}
          >
            <Input placeholder="GST No." maxLength={15} />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          {/* <Row gutter={[24, 12]}>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item label="Known For" name="companyType">
                  <Select allowClear placeholder="Select known for">
                    <Option value="0">Supplier & Service Provider</Option>
                    <Option value="1">Supplier</Option>
                    <Option value="2">Service Provider</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
                <Form.Item
                  label="Vendor Type"
                  name="vendorType"
                  rules={[
                    {
                      required: true,
                      message: "Please input vendorType",
                    },
                  ]}
                >
                  <Select
                    options={VendorType}
                    allowClear
                    placeholder="Select vendorType"
                  />
                </Form.Item>
              </Col>
            </Row> */}
          {/* <Row gutter={[18, 18]}> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Phone No."
            name="workPhone"
            rules={[
              // {
              //   required: true,
              //   message: "Please input phone number!",
              // },
              {
                pattern: new RegExp(/^\d{10}$/),
                message: "Please input valid number!",
              },
            ]}
          >
            <Input placeholder="Phone no." />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          {/* <Form.Item
            label="Mobile No."
            name="mobileNo"
            rules={[
              // {
              //   required: true,
              //   message: "Please input mobile number!",
              // },
              {
                pattern: new RegExp(/^\d{10}$/),
                message: "Please input valid number!",
              },
            ]}
          >
            <Input placeholder="Mobile no." />
          </Form.Item> */}
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          <Form.Item
            label="Email"
            name="email"
            rules={[
              // { required: true, message: "Please input email!" },
              {
                type: "email",
                message: "Please input valid email!",
              },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          {/* <Row gutter={[18, 18]}> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}> */}
          {/* <Form.Item label="City" name="city">
            <Input placeholder="City" />
          </Form.Item> */}
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}

          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          {/* <Form.Item label="State" name="state">
            <Input placeholder="State" />
          </Form.Item> */}
          {/* </Col> */}
          {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}> */}
          {/* <Form.Item label="Country" name="country">
            <Select allowClear placeholder="Select country">
              <Option value="India">India</Option>
            </Select>
          </Form.Item> */}
          {/* </Col> */}
          {/* </Row> */}

          {/* <Row gutter={[24, 12]}> */}
          {/* <Col lg={{ span: 10 }} sm={{ span: 12 }}> */}
          <Form.Item label="Address 1" name="street1">
            <TextArea
              ref={selectRef}
              autoSize={{ minRows: 1, maxRows: 6 }}
              placeholder="Address 1"
            />
          </Form.Item>
          {/* </Col> */}
          {/* <Col lg={{ span: 10 }} sm={{ span: 12 }}> */}
          <Form.Item label="Address 2" name="street2">
            <TextArea
              autoSize={{ minRows: 1, maxRows: 6 }}
              placeholder="Address 2"
            />
          </Form.Item>
          <Form.Item
            label="Zip Code"
            name="zipCode"
            rules={[
              {
                pattern: new RegExp(/^\d{6}$/),
                message: "Please input valid zipCode",
              },
            ]}
          >
            <Input placeholder="ZipCode" />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
          {/* <Row gutter={[18, 18]}> */}
          {/* <Col lg={{ span: 20 }} md={{ span: 16 }}> */}
          <Form.Item label="Description" name="description">
            <TextArea
              autoSize={{ minRows: 2, maxRows: 5 }}
              placeholder="Description"
            />
          </Form.Item>
          {/* </Col> */}
          {/* </Row> */}
        </Form>
      </Drawer>
    </div>
  );
};

export default AddVendor;
