import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export const enum EntitiesEnum {
  Unknown,
  ApplicationUser,
  Company,
  Vendor,
  Assignee,
  Department,
  Designation,
  SubCategory,
  ProductGroup,
  Variant,
  Option,
  Seating,
  Unit,
  Product,
  ConstructionPurchases,
  Material,
  ConstructionItem,
  Measurement,
  GST,
  UserCategory,
  Vehicle,
  Driver,
  Passenger,
  TripSchedule,
  TripLogs,
  Shift,
  Round,
  PassengerType,
  LogStatus,
}
class ProductStateService {
  route = `/v1/api/UserLog`;

  getUserLog = (
    entityId: string,
    entity: EntitiesEnum,
    start: number,
    length: number
  ) =>
    ApiUtility.getResult(`${this.route}`, { entityId, entity, start, length });
}

export default new ProductStateService();
