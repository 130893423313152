import React, { useEffect, useState } from "react";
import { Button, Drawer, Spin, Tabs, TabsProps } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import VendorService, { IApiDataProps } from "../../Services/VendorService";
import EditVendor from "./EditVendor";
import VendorHistory from "./VendorHistory";
interface IVendorDetailsProps {
  openDrawer: boolean;
  vendorHistoryId: string;
  onDismiss: (rec?: boolean) => void;
}

const VendorDetails = (props: IVendorDetailsProps) => {
  const [loading, setLoading] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [vendorData, setVendorData] = useState<IApiDataProps>({
    id: "",
    name: "",
    contactPerson: "",
    workPhone: "",
    mobileNo: "",
    email: "",
    companyType: 0,
    description: "",
    country: "",
    zipCode: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    isEditAllowed: false,
    issueDate: "",
  });

  useEffect(() => {
    setLoading(true);
    const getVendor = () => {
      VendorService.get(props.vendorHistoryId).then((res) => {
        if (res) {
          setVendorData({ ...res });
          setLoading(false);
        }
      });
    };

    getVendor();
  }, [props.vendorHistoryId, shouldRefresh]);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Detail View`,
      children: loading ? (
        <div style={{ height: "100vh", position: "relative" }}>
          <Spin
            style={{ position: "absolute", top: "50%", left: "45%" }}
            spinning={loading}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </div>
      ) : (
        <EditVendor
          vendor={vendorData}
          setShouldRefresh={setShouldRefresh}
          setIsDataSaved={setIsDataSaved}
        />
      ),
    },
    {
      key: "2",
      label: `History`,
      children: (
        <VendorHistory
          id={props?.vendorHistoryId}
          shouldRefresh={shouldRefresh}
        />
      ),
    },
  ];

  const [activeKey, setActiveKey] = useState(items[0].key);

  const onTabChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
  };

  const tabExtra = (
    <Button
      icon={<CloseOutlined />}
      onClick={() => props.onDismiss(isDataSaved)}
      // className="border-none"
    />
  );
  return (
    <>
      <Drawer
        onClose={() => props.onDismiss(isDataSaved)}
        maskClosable={true}
        title={vendorData?.name}
        width="35vw"
        zIndex={1005}
        open={props.openDrawer}
        extra={tabExtra}
        className="InvDetailsDrawer"
      >
        <Tabs
          activeKey={activeKey}
          items={items}
          className="InvTab"
          onChange={onTabChange}
        />
      </Drawer>
    </>
  );
};

export default VendorDetails;
