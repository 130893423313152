import React, { useEffect, useState } from "react";
import { Spin, Table, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { LoadingOutlined } from "@ant-design/icons";
import LogService, {
  EntitiesEnum,
} from "../../CapsitechInventoryComponents/Services/LogService";
import { dayjs } from "../../Utilities/dayjs";

interface DataType {
  key: React.Key;
  serialNo: number;
  user: string;
  date: string;
  message: string;
  action: number;
}
interface IHistory {
  id: string;
  shouldRefresh: boolean;
}
const ConstructionHistory = (props: IHistory) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [messageApi, contextHolder] = message.useMessage();
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  useEffect(() => {
    setLoading(true);
    LogService.getUserLog(
      props?.id,
      EntitiesEnum.ConstructionPurchases,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.items.length > 0) {
        setData(
          res?.items?.map((d: any, i: number) => ({
            serialNo: i + 1 + listParams.start,
            key: i,
            date: dayjs(d?.createdBy?.date).format("DD/MM/YYYY , hh:mm A"),
            message: d?.message.replace(/\r?\n|\r/, ""),
            user: d?.createdBy?.userName,
            action: d?.action,
          }))
        );
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      } else {
        messageApi.error(res?.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams.length, listParams.start, props?.id, props?.shouldRefresh]);

  const columns: ColumnsType<DataType> = [
    {
      title: "S.No.",
      dataIndex: "serialNo",
      key: "serialNo",
      width: 70,
      ellipsis: true,
      align: "center",
      render: (serialNo: any) => <span className="font12">{serialNo}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 170,
      render: (date: any) => <span className="font12">{date}</span>,
    },
    {
      title: "Remarks",
      dataIndex: "message",
      key: "message",
      render: (val: string, record) => (
        <div className="font12">
          {record?.action === 1 ? (
            <p>{`${val} by ${record?.user}`}</p>
          ) : record?.action === 2 ? (
            <div>
              {`Updates made by ${record?.user} in :`}
              <pre className="pre-style">{val}</pre>
            </div>
          ) : (
            <p>{`${val} by ${record?.user}`}</p>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <Table
        size="small"
        loading={tableLoading}
        // pagination={false}
        columns={columns}
        dataSource={data}
        scroll={{ y: `calc(100vh - 250px)` }}
        className="InvTable pt-2 fw500 font18"
        pagination={
          totalRecords >= 10
            ? {
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                total: totalRecords,
                position: ["bottomCenter"],
                onChange: (page, pageSize) => {
                  setListParams({
                    ...listParams,
                    start: (page - 1) * pageSize,
                  });
                },
              }
            : false
        }
      />
    </>
  );
};

export default ConstructionHistory;
