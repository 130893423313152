import { useCallback, useContext, useRef } from "react";
import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Select,
  // Drawer,
  // InputNumber,
  Spin,
  Modal,
  Divider,
  Popconfirm,
  Tag,
  Card,
  // Space,
} from "antd";

import { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import MasterService, {
  CategorySearch,
  Masters,
} from "../../../Services/MasterService";
import {
  // CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
// import { Category } from "../../../Services/ProductService";
import {
  getSecureSessionItem,
  pageSizeOption,
  userRightsSessionKey,
} from "../../../Context/ApplicationContext/ApplicationState";
import MasterHistory from "../MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";

const { Search } = Input;

// For View
const CategoryLabels: any = {
  1: "Cleaning",
  2: "Stationary",
  3: "Garden or Plants",
  4: "Emergency Medical Kit",
  5: "Paid Software",
  6: "Digital Products",
  7: "Office Equipments",
};
// For List Input
const CategoryOptionSelect: any = [
  { label: "All", value: 0 },
  { label: "Cleaning", value: 1 },
  { label: "Stationary", value: 2 },
  { label: "Garden or Plants", value: 3 },
  { label: "Emergency Medical Kit", value: 4 },
  { label: "Paid Software", value: 5 },
  { label: "Digital Products", value: 6 },
  { label: "Office Equipments", value: 7 },
];

const SubCategory = () => {
  const inputRef = useRef<any>(null);

  <Input ref={inputRef} />;
  const loginUserRights: any = getSecureSessionItem(userRightsSessionKey);
  const { variantTypes } = useContext(ApplicationContext);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [updateToggle, setUpdateToggle] = useState("close");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [selectDisable, setButtonDisable] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editData, setEditData] = useState<any>({});
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  // const [fieldChange1, setFieldChange1] = useState<boolean>(false);
  // const [fieldChange2, setFieldChange2] = useState<boolean>(false);

  const CategoryTemp: any = [
    loginUserRights?.products?.cleaning
      ? { label: "Cleaning", value: 1 }
      : null,
    loginUserRights?.products?.stationery
      ? { label: "Stationary", value: 2 }
      : null,
    loginUserRights?.products?.gardenOrPlants
      ? { label: "Garden or Plants", value: 3 }
      : null,
    loginUserRights?.products?.emergencyMedicalKit
      ? { label: "Emergency Medical Kit", value: 4 }
      : null,
    loginUserRights?.products?.paidSoftware
      ? { label: "Paid Software", value: 5 }
      : null,
    loginUserRights?.products?.digitalProduct
      ? { label: "Digital Products", value: 6 }
      : null,
    loginUserRights?.products?.officeEquipments
      ? { label: "Office Equipments", value: 7 }
      : null,
  ];
  const CategoryArr: any = [];
  CategoryTemp.map((item: any) => (item ? CategoryArr.push(item) : null));
  // const [listParams, setListParams] = useState<any>({
  //   start: 0,
  //   length: 10,
  //   searchCategory: "",
  //   searchSubCategory: "",
  // });
  const [listParams, setListParams] = useState<any>({
    subCategorySearch: "",
    category: CategorySearch.All,
    company: "",
    start: 0,
    length: 15,
  });
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };

  // Message on Submit
  const info = (inf: any) => {
    messageApi.success(inf, 1);
  };

  // Message on Submit Error
  const errorMsg = useCallback(
    (err: any) => messageApi.error(err),
    [messageApi]
  );

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Subcategory Is Assigned  Can't be deleted",
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");

    setLoading(false);
    form.resetFields();
  };

  const GetSub = (listParams: any) => {
    MasterService.getSubCategoryList(
      listParams.subCategorySearch,
      listParams.category,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let item: any = [];
        res?.items?.map((data: any) => {
          return item.push({
            key: data?.id,
            id: data?.id,
            name: data?.name,
            category: data?.category,
            minLimit: data?.minLimit,
            maxLimit: data?.maxLimit,
            defaultVariants: data?.defaultVariants?.map((item: any) => {
              let x = {
                label: item?.name,
                value: item?.id,
              };
              return x;
            }),
            createdBy: dayjs(data?.createdBy.date).format("DD/MM/YYYY"),
            // createdBy: data?.createdBy,
          });
        });
        setLoading(false);
        setTableData([...item]);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  // Post Subcategory
  const onSave = (save: boolean) => {
    form.validateFields().then((values) => {
      let defaultVariants: any = [];
      // eslint-disable-next-line array-callback-return
      values?.defaultVariants?.map((item: any) => {
        let obj = {
          id: item?.value,
          name: item?.label,
        };
        defaultVariants.push(obj);
      });
      let subCategoryVal = {
        name: values?.name,
        category: values?.category,
        minLimit: values?.minLimit,
        maxLimit: values?.maxLimit,
        defaultVariants: defaultVariants,
      };
      MasterService.postSubCategory(subCategoryVal).then((res) => {
        if (res?.status === true) {
          info("submitted");
          save ? onDismiss() : form.resetFields();
          GetSub(listParams);
          setShouldRefresh((x) => !x);
          form.resetFields();
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  // Get data for subcategory
  // const getData = useCallback(
  //   (searchCategory: string, CategorySearch: CategorySearch) => {
  //     setLoading(true);
  //     MasterService.getSubCategoryList(
  //       searchCategory,
  //       CategorySearch,
  //       "",
  //       listParams.start,
  //       listParams.length
  //     )
  //       .then((res: any) => {
  //         if (res !== null || res?.totalRecords > 0) {
  //           let item: any = [];
  //           res?.items?.map((data: any) => {
  //             return item.push({
  //               key: data?.id,
  //               id: data?.id,
  //               name: data?.name,
  //               category: data?.category,
  //               minLimit: data?.minLimit,
  //               maxLimit: data?.maxLimit,
  //               defaultVariants: data?.defaultVariants?.map((item: any) => {
  //                 let x = {
  //                   label: item?.name,
  //                   value: item?.id,
  //                 };
  //                 return x;
  //               }),
  //               createdBy: dayjs(data?.createdBy.date).format("DD/MM/YYYY"),
  //             });
  //           });
  //           setLoading(false);
  //           setTableData([...item]);
  //           setTotalRecords(res?.totalRecords);
  //         }
  //       })
  //       .catch((err) => errorMsg(err));
  //   },
  //   [errorMsg, listParams.length, listParams.start]
  // );

  // Edit
  const editRowData = async (editList: any) => {
    MasterService.getSubCategory(editList?.id).then(async (res: any) => {
      let listData: any = {
        ...res,
        defaultVariants: res?.defaultVariants?.map((item2: any) => {
          let x = {
            value: item2?.id,
            label: item2?.name,
          };
          return x;
        }),
      };
      // res?.map((item: any) => {
      //   listData = {
      //     name: item?.name,
      //     category: item?.category,
      //     defaultVariants: item?.defaultVariants?.map((item2: any) => {
      //       let x = {
      //         value: item2?.id,
      //         label: item2?.name,
      //       };
      //       return x;
      //     }),
      //   };
      //   return listData;
      // });
      setSelectedRecord(editList);
      setButtonDisable(true);
      setUpdateToggle("show");
      setShow(true);
      setEditData(res);
      await MasterService.isExist(Masters.SubCategory, editList.id).then(
        (res) => {
          if (res === true) {
            setExist(true);
          } else {
            setExist(false);
          }
        }
      );
      form.setFieldsValue(listData);
    });
  };

  // Update Post
  const updateData = () => {
    form.validateFields().then(async (values) => {
      let val = {
        id: selectedRecord?.id,
        name: values?.name,
        category: values?.category,
        minLimit: values?.minLimit,
        maxLimit: values?.maxLimit,
        defaultVariants:
          values?.defaultVariants?.length > 0
            ? values?.defaultVariants?.map((item: any) => ({
                name: item?.label,
                id: item?.value,
              }))
            : [],
      };
      await MasterService.postSubCategory(val).then((res) => {
        if (res?.message) {
          messageApi.error(res?.message);
        } else {
          onDismiss();
          setUpdateToggle("close");
          GetSub(listParams);
          info("Updated Successfully");
          setSelectedRowKeys("");
        }
      });
    });

    // MasterService.postSubCategory(val).then((res) => {
    //   onDismiss();
    //   setUpdateToggle("close");
    //   info("Updated Successfully");
    // });

    setButtonDisable(true);
  };

  const deleteList = (listId: any) => {
    MasterService.isExist(Masters.SubCategory, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getSubCategory(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteSubCategory(listId).then((res: any) => {
              if (res.status) {
                GetSub(listParams);
                // errorMsg("Subcategory is Deleted Successfully");
                messageApi.success("Subcategory is deleted successfully.");
                onDismiss();
              } else errorMsg(res.errors);
            });
          } else {
            messageApi.warning(
              "Subcategory is added by admin can'nt be deleted"
            );
          }
        });
      }
    });
  };
  // useEffect(() => {
  //   // API Data //
  //   getData("", 0);
  // }, [getData, listParams, shouldRefresh]);

  useEffect(() => {
    setLoading(true);
    inputRef.current?.focus();
    GetSub(listParams);
  }, [listParams, shouldRefresh]);

  // Filter Functions //
  const searchCategory = (val: any) => {
    // getData();
    setListParams({
      subCategorySearch: listParams.subCategorySearch,
      category: val,
      company: "",
      start: 0,
      length: 15,
    });
  };
  const searchSubCategory = (e: any) => {
    setListParams({
      subCategorySearch: e.trim(),
      category: listParams.category,
      company: "",
      start: 0,
      length: 15,
    });
  };

  // Table Column
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className=" ms-2">SUB-CATEGORY </span> */}
          <div>
            <Search
              placeholder="Subcategory"
              className="InvSearchInput"
              allowClear
              // onChange={(e) =>
              //   changeListParams("searchCategory", e.target?.value)
              // }
              // onSearch={() => {
              //   setListParams({ ...listParams, start: 0 });
              //   setShouldRefresh((x: boolean) => !x);
              // }}
              onSearch={(e) => {
                searchSubCategory(e);
              }}
              size="small"
              bordered={false}
            />
          </div>
        </div>
      ),
      dataIndex: "name",
      ellipsis: true,
      key: "name",
      width: 250,

      render: (subcategory: string, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {subcategory !== null ? subcategory : "-"}
        </span>
      ),
    },

    {
      title: (
        <>
          <Select
            className="invSelectVendor"
            style={{ width: "100%" }}
            popupMatchSelectWidth={false}
            bordered={false}
            placeholder="Category"
            allowClear
            size="small"
            onClear={() => {
              setListParams({
                subCategorySearch: listParams.subCategorySearch,
                category: 0,
                company: "",
                start: 0,
                length: 15,
              });
              setShouldRefresh((x: boolean) => !x);
            }}
            // defaultValue={0}
            options={CategoryOptionSelect}
            onSelect={(val: any) => searchCategory(val)}
          />
        </>
      ),
      dataIndex: "category",
      ellipsis: true,
      key: "category",
      width: 200,
      responsive: ["xl"],
      render: (category: any) => (
        <span className="ps-2 font13">{CategoryLabels[category]}</span>
      ),
    },
    // {
    //   title: "LOW STOCK",
    //   dataIndex: "minLimit",
    //   key: "minLimit",
    //   ellipsis: true,
    //   sorter: {
    //     compare: (a: any, b: any) => a.minLimit - b.minLimit,
    //   },
    //   render: (min: string) => <div className="p-0  fw500 font13">{min}</div>,
    // },
    // {
    //   title: "EXTRA STOCK ",
    //   dataIndex: "maxLimit",
    //   ellipsis: true,
    //   sorter: {
    //     compare: (a: any, b: any) => a.maxLimit - b.maxLimit,
    //   },
    //   key: "maxLimit",

    //   render: (max: string) => <div className="p-0  fw500 font13">{max}</div>,
    // },

    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,

      // render: (createdBy: any) => (
      //   <Space>
      //     {dayjs(createdBy?.date).format("DD/MM/YYYY")}
      //     {createdBy?.userName}
      //   </Space>
      // ),
    },
    {
      width: "310px",
      title: " ",
      key: "operation",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            Edit
          </Button>{" "}
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record?.id)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              Delete
            </Button>
          </Popconfirm>
          <Button
            type="text"
            icon={<HistoryOutlined />}
            onClick={() => {
              setSelectedRecord(record);
              setShowDrawer("ShowHistory");
            }}
            size="small"
          >
            History
          </Button>
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  // const CheckChanges1 = (fieldName: string) => {
  //   if (editData[fieldName] === form.getFieldValue(fieldName)) {
  //     setFieldChange1(false);
  //   } else {
  //     setFieldChange1(true);
  //   }
  // };
  // const CheckChanges2 = (fieldName: string) => {
  //   console.log("0", editData[fieldName]);
  //   console.log("10", form.getFieldValue(fieldName)[0]);

  //   if (editData[fieldName] === form.getFieldValue(fieldName)) {
  //     setFieldChange2(false);
  //   } else {
  //     setFieldChange2(true);
  //   }
  // };

  return (
    <>
      {contextHolder}
      <Card
        title={"Sub Category"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => {
                    setUpdateToggle("close");
                    setShow(true);
                    setButtonDisable(false);
                  }}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <Table
              rowClassName={rowClassName}
              columns={columns}
              // className="InvTable"
              dataSource={tableData}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>

        <Modal
          className="InvModal"
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          // title={
          //   <span className="modalTitleStyle">
          //     {updateToggle === "close"
          //       ? "Add Subcategory"
          //       : "Update Subcategory"}
          //   </span>
          // }
          title={
            updateToggle === "close" ? (
              "Add Subcategory"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Subcategory
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Subcategory"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>{" "} */}
              </>
            )
          }
          open={show}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setSelectedRowKeys("");
            setShow(false);
            form.resetFields();
            setUpdateToggle("close");
          }}
          zIndex={1005}
          width={750}
          footer={
            <>
              {updateToggle === "close" ? (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      form.resetFields();
                      setUpdateToggle("close");
                      setLoading(false);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      onSave(true);
                      setLoading(true);
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      onSave(false);
                    }}
                  >
                    Save and New
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      form.resetFields();
                      setUpdateToggle("close");
                      setLoading(false);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  {/* {exist === true ? (
                  <Popconfirm
                    title={
                      exist === true
                        ? "Do you wan't to update this assigned Subcategory"
                        : "Update Value"
                    }
                    // onConfirm={() =>  }
                    okText="Yes"
                    cancelText="No"
                    placement="topRight"
                    onConfirm={() => updateData()}
                    onCancel={() => {
                      setShow(false);
                      form.resetFields();
                      setUpdateToggle("close");
                    }}
                  >
                    <Button type="primary" ghost>
                      Update
                    </Button>
                  </Popconfirm>
                ) : ( */}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed}
                  >
                    Update
                  </Button>
                  {/* )} */}
                  {/* 
                <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Subcategory"
                      : "Update Value"
                  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    form.resetFields();
                    setUpdateToggle("close");
                  }}
                >
                  <Button type="primary" ghost>
                    Update
                  </Button>
                </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            layout="vertical"
            className="InvAddForm pb-5"
            initialValues={{ remember: true }}
            autoComplete="off"
            size="large"
            style={{ maxWidth: 800 }}
            form={form}
          >
            <Row key={1} gutter={[{ xs: 12, md: 32 }, { xs: 8 }]}>
              <Col lg={12} xs={24}>
                <Form.Item
                  label={"Name"}
                  name="name"
                  rules={[
                    {
                      // pattern: /^\b[^-\s\d\W][^\s\d\W]*$/,
                      pattern: pattern,
                      message:
                        "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                    },
                    // {
                    //   required: true,
                    //   message: "Fill the field",
                    // },
                    {
                      required: true,
                      message: "Please fill the field",
                    },
                    // {
                    //   whitespace: true,
                    // },
                  ]}
                >
                  <Input
                    className="formInputStyle"
                    ref={inputRef}
                    // onBlur={() => {
                    //   CheckChanges1("name");
                    // }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  label={"Category"}
                  name="category"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "please fill the category",
                  //   },
                  // ]}
                >
                  <Select
                    className="invFormSelect"
                    options={CategoryArr}
                    disabled={selectDisable}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
            {/* Temporary Commented */}
            {/* <Row key={2} gutter={[{ xs: 12, md: 32 }, { xs: 8 }]}>
            <Col lg={12} xs={24}>
              <Form.Item label={"Choose Minimum Limit"} name="minLimit">
                <InputNumber
                  className="formInputStyle"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col lg={12} xs={24}>
              <Form.Item
                label={"Choose Maximum Limit"}
                name="maxLimit"
                // dependencies={["minLimit"]}
                // rules={[
                //   ({ getFieldValue }) => ({
                //     validator(_, value) {
                //       let intVal = value;
                //       let prevVal = getFieldValue("minLimit");
                //       if (intVal) {
                //         if (prevVal <= intVal) return Promise.resolve();
                //         else {
                //           return Promise.reject(
                //             new Error("Max must be greater then min")
                //           );
                //         }
                //       } else {
                //         return Promise.reject(
                //           new Error("Max must be greater then min")
                //         );
                //       }
                //     },
                //   }),
                // ]}
              >
                <InputNumber
                  className="formInputStyle"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row> */}
            <Row>
              <Col lg={12} xs={24}>
                <Form.Item label={"Default Variants"} name="defaultVariants">
                  <Select
                    // defaultValue={}
                    options={variantTypes}
                    mode="multiple"
                    maxLength={1}
                    maxTagTextLength={8}
                    listHeight={120}
                    maxTagCount={1}
                    allowClear
                    labelInValue
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // onBlur={() => {
                    //   CheckChanges2("defaultVariants");
                    // }}
                  ></Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.SubCategory}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};
export default SubCategory;
