import {
  Modal,
  Button,
  Select,
  Row,
  Col,
  Form,
  Input,
  Space,
  Checkbox,
  Spin,
} from "antd";
import { useForm } from "antd/es/form/Form";
import CabServices from "../../../Services/CabServices";
import { dayjs } from "../../../../Utilities/dayjs";
import { useEffect, useState } from "react";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const AddPassenger = (props: any) => {
  const [form] = useForm();
  const [userList, setUserList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [show, setShow] = useState<string>("");
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const { Search } = Input;
  const [tripList, setTripList] = useState<any>([]);
  let val = 0;

  const getTripOptions = async (tripId: string = "", name: string = "") => {
    setLoading(true);
    await CabServices.getTripOptions(tripId, name).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items[0]?.passenger?.map((r: any) => {
          return data.push({
            // key: r?.id,
            // id: r.id,
            label: r?.name,
            value: r?.id,
          });
        });
        setTripList([...data]);
        form.setFieldValue(
          "list",
          data?.map((itm: any) => ({
            isPresent: true,
            defaultPassengerType: {
              label: res?.items[0]?.defaultPassengerType?.name,
              value: res?.items[0]?.defaultPassengerType?.id,
            },
            passenger: {
              label: itm?.label,
              value: itm?.value,
            },
          }))
        );
        setLoading(false);
      }
    });
  };
  const getAllPassenger = async () => {
    await CabServices.getPassengerList("", "").then((res) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            // key: r?.id,
            // id: r.id,
            label: r?.name,
            value: r?.id,
          });
        });
        // if (tripId !== "") {
        //   setTripList([...data]);
        //   form.setFieldValue(
        //     "list",
        //     data?.map((itm: any) => ({
        //       isPresent: true,
        //       // type: 0,
        //       passenger: {
        //         label: itm?.label,
        //         value: itm?.value,
        //       },
        //     }))
        //   );
        //   setLoading(false);
        // } else {
        setUserList([...data]);
        setLoading(false);
        // }
      }
    });
  };
  // console.log("userList", userList);
  // console.log("setTrip1", tripList);
  const onSave = () => {
    form.validateFields().then((values: any) => {
      const data = {
        ...values,
        trip: {
          id: values?.trip?.value,
          name: values?.trip?.label,
        },
        list: values?.list?.map((itm: any) => ({
          ...itm,
          passenger: {
            id: itm?.passenger?.value,
            name: itm?.passenger?.label,
          },
          defaultPassengerType: {
            id: itm?.defaultPassengerType?.value,
            name: itm?.defaultPassengerType?.label,
          },
        })),
      };
      props?.onSave(data);
    });
  };
  // <Form.Item>{form}</Form.Item>;
  val = form?.getFieldValue("list")?.length;

  useEffect(() => {
    // let a =
    setTripList(
      props?.row?.list?.map((itm: any) => ({
        label: itm?.passenger?.name,
        value: itm?.passenger?.id,
      }))
    );
    // getPassenger(listParams, "");
    getAllPassenger();

    form.setFieldsValue({
      ...props?.row,
      trip: { label: props?.row?.trip?.name, value: props?.row?.trip?.id },
      list: props?.row?.list?.map((itm: any) => ({
        ...itm,
        passenger: {
          label: itm?.passenger?.name,
          value: itm?.passenger?.id,
        },
        defaultPassengerType: {
          label: itm?.defaultPassengerType?.name,
          value: itm?.defaultPassengerType?.id,
        },
      })),
    });
  }, []);
  const count = () => form?.getFieldValue("list")?.length;
  // console.log(
  //   "Filter",
  //   userList?.filter((employeeA: any) => {
  //     return !tripList?.some(
  //       (employeeB: any) => employeeB?.value === employeeA?.value
  //     );
  //   })
  //   // userList.filter((d: any) =>
  //   //   tripList?.some((f: any) => f?.value !== d?.value)/
  //   // )
  // );
  function getUserList() {
    let pList: any = form.getFieldValue("list");
    return userList?.filter((employeeA: any) => {
      return !pList?.some(
        (employeeB: any) => employeeB?.passenger?.value === employeeA?.value
      );
    });
  }

  const isValidRow = (row: any) => {
    if (row?.list && row?.list?.length > 0 && row?.trip) return true;
    else return false;
  };
  return (
    <Modal
      className="InvModal"
      open={true}
      closable={true}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={() => {
        props?.onDismiss();
      }}
      zIndex={1005}
      width={750}
      footer={
        <>
          <Button
            htmlType="submit"
            onClick={() => {
              onSave();
              //   let data = form?.getFieldsValue();
              //   console.log(form?.getFieldsValue());
            }}
          >
            Save
          </Button>
        </>
      }
    >
      <Spin
        spinning={loading}
        //   delay={500}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form
          name="passenger"
          className="InvAddForm"
          //   initialValues={{
          //     remember: true,
          //     list: [
          //       {
          //         // passenger: 0,
          //         isPresent: false,
          //         type: 0,
          //       },
          //     ],
          //   }}
          layout="horizontal"
          form={form}
          size="middle"
          //   wrapperCol={{ span: 8 }}
          style={{ maxWidth: 600 }}
          autoComplete="off"
        >
          <Form.Item
            label="Trip"
            name="trip"
            required
            wrapperCol={{ span: 10 }}
          >
            <Select
              placeholder={"Select Trip Schedule"}
              style={{ width: "100%" }}
              options={props?.tripOptions}
              labelInValue
              onSelect={(val: any) => {
                getTripOptions(val?.value);
              }}
            ></Select>
          </Form.Item>
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  //   <Space
                  //     key={key}
                  //     style={{ display: "flex", marginBottom: 8 }}
                  //     align="baseline"
                  //   >
                  <Row key={key} gutter={[10, 16]}>
                    <Col span={2}>
                      <Form.Item
                        {...restField}
                        name={[name, "isPresent"]}
                        valuePropName="checked"
                      >
                        <Checkbox>
                          {/* {form.getFieldValue([name, "isPresent"])} */}
                        </Checkbox>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item {...restField} name={[name, "passenger"]}>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Passenger"
                          labelInValue
                          options={getUserList()}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        {...restField}
                        name={[name, "defaultPassengerType"]}
                      >
                        <Select
                          style={{ width: "100%" }}
                          labelInValue
                          options={props?.statusOptions}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Col>
                  </Row>
                  //   </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      count();
                      add({
                        // passenger: 0,
                        isPresent: true,
                        // type: 0,
                      });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
        {/* <Button>Save</Button> */}
        <Row>{/* <Col></Col> */}</Row>
      </Spin>
    </Modal>
  );
};

export default AddPassenger;
