/* eslint-disable */

import {
  message,
  Input,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Spin,
  Popconfirm,
  Divider,
  Tag,
  Card,
  DatePicker,
} from "antd";
const { Search } = Input;
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useRef, useState } from "react";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../../../../Common/Common.css";
import { dayjs } from "../../../../../Utilities/dayjs";
import CabServices from "../../../../Services/CabServices";
import MasterHistory from "../../../Masters/MasterHistory";
import { EntitiesEnum } from "../../../../Services/LogService";
const Vehicle = () => {
  const inputRef = useRef<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [VehicleForm] = Form.useForm();
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [tableDataOriginal, setTableDataOriginal] = useState<any>([]);
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [updateToggle, setUpdateToggle] = useState("close");
  const [recordVal, setRecordVal] = useState<any>({});
  const [exist, setExist] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>({});
  const [fieldChange, setFieldChange] = useState<boolean>(false);
  const [listParams, setListParams] = useState<any>({
    name: "",
    company: "",
    start: 0,
    length: 15,
  });
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const tableLoading = {
    spinning: loading,

    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const [show, setShow] = useState<boolean>(false);

  const info = (msg: any) => {
    messageApi.success(msg);
  };

  // Message on Submit Error
  const errorMsg = (err: any) => messageApi.error(err);

  // Message on Already exist value
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "Vehicle Is Assigned  Can't be deleted",
    });
  };

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    setFieldChange(false);
    setLoading(false);
    VehicleForm.resetFields();
  };

  const subVariant = (saveTog: boolean) => {
    VehicleForm.validateFields().then((values) => {
      CabServices.postVehicle(values).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            VehicleForm.resetFields();
            setShouldRefresh((x) => !x);
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };
  //get list of variants
  const getVariants1 = (listParams: any) => {
    setLoading(true);
    CabServices.getVehicleList(
      listParams.name,
      listParams.company,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        let data: any = [];
        res?.items?.map((r: any) => {
          return data.push({
            key: r?.id,
            id: r.id,
            label: r?.name,
            value: r?.id,
            vehicleNo: r?.vehicleNo,
            chassisNo: r?.chassisNo,
            startDate:
              // r?.startDate === "0001-01-01T00:00:00"
              //   ? "-"
              //   :
              dayjs(r?.startDate).format("DD/MM/YYYY"),
          });
        });
        setTableData([...data]);
        setTableDataOriginal([...data]);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const editRowData = (editList: any) => {
    // console.log(editList, "EditList");

    CabServices.getVehicleById(editList?.value).then(async (res: any) => {
      VehicleForm.setFieldsValue({
        ...res,
        startDate: dayjs(new Date(res?.startDate)),
      });
      setEditData(res);
      setShow(true);
      setRecordVal(editList);
      setUpdateToggle("show");
      // await CabService.isExist(Masters.Vehicle, editList.value).then((res) => {
      //   if (res === true) {
      //     setExist(true);
      //   } else {
      //     setExist(false);
      //   }
      // });
    });
  };

  const updateData = () => {
    VehicleForm.validateFields().then(async (val) => {
      const newVal = { ...val, id: editData?.id };
      // console.log();
      await CabServices.postVehicle(newVal).then((res: any) => {
        if (res?.message) {
          messageApi.error(res?.message);
        } else {
          onDismiss();
          setUpdateToggle("close");
          info("Updated Successfully");
          setSelectedRowKeys("");
        }
      });
    });
  };

  const deleteList = (listId: string) => {
    // CabService.isExist(Masters.Vehicle, listId).then((res) => {
    //   if (res === true) {
    //     warning();
    //   } else {
    CabServices.getVehicleById(listId).then((res: any) => {
      if (res?.isEditAllowed) {
        CabServices.deleteVehicle(listId).then((res) => {
          if (res.status) {
            // errorMsg("Vehicle is Deleted Successfully.");
            messageApi.success("Vehicle is deleted successfully.");
            onDismiss();
          }
        });
      } else {
        messageApi.warning("Vehicle added by admin can't be deleted.");
      }
    });
  };

  const search = (e: any) => {
    setListParams({
      name: e.trim(),
      company: "",
      start: 0,
      length: 15,
    });
  };

  useEffect(() => {
    getVariants1(listParams);
    inputRef.current?.focus();
  }, [shouldRefresh, listParams]);

  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };

  const CheckChanges = (fieldName: string) => {
    if (editData[fieldName] === VehicleForm.getFieldValue(fieldName))
      setFieldChange(false);
    else setFieldChange(true);
  };

  // COLUMNS For Table
  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">VARIANT</span> */}
          <Search
            placeholder="Vehicle"
            className="InvSearchInput"
            allowClear
            size="small"
            bordered={false}
            onSearch={(e: any) => search(e)}
          />
        </div>
      ),
      dataIndex: "label",
      width: 240,

      render: (itm: any, record: any) => (
        <span
          // className="p-0 text-dark fw500"
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm}
        </span>
      ),
    },
    {
      title: <div className="">Vehicle No </div>,

      dataIndex: "vehicleNo",
      ellipsis: true,
      key: "vehicleNo",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">Chassis No </div>,

      dataIndex: "chassisNo",
      ellipsis: true,
      key: "chassisNo",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: <div className="">Date </div>,

      dataIndex: "startDate",
      ellipsis: true,
      key: "startDate",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: "  ",
      key: "operation",
      align: "right",
      render: (_, record) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            Edit
          </Button>{" "}
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.value)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              Delete
            </Button>
          </Popconfirm>
          {
            <Button
              type="text"
              icon={<HistoryOutlined />}
              size="small"
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              History
            </Button>
          }
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Vehicle"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        {/* <Row style={{ marginBottom: "10px" }} justify="end">
          <Col>
            <Button type="primary" onClick={() => setShow(true)}>
              Add New
            </Button>
          </Col>
        </Row> */}

        <Row>
          <Col lg={24}>
            <Table
              size="small"
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={tableLoading}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                // pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          open={show}
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          destroyOnClose={true}
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            VehicleForm.resetFields();
            setUpdateToggle("close");
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          // title={updateToggle === "close" ? "Add Vehicle" : "Update Vehicle"}
          title={
            updateToggle === "close" ? (
              "Add Vehicle"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Vehicle
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Vehicle"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>{" "} */}
              </>
            )
          }
          // onOk={() => {
          //   subVariant();
          // }}
          footer={
            <>
              {updateToggle === "close" ? (
                <div className="ModelFooterStyle">
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      VehicleForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subVariant(true);
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      subVariant(false);
                    }}
                  >
                    Save and New
                  </Button>{" "}
                </div>
              ) : (
                <div className="ModelFooterStyle">
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      VehicleForm.resetFields();
                      setUpdateToggle("close");
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  {/* 
                <Popconfirm
                  title={
                    exist === true
                      ? "Do you wan't to update this assigned Vehicle"
                      : "Update Vehicle"
                  }
                  // onConfirm={() =>  }
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                  onConfirm={() => updateData()}
                  onCancel={() => {
                    setShow(false);
                    VehicleForm.resetFields();
                    setUpdateToggle("close");
                  }}
                > */}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed}
                  >
                    Update
                  </Button>
                  {/* </Popconfirm> */}
                </div>
              )}
            </>
          }
        >
          {/* /^\S[\S\s]*$/ */}
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            name="variantBasic"
            className="InvAddForm"
            layout="vertical"
            form={VehicleForm}
            size="large"
            // wrapperCol={{ span: 18 }}
            // style={{ maxWidth: 800 }}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Row gutter={[16, 16]}>
              <Col lg={12}>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    {
                      // pattern: /^\b[a-zA-Z][^\s\W]*$/,
                      pattern: pattern,
                      message:
                        "Spaces, digits, and special symbols are not allowed at beginning or end of the input",
                    },
                    {
                      required: true,
                      message: "Please fill the field",
                    },
                    // {
                    //   whitespace: true,
                    // },
                  ]}
                >
                  <Input
                    placeholder="Enter Vehicle Name"
                    ref={inputRef}
                    onBlur={() => {
                      CheckChanges("name");
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item label="Vehicle No" name="vehicleNo">
                  <Input placeholder="Vehicle No." />
                </Form.Item>{" "}
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col lg={12}>
                <Form.Item label="Chassis No" name="chassisNo">
                  <Input placeholder="Chassis No." />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  initialValue={dayjs()}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Start Date"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.Vehicle}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default Vehicle;
