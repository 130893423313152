import {
  Modal,
  Divider,
  Row,
  Col,
  Form,
  Select,
  Button,
  InputNumber,
  message,
  Input,
  Empty,
  Spin,
  DatePicker,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useContext, useEffect, useState } from "react";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import ProductStateService from "../../Services/ProductStateService";
import { Category } from "../../Services/ProductService";
import TextArea from "antd/es/input/TextArea";
import "../../Common/Common.css";
import { dayjs } from "../../../Utilities/dayjs";
const { Option } = Select;

// import InfiniteScroll from 'react-infinite-scroll-component';
interface ModifyStateProps {
  mode: string;
  setShow: React.Dispatch<React.SetStateAction<string>>;
  category: Category;
  selectedRecord: any;
  onDismiss: (rec: boolean) => void;
}

const ModifyState = (props: ModifyStateProps) => {
  const { assigneeOptions } = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [addNewRowIndex, setAddNewRowIndex] = useState<number>(0);
  const [rowList, setRowList] = useState<any[]>([]);
  const [apiData, setApiData] = useState<any>([]);
  const [assignee, setAssignee] = useState<[]>(assigneeOptions);
  const [discardableAssignee, setDiscardableAssignee] = useState<any[]>([]);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [total, setTotal] = useState({
    given: 0,
    return: 0,
    used: 0,
    discard: 0,
    available: 0,
    total: 0,
    reason: "",
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = useForm();

  const AddNewRow = () => {
    form.validateFields().then((val: any) => {
      let arr: any = rowList;
      let saveObj: any = {};

      // eslint-disable-next-line array-callback-return
      val?.assignTo.map((item: any, idx: number) => {
        saveObj = {
          key: addNewRowIndex + idx,
          assignee: { id: item?.value, name: item?.label },
          productState: val?.productState,
          issueDate: val?.issueDate,
          givenStock: val?.productState === 1 ? val?.quantity : 0,
          usedStock: val?.productState === 2 ? val?.quantity : 0,
          returnedStock: val?.productState === 3 ? val?.quantity : 0,
          discardedStock: val?.productState === 4 ? val?.quantity : 0,
          reason: val?.reason,
        };
        arr.unshift(saveObj);
        setTotal({
          ...total,
          given: total.given + saveObj.givenStock * val?.assignTo?.length,
          used: total.used + saveObj.usedStock * val?.assignTo?.length,
          return: total.return + saveObj.returnedStock * val?.assignTo?.length,
          discard:
            total.discard + saveObj.discardedStock * val?.assignTo?.length,
        });

        // if Only Discard Selected //
        if (val?.productState === 4) {
          let index: any = discardableAssignee?.findIndex(
            (record: any) => record?.id === item?.value
          );
          if (index !== -1) {
            let adjust: any = {
              id: discardableAssignee[index]?.id,
              usedStock:
                discardableAssignee[index]?.usedStock - saveObj?.discardedStock,
              discardedStock:
                discardableAssignee[index]?.discardedStock +
                saveObj?.discardedStock,
            };
            discardableAssignee[index] = adjust;
            setDiscardableAssignee([...discardableAssignee]);
          }
        }
      });

      setAddNewRowIndex(saveObj?.key + 1);
      setRowList([...arr]);
      // form.resetFields();
      form.resetFields(["reason"]);
      form.resetFields(["assignTo"]);
      form.resetFields(["quantity"]);
    });
  };

  const deleteRow = (itm: any) => {
    if (itm?.productState === 4) {
      // if Only Discard Selected //

      let index: any = discardableAssignee?.findIndex(
        (record: any) => record?.id === itm?.assignee?.id
      );
      if (index !== -1) {
        let adjust: any = {
          id: discardableAssignee[index]?.id,
          usedStock:
            discardableAssignee[index]?.usedStock + itm?.discardedStock,
          discardedStock:
            discardableAssignee[index]?.discardedStock - itm?.discardedStock,
        };
        discardableAssignee[index] = adjust;
        setDiscardableAssignee([...discardableAssignee]);
      }
    }
    setRowList(rowList.filter((val: any) => val.key !== itm.key));
    setTotal({
      ...total,
      given: total.given - itm?.givenStock,
      used: total.used - itm?.usedStock,
      return: total.return - itm?.returnedStock,
      discard: total.discard - itm?.discardedStock,
    });
  };

  const onSave = async () => {
    if (rowList.length > 0) {
      setBtnDisabled(true);
      let val = rowList?.map(({ key, ...rest }) => ({
        ...rest,
        productId:
          props?.selectedRecord?.id || props?.selectedRecord?.productID,
        category: props.category,
      }));
      await ProductStateService.post(
        props?.selectedRecord?.id || props?.selectedRecord?.productID,
        props?.mode === "SUMMARY" ? props?.selectedRecord?.stockAdjID : "",
        props?.category,
        val
      ).then((res) => {
        if (res?.status) {
          props.onDismiss(true);
          form.resetFields();
          setBtnDisabled(false);
        } else {
          messageApi.error(res?.message);
          form.resetFields();
          setBtnDisabled(false);
        }
      });
    } else form.validateFields();
  };

  const validate = async (rule: any, value: any) => {
    const arr = form.getFieldValue("assignTo");
    const productState = form.getFieldValue("productState");

    let filterAssignee = apiData.filter((d: any) =>
      arr?.some((f: any) => f?.value === d?.assignee?.id)
    );

    // if (
    //   filterAssignee.length === 0 &&
    //   (productState === 2 || productState === 3 || productState === 4)
    // )
    //   throw new Error(
    //     `Assignee can't ${productState === 2
    //       ? "used"
    //       : productState === 3
    //         ? "return"
    //         : "discard"
    //     }.`
    //   );
    if (
      productState === 2 &&
      (arr?.length * value + total?.used > props?.selectedRecord?.inStock ||
        value === 0)
    ) {
      throw new Error("qty exceeds total");
    }
    // if (productState === 4 && arr?.length * value + total?.discard > props?.selectedRecord?.inStock) {
    //   throw new Error("cannot be discarded.");
    // }
    if (filterAssignee?.length > 0 && productState === 4) {
      filterAssignee?.map((itm: any) => {
        if (itm?.usedStock < value) {
          throw new Error("cannot be discarded.");
        }
      });
    }

    // if (filterAssignee?.length === 0 && productState === 4) {
    //   throw new Error("cannot be discarded.");
    // }
    // if (filterAssignee?.length > 0 && productState === 4) {
    //   filterAssignee?.map((itm: any) => {
    //     console.log("itm = ", itm)
    //   })
    // }
    // eslint-disable-next-line array-callback-return
    // filterAssignee.map((itm: any) => {
    //   if (
    //     productState === 2 &&
    //     arr?.length * value + total.used > itm?.available
    //   )
    //   {
    //     throw new Error(
    //       `Value can't be more than ${itm?.assignee?.name}'s available.`
    //     );
    //   }

    //   else if (
    //     productState === 4 &&
    //     arr?.length * value + total.discard > itm?.available
    //   )
    //   {
    //     throw new Error(
    //       `Value can't be more than ${itm?.assignee?.name}'s available.`
    //     );
    //   }
    // });
  };

  useEffect(() => {
    setLoading(true);
    ProductStateService.getGroupedList(
      props?.category,
      props?.selectedRecord?.id || props?.selectedRecord?.productID,
      props?.selectedRecord?.stockAdjID
    ).then((res) => {
      console.log(res);
      
      if (res) {
        setApiData(
          res?.items?.map((itm: any, i: number) => ({
            ...itm,
            key: i,
            assignee: itm?.assignee,
            givenStock: itm?.givenStock,
            issueDate: dayjs (itm?.issueDate)?.format("DD/MM/YYYY") ,
            returnedStock: itm?.returnedStock,
            usedStock: itm?.usedStock,
            discardedStock: itm?.discardedStock,
            available:
              itm?.givenStock -
              itm?.usedStock -
              itm?.returnedStock -
              itm?.discardedStock,
          }))
        );
        setDiscardableAssignee(
          res?.items?.map((itm: any, i: number) => ({
            id: itm?.assignee?.id,
            usedStock: itm?.usedStock,
            discardedStock: itm?.discardedStock,
          }))
        );
        setLoading(false);
        let discardAss = res?.items?.map((itm: any, i: number) => ({
          id: itm?.assignee?.id,
          usedStock: itm?.usedStock,
          discardedStock: itm?.discardedStock,
        }));

        if (props?.mode === "SUMMARY") {
          let filterAssignee = discardAss.filter((d: any) =>
            assignee?.some((f: any) => f?.value === d?.id)
          );
          setAssignee(
            assigneeOptions.filter((employeeA: any) => {
              return filterAssignee.some(
                (employeeB: any) => employeeB?.id === employeeA.value
              );
            })
          );
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });
  }, [props?.category, props?.selectedRecord?.id]);

  return (
    <>
      {contextHolder}
      <Modal
        className="InvModal_modifyState"
        okText="Save"
        zIndex={1010}
        open={true}
        title={`Modify State Of ${props?.selectedRecord?.productName}`}
        footer={
          <>
            <Button
              danger
              onClick={() => {
                props.setShow("");
                form.resetFields();
                props.onDismiss(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              ghost
              disabled={btnDisabled}
              onClick={onSave}
            >
              Save
            </Button>
          </>
        }
        width={1300}
        onCancel={() => {
          props.setShow("");
          form.resetFields();
          props.onDismiss(false);
        }}
        // style={{ top: 20, margin: "0 0 0 27vw" }}
      >
        <Form
          form={form}
          labelCol={{ span: 18 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          initialValues={{
            productState: props?.mode === "SUMMARY" ? 4 : 2,
          }}
          autoComplete="off"
          size="middle"
          className="InvAddForm"
        >
          <Row gutter={[24, 6]} style={{ marginTop: 10 }}>
            <Col lg={11}>
              <div className="font14 fw500">
                {props?.mode === "" ? (
                  <>Total In Stock :{props?.selectedRecord?.inStock}</>
                ) : (
                  <>Total Used Stock :{props?.selectedRecord?.committedStock}</>
                )}
              </div>

              {/* Assignee & State */}
              <Row
                gutter={[10, 0]}
                align={"middle"}
                style={{ marginTop: "10px" }}
              >
                <Col lg={6}>
                  <Form.Item name={"productState"} label={"State"}>
                    <Select
                      className="invFormSelect"
                      options={
                        // { label: "InUse", value: 1 },
                        props?.mode === ""
                          ? [
                              { label: "Used", value: 2 },
                              // { label: "Return", value: 3 },
                              { label: "Discard", value: 4 },
                            ]
                          : [{ label: "Discard", value: 4 }]
                      }
                      onSelect={(val: any) => {
                        form.resetFields(["assignTo"]);
                        form.resetFields(["quantity"]);
                        if (val === 4) {
                          let filterAssignee = discardableAssignee.filter(
                            (d: any) =>
                              assignee?.some((f: any) => f?.value === d?.id)
                          );
                          setAssignee(
                            assigneeOptions.filter((employeeA: any) => {
                              return filterAssignee.some(
                                (employeeB: any) =>
                                  employeeB?.id === employeeA.value
                              );
                            })
                          );
                        } else {
                          setAssignee(assigneeOptions);
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col lg={5}>
                  <Form.Item
                    name="quantity"
                    label="Qty"
                    rules={[
                      {
                        required: true,
                        message: "Fill Field.",
                      },
                      {
                        validator: validate,
                      },

                      {
                        pattern: new RegExp(/^\d{1,5}$/i),
                        message: "Invalid Qty",
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      controls={false}
                      placeholder="Qty"
                      type="number"
                      onBlur={(e: any) => {
                        if (form.getFieldValue("productState") === 4) {
                          let qty = form.getFieldValue("quantity");
                          let filterAssignee = discardableAssignee.filter(
                            (d: any) =>
                              assigneeOptions?.some(
                                (f: any) =>
                                  f?.value === d?.id &&
                                  d?.usedStock >= qty &&
                                  d?.usedStock > 0
                              )
                          );
                          setAssignee(
                            assigneeOptions.filter((employeeA: any) => {
                              return filterAssignee.some(
                                (employeeB: any) =>
                                  employeeB?.id === employeeA.value
                              );
                            })
                          );
                        }
                      }}
                      style={{ width: "100%", borderRadius: "3px" }}
                    />
                  </Form.Item>
                </Col>

                <Col lg={13}>
                  <Form.Item
                    name="issueDate"
                    label="Issue Date"
                    rules={[{ required: true, message: "Required" }]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <Form.Item
                    name="assignTo"
                    label={"Assignee"}
                    rules={[{ required: true, message: "Select Assignee" }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Select Assignee"
                      mode="multiple"
                      showSearch
                      maxLength={1}
                      maxTagTextLength={12}
                      allowClear
                      maxTagCount={1}
                      listItemHeight={50}
                      // className="invFormSelect"

                      filterOption={(input, option) =>
                        (option?.label?.toString() ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase().trim())
                      }
                      optionLabelProp="label"
                      labelInValue
                    >
                      {assignee.map((itm: any, index: any) => {
                        return (
                          <Option
                            value={itm?.value}
                            label={itm?.label}
                            key={index}
                            disabled={
                              form.getFieldValue("productState") === 4 &&
                              (form.getFieldValue("quantity") === undefined ||
                                form.getFieldValue("quantity") === null)
                            }
                          >
                            <Row>
                              <Col md={24}>
                                <span className=" fw600 font13 ">
                                  {itm?.label}
                                </span>
                              </Col>
                              <Row>
                                <Col md={24}>
                                  <span className="fontS fw500 ">
                                    {itm?.designation}
                                  </span>
                                </Col>{" "}
                                <Col md={24}>
                                  <span className="fontS fw500 ">
                                    {itm?.department}
                                  </span>
                                </Col>
                              </Row>
                            </Row>
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {/*  Reason */}
              <Row gutter={[24, 0]}>
                <Col lg={24}>
                  <Form.Item name="reason" label="Reason">
                    <TextArea
                      placeholder="Enter Reason"
                      autoSize={{ minRows: 3, maxRows: 3 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/* Add */}
              <Row>
                <Col lg={24} className="fw500">
                  <Button
                    type="primary"
                    ghost
                    size="middle"
                    onClick={AddNewRow}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* other */}
            <Col lg={13}>
              {/* Entries */}
              <div style={{ border: "1px solid #f0f0f0", padding: "5px 10px" }}>
                <Row
                  gutter={[10, 0]}
                  style={{ borderBottom: "1px solid #f0f0f0" }}
                >
                  <Col lg={7} md={5} className="text-dark font13 mb-2 fw600">
                    Assignee
                  </Col>
                  <Col
                    lg={4}
                    md={5}
                    className="text-dark font13 mb-2 fw600"
                    style={{ borderLeft: "1px solid #f0f0f0" }}
                  >
                    Issue Date
                  </Col>

                  <Col
                    lg={3}
                    md={3}
                    className="text-dark font13 mb-2 fw600"
                    style={{ borderLeft: "1px solid #f0f0f0" }}
                  >
                    Used
                  </Col>

                  <Col
                    lg={3}
                    md={5}
                    className="text-dark font13 mb-2 fw600"
                    style={{ borderLeft: "1px solid #f0f0f0" }}
                  >
                    Discard
                  </Col>
                  <Col
                    lg={7}
                    md={5}
                    className="text-dark font13 mb-2 fw600"
                    style={{ borderLeft: "1px solid #f0f0f0" }}
                  >
                    Reason
                  </Col>
                </Row>

                <div
                  style={{ maxHeight: 158, minHeight: 158, overflowY: "auto" }}
                >
                  {rowList?.length === 0 ? (
                    <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} />
                  ) : (
                    <>
                      {rowList.map((item: any, index: number) => (
                        <div key={index + 100}>
                          <Row gutter={[24, 0]}>
                            <Col lg={7} md={5} className="font13">
                              {item?.assignee?.name}
                            </Col>
                            <Col lg={4} md={5} className="font13">
                              {dayjs(item?.issueDate).format("DD/MM/YYYY")}
                            </Col>

                            <Col lg={3} md={3} className="font13">
                              {item?.usedStock}
                            </Col>

                            <Col lg={3} md={3} className="font13">
                              {item?.discardedStock}
                            </Col>
                            <Col lg={5} md={3} className="font13">
                              {item?.reason}
                            </Col>
                            <Col lg={1} md={2} className="font13">
                              <DeleteOutlined onClick={() => deleteRow(item)} />
                            </Col>
                            {rowList?.length - 1 === index ? null : (
                              <>
                                <Divider style={{ margin: "2px 0" }} />
                              </>
                            )}
                          </Row>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <>
                  <Row
                    gutter={[24, 0]}
                    className="fw500 pt-1 "
                    style={{
                      borderTop: "1px solid #f0f0f0",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Col lg={11} md={3} className="text-dark font13  fw600">
                      Total
                    </Col>

                    <Col lg={4} md={3} className="text-dark font13  fw600">
                      {total?.used}
                    </Col>

                    <Col lg={4} md={3} className="text-dark font13  fw600">
                      {total?.discard}
                    </Col>
                    <Col lg={8} md={3}></Col>
                  </Row>
                </>
              </div>
            </Col>
          </Row>
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            {apiData?.length === 0 ? null : (
              <>
                <Row gutter={[24, 6]}>
                  <Col lg={24} className="text-dark font13 mb-2 fw600">
                    <Divider style={{ marginBottom: 5 }} />
                    Previously saved assignee and stocks
                  </Col>
                </Row>
                <Row gutter={[24, 6]}>
                  <Col lg={24}>
                    <div
                      style={{
                        border: "1px solid #f0f0f0",
                        padding: "5px 10px",
                      }}
                    >
                      {apiData && apiData?.length > 0 ? (
                        <>
                          <Row
                            gutter={[24, 0]}
                            style={{ borderBottom: "1px solid #f0f0f0" }}
                          >
                            <Col
                              lg={5}
                              md={5}
                              className="text-dark font13 mb-2 fw600"
                            >
                              Assignee
                            </Col>
                            <Col
                              lg={5}
                              md={5}
                              className="text-dark font13 mb-2 fw600"
                              style={{ borderLeft: "1px solid #f0f0f0" }}
                            >
                              Issue Date
                            </Col>

                            <Col
                              lg={5}
                              md={3}
                              className="text-dark font13 mb-2 fw600"
                              style={{ borderLeft: "1px solid #f0f0f0" }}
                            >
                              Used
                            </Col>

                            <Col
                              lg={5}
                              md={5}
                              className="text-dark font13 mb-2 fw600"
                              style={{ borderLeft: "1px solid #f0f0f0" }}
                            >
                              Discard
                            </Col>
                          </Row>
                          <div style={{ maxHeight: 150, overflowY: "auto" }}>
                            {apiData?.map((item: any, idx: number) => (
                              <div key={idx + 200}>
                                <Row gutter={[24, 0]} key={idx}>
                                  <Col lg={5} md={5} className="font13">
                                    {item?.assignee?.name}
                                  </Col>
                                  <Col lg={5} md={5} className="font13">
                                    {item?.issueDate === "01/01/1901" ?"-":item?.issueDate}
                                  </Col>

                                  <Col lg={5} md={3} className="font13">
                                    {item?.usedStock}
                                  </Col>

                                  <Col lg={5} md={3} className="font13">
                                    {item?.discardedStock}
                                  </Col>

                                  {apiData?.length - 1 === idx ? null : (
                                    <>
                                      <Divider style={{ margin: "2px 0" }} />
                                    </>
                                  )}
                                </Row>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}

                      <Row
                        gutter={[24, 6]}
                        className="fw500 pt-1 "
                        style={{
                          borderTop: "1px solid #f0f0f0",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <Col lg={10} className="text-dark font13  fw600">
                          Total
                        </Col>
                        <Col lg={5} className="text-dark font13  fw600">
                          {apiData?.reduce((acc: any, curr: any) => {
                            return (acc += Number(curr?.usedStock));
                          }, 0) || 0}
                        </Col>
                        <Col lg={5} className="text-dark font13  fw600">
                          {apiData?.reduce((acc: any, curr: any) => {
                            return (acc += Number(curr?.discardedStock));
                          }, 0) || 0}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Spin>
        </Form>
      </Modal>
    </>
  );
};

export default ModifyState;
