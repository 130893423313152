import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { IUserAccessRights, IUserContent } from "../UserRights/UsersRightsType";

class UserRights {
  route = `v1/api/IUserRights`;
  getRights = (Role: string) => ApiUtility.getResult(`${this.route}`, { Role });

  getUserRole = (
    roleSearch: string,
    setLength: boolean,
    start: number,
    length: number
  ) =>
    ApiUtility.getResult(`${this.route}/GetUserRole`, {
      roleSearch,
      setLength,
      start,
      length,
    });

  getUserDetails = (id: string) =>
    ApiUtility.getResult("/API/Auth/GetUser", { id });

  post = (userRights: IUserAccessRights) =>
    ApiUtility.post(`${this.route}`, userRights);
}

export default new UserRights();
