import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class PurchaseService {
  route = `v1/api/CPurchases`;
  getPurchases = (id: string) => ApiUtility.getResult(`${this.route}/${id}`);

  getPurchasesOptions = (search: string, start?: number, length?: number) =>
    ApiUtility.getResult(`${this.route}`, {
      search,
      start,
      length,
    });

  getList = (
    recordStatusType: number,
    billNoSearch: string,
    materialNameSearch: string,
    itemNameSearch: string,
    vendorNameSearch: string,
    fromDate: string,
    toDate: string,
    sortCol: string,
    sortDir: string,
    start: number,
    length: number
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      recordStatusType,
      billNoSearch,
      materialNameSearch,
      itemNameSearch,
      vendorNameSearch,
      fromDate,
      toDate,
      sortCol,
      sortDir,
      start,
      length,
    });

  postPurchases = (params: any) => ApiUtility.post(`${this.route}`, params);

  delete = (id: any) => ApiUtility.postForm(`${this.route}/delete`, { id });
  restore = (id: any) => ApiUtility.postForm(`${this.route}/restore`, { id });
}

export default new PurchaseService();
