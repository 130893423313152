import React from 'react'

type Props = {}

const PaidSoftwareDashboard = (props: Props) => {
  return (
    <div>PaidSoftwareDashboard</div>
  )
}

export default PaidSoftwareDashboard