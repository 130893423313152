import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

interface ILoadingStackProps {
  label?: string;
}

export const FullPageSpinner = ({
  label = "Loading...",
}: ILoadingStackProps) => {
  return (
    <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }}>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -25,
          marginLeft: -75,
          width: 150,
          height: 50,
        }}
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        ></Spin>
      </div>
    </div>
  );
};

export const LoadingStack = ({ label = "Loading..." }: ILoadingStackProps) => {
  return (
    <div>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      ></Spin>
    </div>
  );
};
