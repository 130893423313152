import { TeamOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { ApplicationRouteLink } from "../../Routes/ApplicationRoutePath";

type Props = {};

const Setting = (props: Props) => {
  const navigate: any = useNavigate();
  return (
    <>
      <Row>
        <Col>
          <Space size="middle">
            <TeamOutlined style={{ fontSize: "30px" }} />
            <Button
              type="link"
              style={{ fontSize: "20px" }}
              onClick={() => {
                navigate(ApplicationRouteLink.UserAccess.split("/").pop());
              }}
            >
              User Access Rights
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default Setting;
