import {
  Badge,
  Button,
  Card,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  theme,
} from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { ColumnsType } from "antd/es/table";
import {
  ApprovalStatusOption,
  CategoryOption,
  ProductStatusFlag,
  pageSizeOption,
} from "../../../Context/ApplicationContext/ApplicationState";
import DashboardServices, {
  FilterTypeCategory,
} from "../../../Services/DashboardServices";
import { message } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../../../Common/Common.css";
type Props = {};
const { Search } = Input;

const CategoryData: any = {
  1: "Cleaning",
  2: "Stationary",
  3: "Garden or Plants",
  4: "Emergency Medical Kit",
  5: "Paid Software",
  6: "Digital Products",
  7: "Office Equipments",
};

const THeader = ({
  title,
  inputName,
  setShouldRefresh,
  changeListParams,
  ifSelect,
  listParams,
  setListParams,
  option,
  defaultValue,
}: any) => {
  return (
    <>
      <div
        className="searchDivStyle"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {ifSelect ? (
          <Select
            allowClear
            style={{ width: "100%" }}
            onSelect={(value) => {
              changeListParams(inputName, value);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            onClear={() => {
              changeListParams(inputName);
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            popupMatchSelectWidth={false}
            bordered={false}
            size="small"
            className="invSelectVendor"
            placeholder={title}
            options={option}
            // defaultValue={defaultValue}
          />
        ) : (
          <Search
            placeholder={title}
            className="InvSearchInput"
            allowClear
            onChange={(e) => changeListParams(inputName, e.target?.value)}
            onSearch={(val) => {
              setListParams({ ...listParams, start: 0 });
              setShouldRefresh((x: boolean) => !x);
            }}
            size="small"
            bordered={false}
          />
        )}
      </div>
    </>
  );
};

const Approval = (props: Props) => {
  const [tableData, setTableData] = useState<any>([]);
  const [showIsApproveAll, setShowIsApproveAll] = useState(false);
  const [approveRequestList, setApproveRequestList]: any = useState([]);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  const [listParams, setListParams] = useState<any>({
    productname: "",
    category: FilterTypeCategory.All,
    subcategory: "",
    userName: "",
    start: 0,
    length: 15,
    ProductStatusFlag: ProductStatusFlag.Pending,
  });
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const [totalNoOfRecords, setTotalNoOfRecords] = useState<number>(0);
  const [messageApi, contextHolder] = message.useMessage();
  const PostData = useCallback((list: any[], flag: ProductStatusFlag) => {
    let Ids: any = [];
    list.map((item: any) => {
      Ids.push(item?.key);
    });
    DashboardServices.postList(Ids, flag).then((res: any) => {
      if (res?.status) {
        info("Approved Successfully");
        setRefresh((x) => !x);

        // GetData();
      } else {
        error(res?.errors);
        setRefresh((x) => !x);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const DeleteData = useCallback((list: any, flag: ProductStatusFlag) => {
    let Ids: any = [];
    list.map((item: any) => {
      Ids.push(item?.key);
    });
    DashboardServices.postList(Ids, flag).then((res: any) => {
      if (res?.status) {
        info("Delete Successfully");
        setRefresh((x) => !x);

        // GetData();
      } else {
        error(res?.errors);
        setRefresh((x) => !x);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const info = (msg: string) => {
    messageApi.success(msg, 1);
  };
  const error = (mes: string) => {
    messageApi.error(mes);
  };
  const GetData = (listParams: any) => {
    setLoading(true);
    DashboardServices.getApprovalList(
      listParams?.start,
      listParams?.length,
      listParams?.ProductStatusFlag,
      listParams?.category,
      listParams?.productname,
      listParams?.subcategory,
      listParams?.userName
    ).then((r: any) => {
      let data: any = [];
      // eslint-disable-next-line array-callback-return
      r?.items?.map((item: any) => {
        data.push({
          key: item.id,
          productname: item?.name,
          category: item?.category,
          subcategory: item?.subCategory?.name,
          addedBy: item?.createdBy?.userName,
          stockQuantity: item?.numberOfStock,
          status: item?.productStatusFlag,
        });
      });
      setTableData([...data]);

      setTotalNoOfRecords(r?.totalRecords);
      setLoading(false);
    });
  };

  const rowSelection = {
    selectedRowKeys: checkedKeys,
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setCheckedKeys(selectedRowKeys);
      let arr: any = [];

      // eslint-disable-next-line array-callback-return
      selectedRows.map((item: any) => {
        arr.push(item);
      });
      setApproveRequestList([...arr]);
      selectedRows.length > 0
        ? setShowIsApproveAll(true)
        : setShowIsApproveAll(false);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.status === 1 || record.status === 3, // Column configuration not to be checked
      status: record.status,
    }),
  };

  useEffect(() => {
    GetData(listParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, refresh]);

  // Filter Functions //

  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"Product Name"}
          inputName={"productname"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "productname",
      key: "productname",

      render: (productname: string) => (
        <div className="p-1 text-dark ">{productname}</div>
      ),
    },
    {
      title: (
        <>
          <THeader
            title={"Category"}
            inputName={"category"}
            changeListParams={changeListParams}
            setShouldRefresh={setRefresh}
            listParams={listParams}
            setListParams={setListParams}
            ifSelect={true}
            option={CategoryOption}
            defaultValue={{ label: "All", value: "0" }}
          />
        </>
      ),
      dataIndex: "category",
      key: "category",
      // responsive: ["xl"],
      render: (category: string) => (
        <span className="ps-2 font13">{CategoryData[category]}</span>
      ),
    },
    {
      title: (
        <THeader
          title={"Sub-Category"}
          inputName={"subcategory"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "subcategory",
      key: "subcategory",
      responsive: ["xl"],
      render: (subcategory: string) => (
        <span className="ps-2 font13">{subcategory}</span>
      ),
    },
    {
      title: (
        <THeader
          title={"Added By"}
          inputName={"userName"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "addedBy",
      key: "addedBy",
      render: (addedBy: string) => (
        <span className="ps-2 font13">{addedBy}</span>
      ),
    },
    {
      title: "Qty",
      dataIndex: "stockQuantity",
      key: "stockQuantity",
      sorter: {
        compare: (a: any, b: any) => a.stockQuantity - b.stockQuantity,
      },

      render: (stockQuantity: string) => (
        <div className="ps-2 font13 text-end">{stockQuantity}</div>
      ),
    },
    {
      title: (
        <THeader
          title={"Status"}
          inputName={"ProductStatusFlag"}
          changeListParams={changeListParams}
          setShouldRefresh={setRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={true}
          option={ApprovalStatusOption}
          defaultValue={{ label: "Pending", value: 2 }}
        />
      ),
      dataIndex: "status",
      key: "status",
      width: "140px",
      render: (result: any, record: any) => (
        <>
          {result === ProductStatusFlag.Approved ? ( // show Approved
            <Badge status="success" text="Approved" />
          ) : result === ProductStatusFlag.Pending ? ( // show Pending
            <>
              <Row justify={"space-between"}>
                <Popconfirm
                  title="Do you want to Approve record ?"
                  onConfirm={() => {
                    PostData([record], ProductStatusFlag.Approved);
                    setApproveRequestList(
                      approveRequestList.filter(
                        (item: any) => item.toString() !== record.key.toString()
                      )
                    );
                    if (approveRequestList.length <= 1) {
                      setShowIsApproveAll(false);
                    }
                  }}
                  okText="Yes"
                  cancelText="No"
                  placement="top"
                  icon={<InfoCircleTwoTone />}
                >
                  <Badge
                    status="warning"
                    text="Pending"
                    className="cursorPointer"
                  />
                </Popconfirm>
                <Popconfirm
                  title="Do you want to Delete record ?"
                  onConfirm={() => {
                    DeleteData([record], ProductStatusFlag.Delete);
                    setApproveRequestList(
                      approveRequestList.filter(
                        (item: any) => item.toString() !== record.key.toString()
                      )
                    );
                    if (approveRequestList.length <= 1) {
                      setShowIsApproveAll(false);
                    }
                  }}
                  okText="Yes"
                  cancelText="No"
                  placement="topLeft"
                  icon={<DeleteOutlined style={{ color: "red" }} />}
                >
                  <DeleteOutlined style={{ color: "red" }} size={200} />
                </Popconfirm>
              </Row>
            </>
          ) : (
            // show Deleted
            <>
              <Badge status="error" text="Deleted" />
            </>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <Card
        title={"Approval"}
        bordered={false}
        className="mt-4"
        extra={
          <>
            {showIsApproveAll ? (
              <Button
                type="primary"
                ghost
                size="middle"
                style={{ fontWeight: "600" }}
                icon={<PlusOutlined style={{}} />}
                onClick={() => {
                  Modal.confirm({
                    zIndex: 1020,
                    title: "Confirmation",
                    icon: <ExclamationCircleOutlined />,
                    content: "Do you want to Approve all records?",
                    okText: "Yes",
                    cancelText: "No",
                    onOk: () => {
                      PostData(approveRequestList, ProductStatusFlag.Approved);
                      setShowIsApproveAll(false);
                      setCheckedKeys([]);
                    },
                  });
                }}
              >
                Approve All
              </Button>
            ) : null}
          </>
        }
      >
        {/* <div className="mt-4">
          {showIsApproveAll ? (
            <Button
              type="primary"
              className="invBtn"
              onClick={() => {
                Modal.confirm({
                  zIndex: 1020,
                  title: "Confirmation",
                  icon: <ExclamationCircleOutlined />,
                  content: "Do you want to Approve all records?",
                  okText: "Yes",
                  cancelText: "No",
                  onOk: () => {
                    PostData(approveRequestList, ProductStatusFlag.Approved);
                    setShowIsApproveAll(false);
                    setCheckedKeys([]);
                  },
                });
              }}
            >
              Approve All
            </Button>
          ) : null} */}

        {/* Table */}
        {window.innerWidth < 600 ? (
          <>
            <div className="mt-5">
              <Table
                columns={columns}
                dataSource={tableData}
                // className="InvTable"
                loading={tableLoading}
                rowSelection={rowSelection}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  total: totalNoOfRecords,
                  onChange: (page, pageSize) => {
                    changeListParams("start", (page - 1) * pageSize);
                    changeListParams("length", pageSize);
                    // setShouldRefresh((x) => !x);
                    setRefresh((x) => !x);
                  },
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
              />
            </div>
          </>
        ) : (
          // <div className="mt-4">
          <Table
            columns={columns}
            dataSource={tableData}
            // className="InvTable"
            loading={tableLoading}
            rowSelection={rowSelection}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: listParams.start / listParams.length + 1,
              pageSize: listParams.length,
              showSizeChanger: true,
              total: totalNoOfRecords,
              onChange: (page, pageSize) => {
                changeListParams("start", (page - 1) * pageSize);
                changeListParams("length", pageSize);
                // setShouldRefresh((x) => !x);
                setRefresh((x) => !x);
              },
              pageSizeOptions: pageSizeOption,
            }}
            scroll={{ y: `calc(100vh - 330px)` }}
          />
          // </div>
        )}
        {/* </div> */}
      </Card>
    </>
  );
};

export default Approval;
