import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Space,
  Button,
  TabsProps,
  message,
  Form,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../Context/ApplicationContext/CreateApplicationContext";
import VendorService, { IApiDataProps } from "../../Services/VendorService";
const { TextArea } = Input;
const { Option } = Select;
interface IAddEditVendorProps {
  // vendor?: IApiDataProps[];
  vendorId?: any;
  onDismiss: (rec: boolean) => void;
  // setShow: any;
  setShouldRefresh: () => void;
}
export const VendorType = [
  { label: "Product", value: 0 },
  { label: "Construction", value: 1 },
];
export const CompanyType = [
  { label: "Supplier & Service Provider", value: 0 },
  { label: "Supplier", value: 1 },
  { label: "Service Provider", value: 2 },
];
const AddEditVendor = (props: IAddEditVendorProps) => {
  const { getVendorOptions } = useContext(ApplicationContext);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [cardLoading, setCardLoading] = useState(false);
  // const [form1Values, setForm1Values] = useState({});
  const [activeKey, setActiveKey] = useState("0");
  const [form1] = Form.useForm();
  // const [form2] = Form.useForm();
  const inputRef = React.useRef<any>(null);
  const selectRef = React.useRef<any>(null);
  const [vendorData, setVendorData] = useState<any>({
    id: "",
    name: "",
    contactPerson: "",
    workPhone: "",
    mobileNo: "",
    email: "",
    companyType: 0,
    description: "",
    country: "",
    zipCode: "",
    street1: "",
    street2: "",
    city: "",
    state: "",
    isEditAllowed: false,
    issueDate: "",
  });

  useEffect(() => {
    getVendorById();
  }, [props?.vendorId]);

  const getVendorById = () => {
    if (props?.vendorId) {
      setCardLoading(true);
      VendorService.get(props?.vendorId).then((res) => {
        if (res) {
          form1.setFieldsValue({ ...res });
          setCardLoading(false);
          // setVendorData({ ...res });
          // setLoading(false);
        }
      });
    }
  };
  // const onSaveAndContinue = () => {
  //   form1.validateFields().then((val: any) => {
  //     // setForm1Values(val);
  //     setActiveKey("2");
  //   });
  // };
  const info = (msg: any) => {
    messageApi.success(msg);
  };
  const error = (mes: any) => {
    messageApi.error(mes);
  };
  const resetVendor = () => {
    form1.resetFields();
    props.setShouldRefresh();
    // form2.resetFields();
    // setActiveKey("1");
  };

  const onSave = (onlySave: boolean) => {
    form1.validateFields().then(async (values) => {
      let val = { id: props?.vendorId ? props?.vendorId : "", ...values };
      setButtonDisable(true);
      await VendorService.post(val).then((res) => {
        if (res?.status) {
          info("Vendor Added Successfully");
          setTimeout(() => {
            onlySave ? dismiss() : resetVendor();
          }, 1000);
          getVendorOptions();
        } else {
          error(res?.message);
        }
      });
      setButtonDisable(false);
    });
  };

  const dismiss = () => {
    props.onDismiss(true);
    form1.resetFields();
  };

  useEffect(() => {
    setTimeout(() => {
      activeKey === "1"
        ? inputRef.current?.focus()
        : selectRef.current?.focus();
    }, 5);
  }, [activeKey]);

  return (
    <>
      {contextHolder}
      <Card
        size="small"
        style={{ marginBottom: 20 }}
        loading={cardLoading}
        // title="Add new vendor(s)"
        // bordered={false}
      >
        <Form
          form={form1}
          // onFinish={onSaveAndContinue}
          layout="vertical"
          initialValues={{ remember: true }}
          colon={false}
          requiredMark={false}
          labelAlign="left"
          // labelCol={{ span: 7 }}
          className="InvAddForm"
          // size='small'
          // wrapperCol={{ span: 16 }}
          autoComplete="off"
          // style={{ maxWidth: 800 }}
          // size="large"
          // className="InvAddForm"
        >
          <Row gutter={[18, 18]}>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Company Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input company name",
                  },
                  // {
                  //   pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                  //   message: "Please input alphabets only!",
                  // },
                  {
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  //  ref={inputRef}
                  placeholder="Company name"
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Contact Person"
                name="contactPerson"
                rules={[
                  // { required: true, message: "Please input contact" },
                  {
                    pattern: new RegExp(/^[a-zA-Z_ ./,-]+$/i),
                    message: "Please input alphabets only!",
                  },
                ]}
              >
                <Input placeholder="Contact person" />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
              <Form.Item label="Known For" name="companyType">
                <Select
                  allowClear
                  placeholder="Select known for"
                  options={CompanyType}
                >
                  {/* <Option value="0">Supplier & Service Provider</Option>
                    <Option value="1">Supplier</Option>
                    <Option value="2">Service Provider</Option> */}
                </Select>
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
              <Form.Item
                label="Type"
                name="vendorType"
                rules={[
                  {
                    required: true,
                    message: "Please input Type",
                  },
                ]}
              >
                <Select
                  options={VendorType}
                  allowClear
                  placeholder="Select Type"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[18, 18]}>
            <Col lg={{ span: 5 }} md={{ span: 16 }}>
              <Form.Item
                label="GST No."
                name="gstNumber"
                rules={[
                  {
                    pattern: new RegExp(
                      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
                    ),
                    message: "Please input valid GST number!",
                  },
                ]}
              >
                <Input placeholder="GST No." maxLength={15} />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Phone No."
                name="workPhone"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input phone number!",
                  // },
                  {
                    pattern: new RegExp(/^\d{10}$/),
                    message: "Please input valid number!",
                  },
                ]}
              >
                <Input placeholder="Phone no." />
              </Form.Item>
            </Col>
            {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Mobile No."
                name="mobileNo"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please input mobile number!",
                  // },
                  {
                    pattern: new RegExp(/^\d{10}$/),
                    message: "Please input valid number!",
                  },
                ]}
              >
                <Input placeholder="Mobile no." />
              </Form.Item>
            </Col> */}
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  // { required: true, message: "Please input email!" },
                  {
                    type: "email",
                    message: "Please input valid email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} md={{ span: 16 }}>
              <Form.Item label="Description" name="description">
                <TextArea
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  placeholder="Description"
                />
              </Form.Item>
            </Col>

            {/* <Col lg={{ span: 5 }} sm={{ span: 12 }} xs={{ span: 18 }}>
              <Form.Item label="City" name="city">
                <Input placeholder="City" />
              </Form.Item>
            </Col> */}
            {/* <Col lg={{ span: 4 }} sm={{ span: 12 }}>
              <Form.Item
                label="Zip code"
                name="zipCode"
                rules={[
                  {
                    pattern: new RegExp(/^\d{6}$/),
                    message: "Please input valid zipCode",
                  },
                ]}
              >
                <Input placeholder="ZipCode" />
              </Form.Item>
            </Col> */}
            {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item label="State" name="state">
                <Input placeholder="State" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={[18, 18]}>
            {/* <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item label="Country" name="country">
                <Select allowClear placeholder="Select country">
                  <Option value="India">India</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item label="Address 1" name="street1">
                <Input
                  // ref={selectRef}
                  // autoSize={{ minRows: 1, maxRows: 6 }}
                  placeholder="Address 1"
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item label="Address 2" name="street2">
                <Input
                  // autoSize={{ minRows: 1, maxRows: 6 }}
                  placeholder="Address 2"
                />
              </Form.Item>
            </Col>
            <Col lg={{ span: 5 }} sm={{ span: 12 }}>
              <Form.Item
                label="Zip Code"
                name="zipCode"
                rules={[
                  {
                    pattern: new RegExp(/^\d{6}$/),
                    message: "Please input valid zipCode",
                  },
                ]}
              >
                <Input placeholder="ZipCode" />
              </Form.Item>
            </Col>
          </Row>

          {/* <Row gutter={[24, 12]}>
          
          </Row> */}
          <Row justify={"end"}>
            <Space>
              <Button
                htmlType="submit"
                type="primary"
                ghost
                onClick={() => onSave(true)}
                disabled={buttonDisable}
              >
                Save
              </Button>
              {(props?.vendorId).length === 0 ? (
                <Button
                  type="primary"
                  ghost
                  onClick={() => onSave(false)}
                  disabled={buttonDisable}
                >
                  Save and New
                </Button>
              ) : null}

              <Button
                danger
                onClick={() => {
                  form1.resetFields();
                  props.onDismiss(false);
                }}
              >
                Cancel
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default AddEditVendor;
