import { ApiUtility } from "../../Utilities/ApiUtility.axios";
export enum PurchaseType {
  UPI,
  Cash,
  Cheque,
  NetBanking,
  Card,
}
export enum StockType {
  SingleStock,
  StockWithVariant,
  Variant,
}
export enum Category {
  Cleaning = 1,
  Stationary,
  Garden,
  MedicalKit,
  PaidSoftware,
  DigitalProducts,
  OfficeEquipments,
}
export interface IProductApiProps {
  id: string;
  name: string;
  index?: number;
  category: Category;
  stockType: StockType;
  subCategory: { id: string; name: string };
  itemId: string;
  brand: string;
  vendor: { id: string; name: string };
  purchaseDate: string;
  invoiceNumber: string;
  purchaseType: PurchaseType;
  transactionId: string;
  numberOfStock: number;
  tax: number;
  pricePerUnit: number;
  totalPrice: number;
  expiryDate: string;
  isEditAllowed: boolean;
  unit?: { id: string; name: string };
  productGroup?: { id: string; name: string };
  //digital products /variants props
  variantType?: { id: string; name: string };
  variantOption?: { id: string; name: string };
  variants?: any;
  productStatusFlag?: number;
  seatAllotted?: { id: string; name: string };
  status?: any;
  assignee?: any;
  workFromHome?: boolean;
  issueDate?: any;
  description?: string;
  serialNo?: string;
  currency?: any;
  gst?: { name: string; id: string };
  totalAmount?: 0;
  subscription?: {
    startDate: string;
    endDate: string;
  };
  gstAmount?: 0;
  projectName?: string;
  startDate?: string;
  endDate?: string;
}

class ProductService {
  // route = `${process.env.REACT_APP_API_BASE_URL}/v1/api/Inv_Product`
  route = `/v1/api/IProducts`;
  routeSummary = `/v1/api/ICommittedProducts`;
  get = (id: string) => ApiUtility.getResult(`${this.route}/${id}`);

  getList = (
    category: Category,
    // stockType: StockType,
    nameSearch: string,
    subCategorySearch: string,
    productGroupSearch: string,
    assigneeSearch: string,
    seatName: string,
    status: string,
    digiStatus: string,
    start: number,
    length: number,
    sortCol: string,
    sortDir: string,
    workFromHome?: boolean,
    fromDate?: string,
    toDate?: string,
    startDate?: string,
    endDate?: string
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      category,
      // stockType,
      nameSearch,
      subCategorySearch,
      productGroupSearch,
      assigneeSearch,
      status,
      digiStatus,
      start,
      length,
      seatName,
      sortCol,
      sortDir,
      workFromHome,
      fromDate,
      toDate,
      startDate,
      endDate,
    });

  post = (params?: any) => ApiUtility.post(`${this.route}`, params);

  delete = (id: string) => ApiUtility.postForm(`${this.route}/delete`, { id });

  getVariants = (id: string, category: Category) =>
    ApiUtility.getResult(`${this.route}/getVariant`, {
      productId: id,
      category: category,
    });

  saveAdjustedStock = (params?: any) =>
    ApiUtility.post(`${this.route}/stockAdjustment`, params);

  verifySeating = (subcategoryId: string, seatId: string) =>
    ApiUtility.postForm(`${this.route}/verifySeating`, {
      subcategoryId,
      seatId,
    });

  moveMultipleProduct = (
    ids: string[],
    seatAllotted: { id: string; name: string }
  ) => ApiUtility.postForm(`${this.route}/moveMultiple`, { ids, seatAllotted });

  moveProduct = (params: any) =>
    ApiUtility.post(`${this.route}/moveProduct`, params);

  productOptions = (
    category: Category,
    search: string,
    start?: number,
    length?: number
  ) =>
    ApiUtility.getResult(`${this.route}/productOptions`, {
      category,
      search,
      start,
      length,
    });

  getDPHistory = (id: string, start?: number, length?: number) =>
    ApiUtility.getResult(`${this.route}/digitalProductLog`, {
      id,
      start,
      length,
    });

  getSummaryList = (
    productID: string,
    assignee: string,
    sortDir: string,
    sortCol: string,
    fromDate: string,
    toDate: string,
    start: number,
    length: number
  ) =>
    ApiUtility.getResult(`${this.routeSummary}`, {
      productID,
      assignee,
      sortDir,
      sortCol,
      fromDate,
      toDate,
      start,
      length,
    });
}

export default new ProductService();
