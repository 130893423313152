import React from 'react'

type Props = {}

const StationeryDashboard = (props: Props) => {
    return (
        <div>StationeryDashboard</div>
    )
}

export default StationeryDashboard