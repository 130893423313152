import React from 'react'
import ProductList from '../../../Components/ParentCategory-A/ProductList'
import { Category } from '../../../Services/ProductService'

type Props = {}

const EmergencyMedicalKit = (props: Props) => {
  return (
    <><ProductList category={Category.MedicalKit } />
    </>
  )
}

export default EmergencyMedicalKit