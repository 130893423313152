import React from "react";
import DigitalProductList from "../../../Components/ParentCategory-B/DigitalProductList";
import { Category } from "../../../Services/ProductService";

type Props = {};

const DigitalProduct = (props: Props) => {
  return <DigitalProductList category={Category.DigitalProducts} />;
};

export default DigitalProduct;
