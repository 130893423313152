import { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import { ColumnsType } from "antd/es/table";
import MasterService, { Masters } from "../../../Services/MasterService";
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  LoadingOutlined,
  // LockFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../../Utilities/dayjs";
// import ApplicationContext from "../../../Context/ApplicationContext/CreateApplicationContext";
import { Category } from "../../../Services/ProductService";
import MasterHistory from "../MasterHistory";
import { EntitiesEnum } from "../../../Services/LogService";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
const { Search } = Input;

const AddInOption = ({ funcType, setShouldRefresh }: any) => {
  const [name, setName] = useState("");
  const inputRef = useRef<InputRef>(null);
  // const {} = useContext(ApplicationContext);
  const [status, setStatus] = useState<any>("success");
  const [messageApi, contextHolder] = message.useMessage();

  const onNameChange = (e: any) => {
    setName(e.target.value);
  };
  const addOption = (e: any) => {
    e?.stopPropagation();
    if (name.trim() === "") {
      setStatus("error");
    } else {
      setStatus("success");
      if (funcType === "subcategory")
        MasterService.postSubCategory({
          name: name,
          category: Category.DigitalProducts,
        }).then((r: any) => {
          if (r.status === true) {
            inputRef.current?.focus();
            setName("");
            setShouldRefresh((x: any) => !x);
          } else {
            messageApi.error(r?.message);
          }
        });
    }
  };

  return (
    <>
      {" "}
      {contextHolder}
      <Divider style={{ margin: "8px 0" }} />
      <Space style={{ padding: "0 0px 4px", width: "100%" }}>
        <Input
          placeholder="Enter Option"
          ref={inputRef}
          value={name}
          status={status}
          onChange={onNameChange}
          onPressEnter={addOption}
        />
        <Button type="text" icon={<PlusOutlined />} onClick={addOption} />
      </Space>
    </>
  );
};

const ProductGroup = (props: any) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [exist, setExist] = useState<boolean>(false);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [updateToggle, setUpdateToggle] = useState(true);
  const [subCategoryOptions, setSubCategoryOptions] = useState<any>([]);
  const [listParams, setListParams] = useState<any>({
    ProductGroupSearch: "",
    subCategorySearch: "",
    start: 0,
    length: 15,
  });
  const [editData, setEditData] = useState<any>({});
  const [showDrawer, setShowDrawer] = useState<string>("");
  const [selectedRecord, setSelectedRecord] = useState<any>();
  let pattern = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s]*[a-zA-Z0-9])?$/;

  const inputRef = useRef<any>(null);
  const [selectedValues, setSelectedValues] = useState([]);

  const handleSelectChange = (values: any) => {
    // console.log(values, "val");

    // Compare previous and current selected values
    const addedValues = values.filter(
      (value: never) => !selectedValues.includes(value)
    );
    const removedValues = selectedValues.filter(
      (value) => !values.includes(value)
    );

    // Perform actions based on the changes
    if (addedValues.length > 0) {
      // console.log("Added:", addedValues);
    }

    if (removedValues.length > 0) {
      // console.log("Removed:", removedValues);
    }

    setSelectedValues(values);
  };

  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 34 }} spin />;
  const info = (msg: any) => {
    messageApi.success(msg);
  };
  const errorMsg = (err: any) => messageApi.error(err);

  const onDismiss = () => {
    setShouldRefresh((x) => !x);
    setShow(false);
    setShowDrawer("");
    setLoading(false);
    form.resetFields();
  };
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "ProductGroup Is Assigned  Can't be deleted",
    });
  };
  const getProductGroupList = (listParams: any) => {
    MasterService.getProductGroupList(
      listParams.ProductGroupSearch,
      listParams.subCategorySearch,
      true,
      listParams.start,
      listParams.length
    ).then((res: any) => {
      if (res !== null || res?.totalRecords > 0) {
        setTableData(
          res?.items?.map((data: any, i: number) => ({
            key: i,
            id: data?.id,
            subCategory: {
              label: data?.subCategory?.name,
              value: data?.subCategory?.id,
            },
            name: data?.name,
            createdBy: dayjs(data?.createdBy?.date).format("DD/MM/YYYY"),
          }))
        );
        setTotalRecords(res?.totalRecords);
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      }
    });
  };

  const saveProductGroups = (saveTog: boolean) => {
    setBtnLoading(true);
    form.validateFields().then((values) => {
      let val = {
        name: values?.name.trim(),
        subCategory: {
          id: values?.subCategory?.value,
          name: values?.subCategory?.label,
        },
      };
      MasterService.postProductGroup(val).then((res) => {
        if (res?.status === true) {
          info("submitted");
          if (saveTog === true) {
            onDismiss();
          } else {
            form.resetFields();
            setShouldRefresh((x: boolean) => !x);
          }
        } else {
          errorMsg(res?.message);
        }
      });
    });
  };

  const editRowData = async (item: any) => {
    MasterService.getProductGroup(item?.id).then(async (res: any) => {
      let listData: any = {
        ...res,
        subCategory: {
          label: res?.subCategory?.name,
          value: res?.subCategory?.id,
        },
      };
      setEditData(res);
      setShow(true);
      setUpdateToggle(false);
      // form.setFieldsValue({ ...item });
      await MasterService.isExist(Masters.ProductGroup, item.id).then((res) => {
        if (res === true) setExist(true);
        else setExist(false);
      });

      form.setFieldsValue(listData);
    });
  };

  const updateData = () => {
    let values = form.getFieldsValue(true);
    form.validateFields().then(async () => {
      let val: any = {
        name: values?.name.trim(),
        id: values?.id,
        subCategory: {
          id: values?.subCategory?.value,
          name: values?.subCategory?.label,
        },
      };

      await MasterService.postProductGroup(val).then((res: any) => {
        if (res?.result === null) {
          errorMsg(res?.message);
        } else {
          onDismiss();
          setUpdateToggle(true);
          info("Updated Successfully");
          setSelectedRowKeys("");
        }
      });
      // MasterService.postProductGroup(val).then(() => {
      //   onDismiss();
      //   setUpdateToggle(true);
      //   info("Updated Successfully");
      // });
    });
  };

  const search = (val: any, from: string) => {
    if (from === "subcategory") {
      setListParams({
        ProductGroupSearch: "",
        subCategorySearch: val.trim(),
        start: 0,
        length: 15,
      });
    } else {
      setListParams({
        ProductGroupSearch: val.trim(),
        subCategorySearch: "",
        start: 0,
        length: 15,
      });
    }
  };

  const deleteList = (listId: any) => {
    // MasterService.isExist(Masters.ProductGroup, listId).then((res: any) => {
    //   if (res === true) {
    //     warning();
    //   } else {
    //     MasterService.deleteProductGroup(listId).then((res: any) => {
    //       errorMsg("Product Group is Deleted Successfully");
    //       onDismiss();
    //     });
    //   }
    // });
    MasterService.isExist(Masters.ProductGroup, listId).then((res) => {
      if (res === true) {
        warning();
      } else {
        MasterService.getProductGroup(listId).then((res: any) => {
          if (res?.isEditAllowed) {
            MasterService.deleteProductGroup(listId).then((res: any) => {
              // errorMsg("Product Group is Deleted Successfully");
              messageApi.success("Product Group is deleted successfully.");
              onDismiss();
            });
          } else {
            messageApi.warning(
              "Product Group is added by admin can'nt be deleted"
            );
          }
        });
      }
    });
  };

  useEffect(() => {
    MasterService.getSubCategoryOptions(6, "").then((res) => {
      setSubCategoryOptions(
        res?.map((r: any) => ({
          label: r.name,
          value: r.id,
          defaultVariants: r?.defaultVariants,
        }))
      );
    });
  }, [shouldRefresh]);

  useEffect(() => {
    getProductGroupList(listParams);
    inputRef.current?.focus();
  }, [shouldRefresh, listParams]);

  const columns: ColumnsType<any> = [
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">SUB-CATEGORY</span> */}
          <Search
            placeholder="Sub Category"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => search(e, "subcategory")}
            size="small"
            bordered={false}
          />
        </div>
      ),
      width: 240,
      dataIndex: "subCategory",
      key: "subCategory",
      render: (itm: any, record: any) => (
        <span
          className="ps-2 font13"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedRowKeys(record?.key);
            editRowData(record);
          }}
        >
          {itm?.label}
        </span>
      ),
    },
    {
      title: (
        <div>
          {/* <span className="searchDivStyle ms-2">PRODUCT GROUP</span> */}
          <Search
            placeholder="Product Group"
            className="InvSearchInput"
            allowClear
            onSearch={(e: any) => search(e, "productGroup")}
            size="small"
            bordered={false}
          />
        </div>
      ),
      width: 240,
      dataIndex: "name",
      render: (itm: any, record: any) => (
        <span className="ps-2 font13"> {itm}</span>
      ),
    },
    {
      title: (
        <>
          <div className="">Date</div>
        </>
      ),
      dataIndex: "createdBy",
      ellipsis: true,
      key: "createdBy",
      render: (val: any) => <div className=" font13 ">{val}</div>,
    },
    {
      title: " ",
      align: "right",
      key: "operation",
      render: (_, record: any) => (
        <>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined style={{ color: "#1677ff" }} />}
            style={{ color: "#1677ff" }}
            onClick={() => {
              setSelectedRowKeys(record?.key);
              editRowData(record);
            }}
          >
            Edit
          </Button>{" "}
          <Popconfirm
            title="Are you sure?"
            onConfirm={() => deleteList(record.id)}
            okText="Yes"
            cancelText="No"
            placement="top"
            icon={<DeleteOutlined style={{ color: "red" }} />}
          >
            <Button icon={<DeleteOutlined />} type="text" danger size="small">
              Delete
            </Button>
          </Popconfirm>
          {
            <Button
              type="text"
              size="small"
              icon={<HistoryOutlined />}
              onClick={() => {
                setSelectedRecord(record);
                setShowDrawer("ShowHistory");
              }}
            >
              History
            </Button>
          }
        </>
      ),
    },
  ];
  const [selectedRowKeys, setSelectedRowKeys] = useState("");

  const rowClassName = (record: any, index: any) => {
    return selectedRowKeys === record.key ? "selected-row" : "";
  };
  return (
    <>
      {contextHolder}
      <Card
        title={"Product Group"}
        bordered={false}
        extra={
          <>
            <Row justify={"end"} gutter={[18, 16]}>
              <Col>
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  // className="mb-3"
                  // title="Vendor"
                  style={{ fontWeight: "600" }}
                  icon={<PlusOutlined style={{}} />}
                  onClick={() => setShow(true)}
                >
                  New
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col lg={24}>
            <Table
              rowClassName={rowClassName}
              // className="InvTable"
              dataSource={tableData}
              columns={columns}
              loading={{
                spinning: loading,
                indicator: antIcon,
              }}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                total: totalRecords,
                onChange: (page, pageSize) => {
                  changeListParams("start", (page - 1) * pageSize);
                  changeListParams("length", pageSize);
                  setShouldRefresh((x) => !x);
                },
                pageSizeOptions: pageSizeOption,
              }}
              scroll={{ y: `calc(100vh - 300px)` }}
            />
          </Col>
        </Row>
        <Modal
          title={
            updateToggle === true ? (
              "Add Product Group"
            ) : (
              <>
                {exist === true ? (
                  <div>
                    Update Product Group
                    <Tag style={{ marginLeft: "10px" }} color="error">
                      Item is already assigned
                    </Tag>
                  </div>
                ) : (
                  "Update Product Group"
                )}
                {/* <Tag color="success">Item Is Assigned</Tag>{" "} */}
              </>
            )
          }
          afterOpenChange={() => {
            inputRef.current?.focus();
          }}
          open={show}
          destroyOnClose={true}
          closable
          maskClosable={false}
          onCancel={() => {
            setShow(false);
            form.resetFields();
            setUpdateToggle(true);
            setSelectedRowKeys("");
          }}
          zIndex={1005}
          cancelButtonProps={{ type: "primary", ghost: true, danger: true }}
          footer={
            <>
              {updateToggle === true ? (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      form.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      saveProductGroups(true);
                    }}
                  >
                    Save
                  </Button>{" "}
                  <Button
                    type="primary"
                    ghost
                    onClick={() => {
                      saveProductGroups(false);
                    }}
                  >
                    Save and New
                  </Button>{" "}
                </div>
              ) : (
                <div>
                  <Button
                    danger
                    onClick={() => {
                      setShow(false);
                      form.resetFields();
                      setUpdateToggle(true);
                      setSelectedRowKeys("");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => updateData()}
                    disabled={!editData?.isEditAllowed}
                  >
                    Update
                  </Button>
                </div>
              )}
            </>
          }
        >
          <Divider
            style={{ borderColor: "rgb(210, 211, 212)", margin: "10px 0px" }}
          />
          <Form
            form={form}
            size="large"
            layout="vertical"
            wrapperCol={{ span: 18 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
            className="InvAddForm"
          >
            <Form.Item
              label="Product-Category"
              name="subCategory"
              rules={[
                {
                  required: true,
                  message: "please fill the category",
                },
              ]}
            >
              <Select
                options={subCategoryOptions}
                ref={inputRef}
                labelInValue
                allowClear
                listHeight={120}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <AddInOption
                      funcType={"subcategory"}
                      setShouldRefresh={setShouldRefresh}
                      // shouldRefresh={shouldRefresh}
                      // category={props.category}
                      getSubCategory={props.getSubCategory}
                    />
                  </>
                )}
              />
            </Form.Item>
            <Form.Item
              label="Product Group"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please fill the product group",
                },
                // {
                //   pattern: pattern,
                //   message:
                //     "Spaces,  are not allowed at the beginning or end of the value.",
                // },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
          <Divider style={{ borderColor: "rgb(210, 211, 212)" }} />
        </Modal>
        {showDrawer === "ShowHistory" && (
          <MasterHistory
            masterId={selectedRecord?.id}
            entity={EntitiesEnum.ProductGroup}
            openDrawer={true}
            onDismiss={() => {
              setShow(false);
              setShowDrawer("");
            }}
          />
        )}
      </Card>
    </>
  );
};

export default ProductGroup;
