import React from "react";
import UserList from "../../Components/Users/UserList";

type Props = {};

const Users = (props: Props) => {
  return <UserList />;
};

export default Users;
