import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class ReportServices {
  route = `/v1/api/IReports`;
  getProductReport = (
    category: string,
    nameSearch: string,
    subCategorySearch: string,
    assigneeSearch: string,
    seatSearch: string,
    start: number,
    length: number,
    fromdate: string,
    toDate: string,
    sortCol: string,
    sortDir: string
  ) =>
    ApiUtility.getResult(`${this.route}`, {
      category,
      nameSearch,
      subCategorySearch,
      assigneeSearch,
      seatSearch,
      start,
      length,
      fromdate,
      toDate,
      sortCol,
      sortDir,
    });
  digitalStockReport = (
    subCategorySearch: string,
    productGrpSearch: string,
    start: number,
    length: number,
    sortCol: string,
    sortDir: string
  ) =>
    ApiUtility.getResult(`${this.route}/stock/digitalProducts`, {
      subCategorySearch,
      productGrpSearch,
      start,
      length,
      sortCol,
      sortDir,
    });
}

export default new ReportServices();
