import { ConfigProvider } from "antd";
import React from "react";
import InventoryMainLayout from "./MainLayout";
// import ApplicationState from "../Context/ApplicationContext/ApplicationState";

type Props = {};

const WrapperInventory = (props: Props) => {
  return (
    <>
      <InventoryMainLayout />
    </>
  );
};

export default WrapperInventory;
