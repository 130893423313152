import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { Category } from "./ProductService";

export enum Masters {
  unit,
  Option,
  Variant,
  SubCategory,
  Seating,
  Assignee,
  Department,
  Designation,
  ProductGroup,
  ConstructionGst,
  ConstructionCategory,
  ConstructionSubCategory,
  ConstructionMeasurement,
}

export enum CategorySearch {
  All = 0,
  Cleaning,
  Stationary,
  Garden,
  MedicalKit,
  PaidSoftware,
  DigitalProducts,
  OfficeEquipments,
}
class MasterService {
  route = `/v1/api`;

  //////////////////////////// Api Request Methods Masters in Use//////////////////////////
  isExist = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  ////////////////////////////Unit Api Request Methods//////////////////////////
  getUnit = (id: string) => ApiUtility.getResult(`${this.route}/IUnits/${id}`);

  getUnitOptions = (
    unitSearch: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IUnits`, {
      unitSearch,
      company,
      start,
      length,
      setLength,
    });

  getExistUnit = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postUnit = (params: any) => ApiUtility.post(`${this.route}/IUnits`, params);

  deleteUnit = (id: any) =>
    ApiUtility.postForm(`${this.route}/IUnits/delete`, { id });

  ////////////////////////////Sub-Category Api Request Methods///////////////////
  getSubCategory = (id: string) =>
    ApiUtility.getResult(`${this.route}/ISubCategories/${id}`, {});
  getSubCategoryList = (
    subCategorySearch?: string,
    category?: CategorySearch,
    company?: string,
    start?: number,
    length?: number
  ) =>
    ApiUtility.getResult(`${this.route}/ISubCategories`, {
      subCategorySearch,
      category,
      company,
      start,
      length,
    });
  getSubCategoryOptions = (category: Category, subCategorySearch?: string) =>
    ApiUtility.getResult(`${this.route}/ISubCategories/subCategoryOptions`, {
      category,
      subCategorySearch,
    });

  postSubCategory = (params: any) =>
    ApiUtility.post(`${this.route}/ISubCategories`, params);

  deleteSubCategory = (id: string) =>
    ApiUtility.postForm(`${this.route}/ISubCategories/deleteSubcategory`, {
      id,
    });

  // https: //localhost:7142/v1/api/Inv_SubCategory/DeleteSubcategory

  ////////////////////////////ProductGroup Api Request Methods//////////////////////////
  postProductGroup = (params: any) =>
    ApiUtility.post(`${this.route}/IProductGroup`, params);

  getProductGroup = (id: string) =>
    ApiUtility.getResult(`${this.route}/IProductGroup/${id}`, {});

  getProductGroupList = (
    ProductGroupSearch: string,
    subCategorySearch: string,
    setLength: boolean,
    start?: number,
    length?: number
  ) =>
    ApiUtility.getResult(`${this.route}/IProductGroup`, {
      ProductGroupSearch,
      subCategorySearch,
      setLength,
      start,
      length,
    });
  deleteProductGroup = (id: string) =>
    ApiUtility.postForm(`${this.route}/IProductGroup/deleteProductType`, {
      id,
    });

  ////////////////////////////Tax Api Request Methods//////////////////////////
  getTax = () => ApiUtility.getResult(`${this.route}/ITax`);

  postTax = (params: any) => ApiUtility.post(`${this.route}/ITax`, params);

  ////////////////////////////Variant Api Request Methods//////////////////////////

  getVariant = (
    variant: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IVariantsMaster`, {
      variant,
      start,
      length,
      setLength,
    });

  getVariantById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IVariantsMaster/${id}`, {});
  postVariant = (params: any) =>
    ApiUtility.post(`${this.route}/IVariantsMaster`, params);

  deleteVariant = (id: string) =>
    ApiUtility.postForm(`${this.route}/IVariantsMaster/delete`, {
      id,
    });

  ////////////////////////////Variant Option Api Request Methods//////////////////////////

  getOption = (
    search: string,
    start: number,
    length: number,
    isOptionSearch: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IVariantOptions`, {
      search,
      start,
      length,
      isOptionSearch,
    });

  getOptionById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IVariantOptions/${id}`, {});
  postOption = (params: any) =>
    ApiUtility.post(`${this.route}/IVariantOptions`, params);

  deleteOption = (id: string) =>
    ApiUtility.postForm(`${this.route}/IVariantOptions/deleteOption`, {
      id,
    });

  ////////////////////////////Get Grouped List Of Seating //////////////////////////
  getSeating = (
    seatingSearch: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/ISeating`, {
      seatingSearch,
      company,
      start,
      length,
      setLength,
    });
  getSeatingById = (id: string) =>
    ApiUtility.getResult(`${this.route}/ISeating/${id}`, {});
  postSeating = (params: any) =>
    ApiUtility.post(`${this.route}/ISeating`, params);

  deleteSeating = (id: any) =>
    ApiUtility.postForm(`${this.route}/ISeating/delete`, { id });
  getExistSeating = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  //////////////////////////// Department  //////////////////////////
  getDepartment = (id: string) =>
    ApiUtility.getResult(`${this.route}/IDepartments/${id}`);

  getDepartmentOptions = (
    departmentSearch: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IDepartments`, {
      departmentSearch,
      company,
      start,
      length,
      setLength,
    });

  getExistDepartment = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postDepartment = (params: any) =>
    ApiUtility.post(`${this.route}/IDepartments`, params);

  deleteDepartment = (id: any) =>
    ApiUtility.postForm(`${this.route}/IDepartments/delete`, { id });

  //////////////////////////// Designation  //////////////////////////
  getDesignation = (id: string) =>
    ApiUtility.getResult(`${this.route}/IDesignations/${id}`);

  getDesignationOptions = (
    designationSearch: string,
    company: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IDesignations`, {
      designationSearch,
      company,
      start,
      length,
      setLength,
    });

  getExistDesignation = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postDesignation = (params: any) =>
    ApiUtility.post(`${this.route}/IDesignations`, params);

  deleteDesignation = (id: any) =>
    ApiUtility.postForm(`${this.route}/IDesignations/delete`, { id });

  //////////////////////////// Assignee  //////////////////////////
  getAssignee = () => ApiUtility.getResult(`${this.route}/IAssignees/options`);

  getAssigneeById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IAssignees/${id}`);

  getAssigneeList = (
    assigneeSearch: string,
    designationSearch: string,
    departmentSearch: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/IAssignees`, {
      assigneeSearch,
      designationSearch,
      departmentSearch,
      start,
      length,
      setLength,
    });

  getExistAssignee = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postAssignee = (params: any) =>
    ApiUtility.post(`${this.route}/IAssignees`, params);

  deleteAssignee = (id: any) =>
    ApiUtility.postForm(`${this.route}/IAssignees/delete`, { id });

  //////////////////////////// UserCategory  //////////////////////////
  getUserCategory = () =>
    ApiUtility.getResult(`${this.route}/IUserCategory/options`);

  getUserCategoryById = (id: string) =>
    ApiUtility.getResult(`${this.route}/IUserCategory/${id}`);

  getUserCategoryList = (
    name?: string,
    company?: string,
    start?: number,
    length?: number,
    isOptions = false
  ) =>
    ApiUtility.getResult(`${this.route}/IUserCategory`, {
      name,
      company,
      start,
      length,
      isOptions,
    });

  getExistUserCategory = (master: Masters, id: string) =>
    ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postUserCategory = (params: any) =>
    ApiUtility.post(`${this.route}/IUserCategory`, params);

  deleteUserCategory = (id: any) =>
    ApiUtility.postForm(`${this.route}/IUserCategory/delete`, { id });
}

export default new MasterService();
