import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class MastersService {
  route = `/v1/api`;
  /*
    MaterialType = Category
    Item = SubCategory
   */
  /********** Material Api Request Methods **********/
  getMaterialType = (id: string) =>
    ApiUtility.getResult(`${this.route}/CMaterialType/${id}`);

  getMaterialTypeOptions = (
    search: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/CMaterialType`, {
      search,
      start,
      length,
      setLength,
    });

  //   getExistUnit = (master: Masters, id: string) =>
  //     ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postMaterialType = (params: any) =>
    ApiUtility.post(`${this.route}/CMaterialType`, params);

  deleteMaterialType = (id: any) =>
    ApiUtility.postForm(`${this.route}/CMaterialType/deleteMaterial`, { id });

  /********** Items Api Request Methods **********/
  getItems = (id: string) => ApiUtility.getResult(`${this.route}/CItems/${id}`);

  getItemsOptions = (
    itemSearch: string,
    materialSearch: string,
    materialId?: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/CItems`, {
      itemSearch,
      materialSearch,
      materialId,
      start,
      length,
      setLength,
    });

  //   getExistUnit = (master: Masters, id: string) =>
  //     ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postItems = (params: any) => ApiUtility.post(`${this.route}/CItems`, params);

  deleteItems = (id: any) =>
    ApiUtility.postForm(`${this.route}/CItems/delete`, { id });

  /********** Measurement Api Request Methods **********/
  getMeasurement = (id: string) =>
    ApiUtility.getResult(`${this.route}/CMeasurement/${id}`);

  getMeasurementOptions = (
    search: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/CMeasurement`, {
      search,
      start,
      length,
      setLength,
    });

  //   getExistUnit = (master: Masters, id: string) =>
  //     ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postMeasurement = (params: any) =>
    ApiUtility.post(`${this.route}/CMeasurement`, params);

  deleteMeasurement = (id: any) =>
    ApiUtility.postForm(`${this.route}/CMeasurement/delete`, { id });

  /********** GST Api Request Methods **********/
  getGst = (id: string) =>
    ApiUtility.getResult(`${this.route}/Gst/${id}`);

  getGstOptions = (
    gstSearch: string,
    start?: number,
    length?: number,
    setLength: boolean = true
  ) =>
    ApiUtility.getResult(`${this.route}/Gst`, {
      gstSearch,
      start,
      length,
      setLength,
    });

  //   getExistUnit = (master: Masters, id: string) =>
  //     ApiUtility.getResult(`${this.route}/IMasters/isExist`, { master, id });

  postGst = (params: any) =>
    ApiUtility.post(`${this.route}/Gst`, params);

  deleteGst = (id: any) =>
    ApiUtility.postForm(`${this.route}/Gst/delete`, { id });
}

export default new MastersService();
